import {Trans} from "react-i18next";
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
import './Placeholder.scss';

const Placeholder = () => {
    return <div className="placeholder">
        <HourglassDisabledIcon></HourglassDisabledIcon>
        <span><Trans>COMMON.NO_DATA</Trans></span>
    </div>
}

export default Placeholder;