import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {TFunction} from "i18next";
import {TransitionProps} from "@mui/material/transitions";
import {Slide, Tooltip} from "@mui/material";
import {useMovementsDetail} from "../hook/UseMonitoring";
import dayjs from "dayjs";
import SuspenseComponent from "../../Layout/SuspenseComponent";
import Placeholder from "../../SkinnableComponents/Placeholder/Placehoder";
import {Trans, useTranslation} from "react-i18next";
import {GridCellParams, GridColDef} from "@mui/x-data-grid";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {AccountingRowT} from "../core/model/AccountingRowT";
import {currencyFormatter, dateFormatter} from "../../../core/utils";
import './Das01MovementsDetailDialog.scss';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface ModalState {
    data: GridCellParams;
    companyId: number;
    open: boolean;
    onClose: () => void;
}

const renderCell = (params: any) => (<Tooltip title={params.value}><span>{params.value}</span></Tooltip>)

const columns = (t: TFunction): GridColDef[] => ([
    { field: 'idMovimento', headerName: t('DASHBOARD_MONITORING.DASHBOARD_RATES.MOVEMENTS_DETAIL.FIELDS.ID_MOVIMENTO')},
    { field: 'bankName', headerName: t('DASHBOARD_MONITORING.DASHBOARD_RATES.MOVEMENTS_DETAIL.FIELDS.BANK_NAME'), width: 200, renderCell},
    { field: 'aisAccountIban', headerName: t('DASHBOARD_MONITORING.DASHBOARD_RATES.MOVEMENTS_DETAIL.FIELDS.IBAN'), width: 250, renderCell },
    { field: 'registrationDate', headerName: t('DASHBOARD_MONITORING.DASHBOARD_RATES.MOVEMENTS_DETAIL.FIELDS.REG_DATE'), valueFormatter: ({value}) => dateFormatter(value) },
    { field: 'causeDescription', headerName: t('DASHBOARD_MONITORING.DASHBOARD_RATES.MOVEMENTS_DETAIL.FIELDS.CAUSAL'), width: 150, renderCell },
    { field: 'amountAis', headerName: t('DASHBOARD_MONITORING.DASHBOARD_RATES.MOVEMENTS_DETAIL.FIELDS.AIS_AMOUNT'), valueFormatter: ({value})=> currencyFormatter(value) },
    { field: 'codContropartita', headerName: t('DASHBOARD_MONITORING.DASHBOARD_RATES.MOVEMENTS_DETAIL.FIELDS.COD_CONTO'), renderCell},
    { field: 'descrizioneContropartita', headerName: t('DASHBOARD_MONITORING.DASHBOARD_RATES.MOVEMENTS_DETAIL.FIELDS.DESC_CONTO'), renderCell},
    { field: 'importo', headerName: t('DASHBOARD_MONITORING.DASHBOARD_RATES.MOVEMENTS_DETAIL.FIELDS.IMPORTO'), width: 150, valueFormatter: ({value})=> currencyFormatter(value) },
    { field: 'segno', headerName: t('DASHBOARD_MONITORING.DASHBOARD_RATES.MOVEMENTS_DETAIL.FIELDS.SEGNO') },
    { field: 'type', headerName: t('DASHBOARD_MONITORING.DASHBOARD_RATES.MOVEMENTS_DETAIL.FIELDS.TYPE'), valueGetter: ({value}) => value },
    { field: 'label', headerName: t('DASHBOARD_MONITORING.DASHBOARD_RATES.MOVEMENTS_DETAIL.FIELDS.LABEL') },
    { field: 'criteria', headerName: t('DASHBOARD_MONITORING.DASHBOARD_RATES.MOVEMENTS_DETAIL.FIELDS.CRITERIA')},
    { field: 'status', headerName: t('DASHBOARD_MONITORING.DASHBOARD_RATES.MOVEMENTS_DETAIL.FIELDS.STATUS')},
    { field: 'dtInsert', headerName: t('DASHBOARD_MONITORING.DASHBOARD_RATES.MOVEMENTS_DETAIL.FIELDS.DT_INSERT'), valueFormatter: ({value})=> dateFormatter(value) },
    { field: 'dtUpdate', headerName: t('DASHBOARD_MONITORING.DASHBOARD_RATES.MOVEMENTS_DETAIL.FIELDS.DT_UPDATE'), valueFormatter: ({value})=> dateFormatter(value) },
    { field: 'amountScad', headerName: t('DASHBOARD_MONITORING.DASHBOARD_RATES.MOVEMENTS_DETAIL.FIELDS.AMOUNT_SCAD'), valueFormatter: ({value})=> currencyFormatter(value) },
    { field: 'completeNumFattura', headerName: t('DASHBOARD_MONITORING.DASHBOARD_RATES.MOVEMENTS_DETAIL.FIELDS.NUM_FATTURA')},
    { field: 'dataScadenza', headerName: t('DASHBOARD_MONITORING.DASHBOARD_RATES.MOVEMENTS_DETAIL.FIELDS.DT_SCAD'), valueFormatter: ({value})=> dateFormatter(value) },
    { field: 'idPartita', headerName: t('DASHBOARD_MONITORING.DASHBOARD_RATES.MOVEMENTS_DETAIL.FIELDS.ID_PARTITA')}
]);

const Das01MovementsDetailDialog = (props: ModalState) => {
    const {t} = useTranslation();
    const movementsDetailHook = useMovementsDetail();

    React.useEffect(() => {
        if (props.data?.field) {
            movementsDetailHook.get(props.companyId,
                dayjs(props.data.field).format('YYYY-MM-DD'),
                dayjs(props.data.field).format('YYYY-MM-DD'));
        }
    }, [props.companyId, props.data?.field]);

    return (
        <Dialog
            onClose={props.onClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}
            TransitionComponent={Transition}
            fullScreen
        >
            <DialogTitle sx={{m: 0, p: 2}} id="customized-dialog-title">
                <Trans>DASHBOARD_MONITORING.DASHBOARD_RATES.MOVEMENTS_DETAIL.TITLE</Trans> {props.data && dayjs(props.data?.field).format("DD MMMM YYYY")}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={props.onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>
            <DialogContent dividers>
                {Boolean(movementsDetailHook.movements)
                    ? <SuspenseComponent isLoading={!movementsDetailHook.movements && !movementsDetailHook.isLoading}>
                        <DataGridPro loading={movementsDetailHook.isLoading}
                                     getRowId={(row: AccountingRowT) => row.id}
                                     rows={movementsDetailHook.movements}
                                     columns={columns(t)}
                                     rowSelection={false}
                                     getRowClassName={(params) => params.row.isBank ? 'row-style' : 'disable-checkbox'}
                                     hideFooter
                                     density="compact"
                        />
                    </SuspenseComponent>
                    : <Placeholder></Placeholder>
                }
            </DialogContent>
        </Dialog>
    );
}

export default Das01MovementsDetailDialog;