import FrequencyConfigurationComponent from "./FrequencyConfigurationComponent";
import {
    BatchParamsValue,
    SERVICE_FREQUENCY,
    ServiceConfiguration
} from "../core/ServiceConfiguration";
import ServiceBatchConfigurationComponent from "./ServiceBatchConfigurationComponent";
import {ServiceConfigurationHookT, useServices} from "../hook/useServices";
import Autocomplete from "@mui/material/Autocomplete";
import {Service} from "../../../model/Customer";
import TextField from "@mui/material/TextField";
import React from "react";
import {Trans} from "react-i18next";
import {useBatches} from "../hook/useBatches";
import {Button, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import PageTitle from "../../SkinnableComponents/PageTitle/PageTitle";
import {ROUTES} from "../../../routes/ROUTES";
import {useNavigate} from "react-router-dom";

interface ServiceConfigurationProps {
    serviceConfigurationHook: ServiceConfigurationHookT;
    serviceId?: number;
}

function ServiceConfigurationComponent(props: ServiceConfigurationProps) {
    const [selectedService, setSelectedService] = React.useState<Service>(null);
    const [config, setConfig] = React.useState<ServiceConfiguration>(new ServiceConfiguration());
    const navigate = useNavigate();

    const servicesHook = useServices();
    const batchHook = useBatches();

    React.useEffect(() => {
        if (props.serviceId && servicesHook.services) {
            const sx = servicesHook.services.find(s => s.serviceId === props.serviceId);
            setSelectedService(sx);
            setConfig({ ...config, serviceId: props.serviceId });
        }

        if (props.serviceConfigurationHook.serviceConfiguration?.serviceConfigId && servicesHook.services) {
            const sx = servicesHook.services.find(s => s.serviceId === props.serviceConfigurationHook.serviceConfiguration.serviceId);
            setSelectedService(sx);
            setConfig(props.serviceConfigurationHook.serviceConfiguration);
        }
    }, [servicesHook.services, props.serviceConfigurationHook.serviceConfiguration, props.serviceId])

    React.useEffect(() => {
        if (selectedService) {
            batchHook.get(selectedService.serviceId).then();
        }
    }, [selectedService] )

    React.useEffect(() => {
        if (batchHook.batches && selectedService) {
            const params: BatchParamsValue[] = [];

            batchHook.batches.forEach(bts => {
                bts.batchParamList.forEach(b => {
                    const v: BatchParamsValue = {
                        paramValue: b.paramValue || b.defaultValue,
                        paramName: b.paramName,
                        batchId: bts.batchId
                    }
                    params.push(v);
                })
            })

            setConfig({...config, batchParamsValues: params});
        }
    }, [selectedService, batchHook.batches])

    const handleChange = (field: string, value: string | number) => {
        setConfig({
            ...config,
            [field]: value
        })
    }

    const handleFrequencyChange = (frequency: SERVICE_FREQUENCY) => {
        setConfig({
            ...config,
            frequency
        })
    }

    const handleParamsChange = (batchParamsValues: BatchParamsValue[]) => {
        setConfig({
            ...config,
            batchParamsValues
        })
    }

    const submit = () => {
        props.serviceConfigurationHook.save(config).then(_ => {
            const url = ROUTES.SERVICE_CONFIGURATION + '/' + config.serviceId;
            navigate(url);
        });
    }

    return <>
    <PageTitle title={ config.serviceConfigId ? "SERVICE_CONFIG.EDIT_TITLE":  "SERVICE_CONFIG.NEW_TITLE"} enableBack>
        <Button
            aria-label="save"
            onClick={submit}
        >
            <Typography>
                <Trans>COMMON.SAVE</Trans>
            </Typography>
        </Button>
    </PageTitle>
    <Grid container columns={2} spacing={1} marginTop={0}>
        <Grid item xs={1}>
                <Autocomplete
                clearOnEscape
                fullWidth
                disableClearable
                id="search-customer"
                options={servicesHook.services}
                value={selectedService}
                onChange={(_, value: Service) => {
                    setSelectedService(value);
                    handleChange("serviceId", value.serviceId);
                }}
                getOptionLabel={(option: Service) => option?.description}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Servizio"
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                        }}
                        InputLabelProps={{ shrink: true }}
                        variant="standard"
                    />
                )}
            />
        </Grid>
        <Grid item xs={1} >
            <TextField
                fullWidth
                variant="standard"
                id="serviceConfigName"
                name="serviceConfigName"
                label={<Trans>SERVICE_CONFIG.FIELDS.NAME</Trans>}
                value={config.serviceConfigName}
                onChange={e => handleChange('serviceConfigName', e.target.value)}
                InputLabelProps={{ shrink: true }}
            />
        </Grid>
        {selectedService &&
        <Grid item xs={2}>
            <Grid container columns={1} spacing={2}>
            <Grid item xs={1}>
                <FrequencyConfigurationComponent frequency={props.serviceConfigurationHook.serviceConfiguration.frequency} onChange={handleFrequencyChange} />
            </Grid>
            <Grid item xs={1}>
                <ServiceBatchConfigurationComponent batches={batchHook.batches}
                                                    batchParamsValue={props.serviceConfigurationHook.serviceConfiguration.batchParamsValues}
                                                    onChange={handleParamsChange}
                />
            </Grid>
        </Grid>
        </Grid>
        }
    </Grid>
        </>
}


export default ServiceConfigurationComponent;