import * as yup from "yup";
import {Contact, ContactType} from "../../../../model/Customer";

export const GenericContactValidation = (t, contactType: ContactType) => {
    switch (contactType) {
        case ContactType.CONTATTO: return ContactValidation(t);
        case ContactType.FATTURAZIONE: return ChargingContactValidation(t);
        case ContactType.ADDRESS: return AddressValidation(t);
        case ContactType.NOTIFY: return NotifyValidation(t);
        default: return ContactValidation(t);
    }
}

//`${t("CUSTOMER_DETAILS.FIELDS.DETAILS.CODE")} ${t("COMMON.REQUIRED_M")}`

const ContactValidation = (t) =>
    yup.object<Contact>().shape({
        ...baseSchema(t),
        ...personSchema(t)
    });

const NotifyValidation = (t) => {
    yup.object<Contact>().shape({
        ...baseSchema(t),
        ...emailSchema(t)
    });
}

const personSchema = (t) => ({
    name: yup.string().required(`${t("CUSTOMER_DETAILS.FIELDS.CONTACTS.NAME")} ${t("COMMON.REQUIRED_M")}`),
    surname: yup.string().required(`${t("CUSTOMER_DETAILS.FIELDS.CONTACTS.SURNAME")} ${t("COMMON.REQUIRED_M")}`),
    phoneNumber: yup.string().required(`${t("CUSTOMER_DETAILS.FIELDS.CONTACTS.TELEPHONE")} ${t("COMMON.REQUIRED_M")}`),
    email: yup.string().required(`${t("CUSTOMER_DETAILS.FIELDS.CONTACTS.EMAIL")} ${t("COMMON.REQUIRED_F")}`),
    //fax: yup.string().required(`${t("CUSTOMER_DETAILS.FIELDS.CONTACTS.FAX")} ${t("COMMON.REQUIRED_M")}`)
})

const emailSchema = (t) => ({
    email: yup.string().required(`${t("CUSTOMER_DETAILS.FIELDS.CONTACTS.EMAIL")} ${t("COMMON.REQUIRED_F")}`),
})

const baseSchema = (t) => ({
    contactType: yup.string().required(`${t("CUSTOMER_DETAILS.FIELDS.CONTACTS.TYPE")} ${t("COMMON.REQUIRED_F")}`)
})

const addressSchema = (t) => ({
    description: yup.string().required(`${t("CUSTOMER_DETAILS.FIELDS.CONTACTS.DESCRIPTION")} ${t("COMMON.REQUIRED_F")}`),
    street: yup.string().required(`${t("CUSTOMER_DETAILS.FIELDS.CONTACTS.STREET")} ${t("COMMON.REQUIRED_M")}`),
    city: yup.string().required(`${t("CUSTOMER_DETAILS.FIELDS.CONTACTS.CITY")} ${t("COMMON.REQUIRED_F")}`),
    region: yup.string().required(`${t("CUSTOMER_DETAILS.FIELDS.CONTACTS.REGION")} ${t("COMMON.REQUIRED_F")}`),
    country: yup.string().required(`${t("CUSTOMER_DETAILS.FIELDS.CONTACTS.COUNTRY")} ${t("COMMON.REQUIRED_M")}`),
    zipCode: yup.string().required(`${t("CUSTOMER_DETAILS.FIELDS.CONTACTS.ZIP")} ${t("COMMON.REQUIRED_M")}`),
})

const AddressValidation = (t) =>
    yup.object<Contact>().shape({
        ...baseSchema(t),
        ...addressSchema(t)
    });

const ChargingContactValidation = (t) =>
    yup.object<Contact>().shape({
        ...baseSchema(t),
        ...personSchema(t),
        ...addressSchema(t)
    });