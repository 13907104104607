import {useNavigate} from "react-router-dom";
import {GridActionsCellItem, GridColDef, GridRowParams} from "@mui/x-data-grid";
import InfoIcon from "@mui/icons-material/Info";
import {Service} from "../core/Service";
import {useTranslation} from "react-i18next";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {ROUTES} from "../../../routes/ROUTES";
import {Tooltip} from "@mui/material";

interface ServicesGridProps {
    services: Service[]
}

function ServicesGrid(props: ServicesGridProps) {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const columns: GridColDef[] = [
        {
            field: 'details', headerName: '', type: 'actions',
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem label="Dettagli" icon={<Tooltip title={t('COMMON.DETAILS').toString()}><InfoIcon /></Tooltip>}
                                     onClick={() => navigate(ROUTES.SERVICE + params.row.serviceId) }
                />
            ]
        },
        { field: 'name', headerName: t("SERVICE_LIST.FIELDS.NAME") },
        { field: 'description', headerName: t("SERVICE_LIST.FIELDS.DESCRIPTION"), flex: 1 },

    ]

    return <DataGridPro getRowId={(row: Service) => row.serviceId} rows={props.services} columns={columns} hideFooter />

}

export default ServicesGrid;