import {useNavigate} from "react-router-dom";
import {GridActionsCellItem, GridColDef, GridRowParams, GridToolbar} from "@mui/x-data-grid";
import InfoIcon from "@mui/icons-material/Info";
import {InvoiceDetails} from "../core/Invoice";
import {useTranslation} from "react-i18next";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {ROUTES} from "../../../routes/ROUTES";
import DownloadIcon from '@mui/icons-material/Download';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import {DOWNLOAD_FORMAT, useDownload} from "../hook/useInvoices";
import {currencyFormatter} from "../../../core/utils";
import {Tooltip} from "@mui/material";
import {calculateTotalInvoiceAmount} from "../core/utils/InvoiceUtils";

interface InvoicesGridProps {
    invoices: InvoiceDetails[];
}


function MyCustomToolbar(props: any) {
    return <GridToolbar {...props} printOptions={{disableToolbarButton: true }}/>;
}

function InvoicesDetailsGrid(props: InvoicesGridProps) {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const downloadHook = useDownload();

    const columns: GridColDef<InvoiceDetails>[] = [
        {
            field: 'Dettagli', hideable: false, headerName: '', type: 'actions',
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem label="Dettagli"
                                     icon={<Tooltip title={t('COMMON.DETAILS').toString()}><InfoIcon /></Tooltip>}
                                     onClick={() => navigate(ROUTES.INVOICE_DETAIL + params.row.invoiceId) }
                />
            ]
        },
        { field: 'customer.customerCode', headerName: t("INVOICES_LIST.FIELDS.CUSTOMER_CODE"), width: 140, valueGetter: (params) => params.row.customer?.customerCode },
        { field: 'customer.businessName', headerName: t("INVOICES_LIST.FIELDS.CUSTOMER_NAME"), flex: 1, valueGetter: (params) => params.row.customer?.businessName },
        { field: 'productCode', headerName: t("INVOICE_DETAIL.FIELDS.PRODUCT_CODE")  },
        { field: 'description', headerName: t("INVOICE_DETAIL.FIELDS.DESCRIPTION"), flex: 1  },
        { field: 'taxAmount', headerName: t("INVOICE_DETAIL.FIELDS.TAX_AMOUNT"), valueGetter: ({value}) => value + "%"},
        { field: 'taxableAmount', headerName: t("INVOICE_DETAIL.FIELDS.TAXABLE_AMOUNT"), valueGetter: ({value}) => currencyFormatter(value)},
        { field: 'invoiceNumber', headerName: t("INVOICES_LIST.FIELDS.NUMBER") },
        { field: 'billingDate', headerName: t("INVOICES_LIST.FIELDS.BILLING_DATE")},
        { field: 'invoiceTotal', headerName: t("INVOICES_LIST.FIELDS.TOTAL"), valueGetter: (params) => currencyFormatter(calculateTotalInvoiceAmount(params.row))},
        {
            field: 'Scarica', hideable: false, headerName: '', type: 'actions', width: 50,
            getActions: (params: GridRowParams<InvoiceDetails>) => [
                <GridActionsCellItem label="download" icon={<Tooltip title={t('COMMON.DOWNLOAD_XML').toString()}><DownloadIcon /></Tooltip>}
                                     onClick={() => downloadHook.download(params.row.invoiceId, DOWNLOAD_FORMAT.XML)}
                />
            ]
        },
        {
            field: 'Scarica PDF', hideable: false, headerName: '', type: 'actions', width: 50,
            getActions: (params: GridRowParams<InvoiceDetails>) => [
                <GridActionsCellItem label="download" icon={<Tooltip title={t('COMMON.DOWNLOAD_PDF').toString()}><FindInPageIcon /></Tooltip>}
                                     onClick={() => downloadHook.download(params.row.invoiceId, DOWNLOAD_FORMAT.PDF)}
                />
            ]
        },
    ]

    return <DataGridPro getRowId={(row: InvoiceDetails) => row.invoiceId + "_" + row.invoiceItemId}
                        rows={props.invoices}
                        columns={columns}
                        slots={{
                            toolbar: MyCustomToolbar,
                        }}
                        hideFooter />

}

export default InvoicesDetailsGrid;