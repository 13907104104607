import React from "react";
import SuspenseComponent from "../../Layout/SuspenseComponent";
import {AxiosResponse} from "axios";
import DetailField from "../../SkinnableComponents/DetailField/DetailField";
import {Card, CardContent} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useService} from "../hook/useServices";
import {Service} from "../core/Service";
import {ServiceComponentProps} from "./ServiceComponentProps";

function ServiceAnag(props: ServiceComponentProps) {
    const {t} = useTranslation();

    const mapService = React.useCallback((res: AxiosResponse<Service>): Service => {
        return res.data;
    }, []);

    const serviceHook = useService(props.id, mapService);

    return (
        <SuspenseComponent isLoading={serviceHook.isLoading}>
            {serviceHook.service &&
                <Card>
                    <CardContent>
                        <DetailField label={t("SERVICE_DETAILS.FIELDS.NAME")} value={serviceHook.service.name} />
                        <DetailField label={t("SERVICE_DETAILS.FIELDS.DESCRIPTION")} value={serviceHook.service.description} />
                    </CardContent>
                </Card>
            }
        </SuspenseComponent>
    )
}

export default ServiceAnag;