import React from 'react';
import {AxiosResponse} from "axios";
import SuspenseComponent from "../../Layout/SuspenseComponent";
import {Invoice, InvoiceDetails, InvoiceItem} from "../core/Invoice";
import {useInvoices} from "../hook/useInvoices";
import PageTitle from "../../SkinnableComponents/PageTitle/PageTitle";
import {InvoicesStatusesMap} from "../../Customers/core/DataSources";
import InvoicesDetailsGrid from "../components/InvoicesDetailsGrid";

export interface InvoicesDetailsViewProps {
    mode: string;
}

function InvoicesDetailsView(props: InvoicesDetailsViewProps) {
    const invoicesHook = useInvoices();

    const mapInvoices = (res: AxiosResponse<Invoice[]>): InvoiceDetails[] => {
        const details: any[] = [];

        res.data.forEach(invoice => {
            const items = invoice.invoiceItems.map((item: InvoiceItem) => {
                return {
                    ...invoice,
                    ...item
                }
            });

            details.push(...items);
        });

        return details;
    }

    const reloadData = () => {
        invoicesHook.get(InvoicesStatusesMap[props.mode], mapInvoices).then();
    }

    React.useEffect(() => {
        reloadData();
    }, [props.mode])

    return <SuspenseComponent isLoading={invoicesHook.isLoading}>
        <PageTitle title={`INVOICES_LIST.TITLES.SUMMARY_${props.mode?.toUpperCase()}`}>
        </PageTitle>
        <InvoicesDetailsGrid invoices={invoicesHook.invoices} />
    </SuspenseComponent>
}

export default InvoicesDetailsView;