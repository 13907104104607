import React from "react";
import {useParams} from "react-router-dom";
import CustomerAnag from "../components/CustomerAnag";
import CustomerContact from "../components/CustomerContact";
import CustomerInvoices from "../components/CustomerInvoices";
import CustomerGroup from "../components/CustomerGroup";
import CustomerSubs from "../components/CustomerSubs";
import Box from "@mui/material/Box";
import PageTitle from "../../SkinnableComponents/PageTitle/PageTitle";

function CustomerDetail() {
    let { customerId } = useParams();
    const [isNewCustomer, setIsNewCustomer] = React.useState<boolean>(false);

    React.useEffect(() => {
        setIsNewCustomer(!Boolean(customerId));
    }, [customerId])

    return <Box sx={{display: 'flex', flexDirection: 'column', gap: '.5rem'}}>
        <PageTitle title="CUSTOMER_DETAILS.TITLE" enableBack={true} />
        <CustomerAnag id={+customerId} isNewCustomer={isNewCustomer} key={`a_${customerId}`} />
        {!isNewCustomer && <>
            <CustomerGroup id={+customerId} key={`g_${customerId}`}/>
            <CustomerContact id={+customerId} key={`c_${customerId}`}/>
            <CustomerSubs id={+customerId} key={`s_${customerId}`}/>
            <CustomerInvoices id={+customerId} key={`i_${customerId}`}/>
        </>
        }
    </Box>
}

export default CustomerDetail;