import React from "react";
import {
    Button, FormHelperText
} from "@mui/material";
import {useFormik} from "formik";
import {Trans} from "react-i18next";
import {Fee} from "../../../model/Customer";
import {
    DataGridPro,
    GridCellEditStopParams, GridCellEditStopReasons,
    MuiEvent
} from "@mui/x-data-grid-pro";
import {CustomerComponentProps} from "./CustomerComponentProps";
import {useFee} from "../hook/useFee";
import {GridColumnGroup} from "@mui/x-data-grid/models/gridColumnGrouping";
import Typography from "@mui/material/Typography";
import {FormikValues} from "formik/dist/types";

function VendorFee(props: CustomerComponentProps) {
    const [feeColumns, setFeeColumns] = React.useState([]);
    const [columnGroupingModel, setColumnGroupingModel] = React.useState([]);
    const [rows, setRows] = React.useState([]);

    const feeHook = useFee();

    React.useEffect(() => {
        feeHook._get(props.id);
    }, [props.id]);

    React.useEffect(() => {
        const col = [
            { field: 'customerId', headerName: 'Cliente', editable: false, type: 'number'},
        ];

        const distinct = [];
        const set = new Set();
        const serviceSet = new Set();
        const groups: GridColumnGroup[] = [];
        const dataSet: {[x: string]: number; customerId: number}[] = [];

        feeHook.fees.forEach((f: Fee) => {
            const {serviceId, serviceName, year, customerId, value} = f;
            const key = serviceId + "_" + year;
            if (!set.has(key)) {
                set.add(key);
                distinct.push({serviceId, year})
                col.push({
                    field: key,
                    headerName: year.toString(),
                    editable: true,
                    type: 'number'
                    })

                if (!serviceSet.has(serviceId)) {
                    const g = { groupId: serviceId.toString(), children: [{field: key}], headerName: serviceName};
                    serviceSet.add(serviceId);
                    groups.push(g);
                } else {
                    const g = groups.filter(gx => gx.groupId = serviceId.toString());
                    if (g.length) {
                        g[0].children.push({field: key});
                    }
                }
            }

            const cst = dataSet.findIndex(c => c.customerId === customerId);
            if (cst === -1) {
                dataSet.push({customerId: customerId, [key]: value})
            } else {
                dataSet[cst][key] = value;
            }
        })

        setFeeColumns(col);
        setColumnGroupingModel(groups);
        setRows(dataSet);
    }, [feeHook.fees])


    const formik = useFormik({
        initialValues: { fees: rows},
        enableReinitialize: true,
        //validationSchema: RateValidation(t),
        onSubmit: (values: any) => {
            console.log(values);
            alert('NOT IMPLEMENTED');
        },
    });

    const onUpdateCell = (updatedRow: any, originalRow: any) => {
        const data = [...formik.values.fees];
        const idx = data.findIndex(x => x.customerId === originalRow.customerId);
        data[idx] = updatedRow;
        formik.setValues((prevState: FormikValues) => ({
            ...prevState,
            fees: data
        }))
        return updatedRow;
    }

    const handleCancel = () => {
        setFeeColumns([]);
        setColumnGroupingModel([]);
        setRows([]);
        feeHook._get(props.id);
    }

    return <>
            <br/>
            <div className="section-header">
                <Typography variant="h6" component="div">
                    <Trans>Clienti</Trans>
                </Typography>
                <div>
                    <Button color="error" onClick={handleCancel}><Trans>COMMON.CANCEL</Trans></Button>
                    <Button type="submit"><Trans>COMMON.SAVE</Trans></Button>
                </div>
            </div>
            <FormHelperText error>{formik.errors.fees?.toString()}</FormHelperText>
            <DataGridPro
                //  rowModesModel={rowModes}
                experimentalFeatures={{ columnGrouping: true }}
                columns={feeColumns}
                columnGroupingModel={columnGroupingModel}
                rows={formik.values.fees}
                getRowId={(row: Fee) => row.customerId}
                processRowUpdate={onUpdateCell}
                onProcessRowUpdateError={console.log}
                onCellEditStop={(params: GridCellEditStopParams, event: MuiEvent) => {
                    if (params.reason === GridCellEditStopReasons.cellFocusOut) {
                        event.defaultMuiPrevented = true;
                    }
                }}
            />
        </>
}

export default VendorFee;