import {CustomerAnagDetails, PaymentMethod, PaymentType} from "../../../model/Customer";

export class Invoice {
    invoiceId: number;
    invoiceTotal?: number; // only for FE
    invoiceConfiguration?: InvoiceConfiguration;
    customer: CustomerAnagDetails;
    invoiceNumber:  number;
    transmissionNumber?:  number;
    documentType?: DocumentType;
    recipientBusinessName?: string;
    recipientVatNumber?: string;
    recipientTaxCode?: string;
    recipientStreetAddress?: string;
    recipientZipCode?: string;
    recipientCity?: string;
    recipientRegion?: string;
    recipientCountry?: string;
    totalAmount?: string;
    billingDate?: string;
    paymentMethod?: PaymentMethod;
    paymentType?: PaymentType;
    paymentDuePeriod?:  number;
    paymentDueDate?: string;
    startPeriod?: string;
    endPeriod?: string;
    xmlPath?: string;
    pdfPath?: string;
    invoiceStatus?: InvoiceStatus;
    invoiceItems: InvoiceItem[] = [];
}

export class InvoiceDetails extends Invoice {
    invoiceItemId?:  number;
    lineNumber?: number;
    description?: string;
    quantity?: number;
    unit?: string;
    productCode?: string;
    startPeriod?: string;
    endPeriod?: string;
    itemPrice?: number;
    taxableAmount?: number;
    taxAmount?: number;
}

export enum InvoiceStatus {
    GENERATED = 'G',
    SENT = 'S',
    APPROVED = 'A'
}

export enum DocumentType {
    TD01 = 'TD01',
    TD02 = 'TD02',
    TD03 = 'TD03',
    TD04 = 'TD04',
    TD05 = 'TD05',
    TD06 = 'TD06',
    TD16 = 'TD16',
    TD17 = 'TD17',
    TD18 = 'TD18',
    TD19 = 'TD19',
    TD20 = 'TD20',
    TD21 = 'TD21',
    TD22 = 'TD22',
    TD23 = 'TD23',
    TD24 = 'TD24',
    TD25 = 'TD25',
    TD26 = 'TD26',
    TD27 = 'TD27',
    TD28 = 'TD28'
}

export class InvoiceItem {
    invoiceItemId:  number;
    lineNumber: number;
    description: string;
    quantity: number;
    unit: string;
    productCode: string;
    startPeriod: string;
    endPeriod: string;
    itemPrice: number;
    taxableAmount: number;
    taxAmount: number;

    constructor() {
        this.lineNumber = 0;
        this.description = '';
        this.quantity = null;
        this.unit = '';
        this.productCode = '';
        this.startPeriod = '';
        this.endPeriod = '';
    }
}

export class InvoiceConfiguration {
    invoiceConfigurationId:  number;
    invoiceConfigurationName: string;
    lastInvoiceNumber: number;
    lastTransmissionNumber: number;
    transmissionNumberPrefix: string;
    transmission: string;
    transmissionCode: string;
    documentType: DocumentType;
    intermediaryBusinessName: string;
    intermediaryVatNumber: string;
    intermediaryTaxCode: string;
    issuerBusinessName: string;
    issuerVatNumber: string;
    issuerTaxCode: string;
    issuerTaxRegime: string;
    issuerStreetAddress: string;
    issuerZipCode: string;
    issuerCity: string;
    issuerRegion: string;
    issuerCounty: string;
    issuerReaInscriptionOffice: string;
    issuerReaInscriptionNumber: string;
    issuerReaInscriptionStatus: string;
    issuerPhoneNumber: string;
    issuerEmail: string;
    issuerFax: string;
    paymentMethod: PaymentMethod;
    paymentType: PaymentType;
    paymentDuePeriod: number;
    paymentAddress: string;
    paymentIban: string;
    paymentAbi: string;
    paymentCab: string;
}