import React from "react";
import {stringToArray} from "cron-converter";
import {SHORTCUTS} from "./core/constants";
import {DataSourceT, ShortcutsType} from "./core/types";
import MonthDays from "./components/MonthDays";
import WeekDays from "./components/WeekDays";
import {Autocomplete} from "@mui/material";
import TextField from "@mui/material/TextField";
import './Cron.scss';

interface CronProps {
    cron: string;
    onChange: (val: string) => void;
}

const MODES: DataSourceT<string>[] = [
    { value: '@DAILY', label: 'GIORNALIERO' },
    { value: '@WEEKLY', label: 'SETTIMANALE' },
    { value: '@MONTHLY', label: 'MENSILE' },
    { value: '@MANUAL', label: 'MANUALE' },
]

export function Cron(props: CronProps) {
    const [mode, setMode] = React.useState<ShortcutsType>("@DAILY")
    const [selectedMode, setSelectedMode] = React.useState<DataSourceT<string>>(MODES[0]);
    const [selectedArray, setSelectedArray] = React.useState<number[][]>();

    React.useEffect(() => {
        let val;
        let array;
        if (props.cron) {
            try {
                array = stringToArray(props.cron);
                val = props.cron?.split(" ");
            } catch (e) {
                console.error(e);
            }
        }

        if (!val) {
            const DEF = SHORTCUTS.find(s => s.name === mode).value
            val = DEF.split(" ");
            array = stringToArray(DEF);
        }

        const _mode = val.findIndex((c, index) => index > 1 && c[0] !== '*');
        const s = SHORTCUTS.find(s => s.position === _mode);
        setSelectedArray(array);
        setMode(s ? s.name : '@DAILY');

        const m = MODES.find(m => m.value === (s ? s.name : '@DAILY'));
        setSelectedMode(m ? m : MODES[0]);
    }, [props.cron, mode])

    const handleChange = (val: string) => {
        props.onChange(val);
    }

    const DAILY = <></>;

    const getInput = () => {
        switch (selectedMode?.value) {
            case "@DAILY": return DAILY;
            case "@MONTHLY": return <MonthDays day={selectedArray[2][0]} onChange={handleChange} />
            case "@WEEKLY": return <WeekDays day={selectedArray[4][0]} onChange={handleChange} />
            default: return DAILY;
        }
    }

    return <div className="cron-container">
        <Autocomplete
            id="mode"
            disableClearable
            fullWidth
            options={MODES}
            getOptionLabel={(option: DataSourceT<string>) => option.label}
            onChange={(_, value: DataSourceT<string>) => {
                setSelectedMode(value);
                if (value.value === '@DAILY') {
                    props.onChange('* * * * *');
                } else if (value.value === '@MANUAL') {
                    props.onChange('');
                }
            }}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            value={selectedMode}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Modalità"
                    InputProps={{
                        ...params.InputProps,
                        type: 'search',
                    }}
                />
            )}
            clearOnEscape
        />
        {getInput()}
    </div>

}

export default Cron;