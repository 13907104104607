import {WidgetOptions} from "@okta/okta-signin-widget/src/types";
import {oktaCustomConfig} from "./oktaCustomConfig";
import {TFunction} from "i18next";

const {
    issuer,
    clientId,
    redirectUri,
    scopes
} = oktaCustomConfig;

export const signInWidgetConfig = (i18n: any, t: TFunction): WidgetOptions => {
    return {
        language: i18n.language,
        i18n: {
            en: t('OKTA.LOGIN', {returnObjects: true}),
            it: t('OKTA.LOGIN', {returnObjects: true}),
        },
        features: {
            registration: false,
            rememberMe: false,
            multiOptionalFactorEnroll: false,
            router: true,
        },
        baseUrl: issuer.split("/oauth2")[0],
        clientId: clientId,
        redirectUri: redirectUri,
        scopes,
        authParams: {
            issuer: issuer,
            responseType: ["id_token", "token"],
            scopes,
        },
    };
}

