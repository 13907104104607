import React from "react";
import SuspenseComponent from "../../Layout/SuspenseComponent";
import {AxiosResponse} from "axios";
import {Button, Card, CardContent, CardHeader, Tooltip} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import {useRates} from "../hook/useServices";
import {ServiceComponentProps} from "./ServiceComponentProps";
import {Rate, RateT, ThresholdsT} from "../../../model/Customer";
import Typography from "@mui/material/Typography";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {GridActionsCellItem, GridColDef, GridRowParams} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import RateEditModal from "./RateEditModal";
import AddIcon from "@mui/icons-material/Add";
import {currencyFormatter} from "../../../core/utils";

function ServiceRates(props: ServiceComponentProps) {
    const {t} = useTranslation();
    const [selectedRate, setSelectedRate] = React.useState<RateT>(null);

    const rateColumns: GridColDef<RateT>[] = [
        {
            field: 'edit', headerName: '', type: 'actions',
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem label="Modifica"
                                     icon={<Tooltip title={t('COMMON.EDIT').toString()}><EditIcon /></Tooltip>}
                                     onClick={() => setSelectedRate(params.row) }
                />
            ]
        },
        { field: 'name', headerName: t("SERVICE_DETAILS.FIELDS.RATES.NAME"), flex: 0.5 },
        { field: 'description', headerName: t("SERVICE_DETAILS.FIELDS.RATES.DESCRIPTION"), flex: 1 }
    ]

    const thresholdColumns: GridColDef<ThresholdsT>[] = [
        {
            field: 'businessDriver.description',
            headerName: t("SERVICE_DETAILS.FIELDS.RATES.THRESHOLD.BUSINESS_DRIVER"),
            valueGetter: params => params.row.businessDriver?.description,
            flex: 1
        },
        { field: 'value', headerName: t("SERVICE_DETAILS.FIELDS.RATES.THRESHOLD.VALUE")},
        { field: 'unitCost', headerName: t("SERVICE_DETAILS.FIELDS.RATES.THRESHOLD.UNIT"), valueGetter: ({value}) => currencyFormatter(value)},
        { field: 'flatCost', headerName: t("SERVICE_DETAILS.FIELDS.RATES.THRESHOLD.FLAT"), valueGetter: ({value}) => currencyFormatter(value)}
    ]


    const _mapRates = (res: AxiosResponse<Rate[]>): RateT[] => {
        return res.data.map(r => ({
            ...r,
            businessDrivers: Array.from(new Set( r.thresholds.map(obj => obj.businessDriver?.businessDriverId))).map(b => ({
                businessDriverId: b,
                thresholds: r.thresholds.filter(t => t.businessDriver?.businessDriverId === b) || []
            }))
        }))
    }

    const mapRates = React.useCallback(_mapRates, []);

    const serviceHook = useRates(props.id, mapRates);

    const header = <CardHeader title={<div className="section-header">
            <Typography variant="h6" component="div">
                <Trans>SERVICE_DETAILS.FIELDS.RATES.TITLE</Trans>
            </Typography>
            <Button
                aria-label="add"
                onClick={_ => setSelectedRate(new RateT())}
            >
                <AddIcon />
                <Typography>
                    <Trans>COMMON.ADD</Trans>
                </Typography>
            </Button>
        </div>}>
        </CardHeader>

    return (
        <SuspenseComponent isLoading={serviceHook.isLoading}>
            <RateEditModal open={selectedRate !== null}
                           onClose={() => {
                               serviceHook.setReload(true);
                               setSelectedRate(null);
                           } }
                           rateId={selectedRate?.rateId}
                           data={selectedRate}
                           serviceId={props.id}
            />
            <Card>
                <CardContent>
                    {header}
                    <DataGridPro
                        hideFooter
                        loading={serviceHook.isLoading}
                        getRowId={(row: RateT) => row.rateId} rows={serviceHook.rates}
                        columns={rateColumns}
                        getDetailPanelContent={({ row }) => <div style={{padding: ".5rem"}}>
                            <Typography variant="h6"><Trans></Trans></Typography>
                            <DataGridPro
                                columns={thresholdColumns}
                                rows={row.thresholds}
                                getRowId={(row: ThresholdsT) => row.thresholdId}
                                hideFooter
                            />
                        </div>}
                        getDetailPanelHeight={() => 'auto'} // Height based on the content.
                    />
                </CardContent>
            </Card>
        </SuspenseComponent>
    )
}
export default ServiceRates;