import {useNavigate} from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {Button} from "@mui/material";


export const BackButton = () => {
    const navigate = useNavigate();

    return <div style={{display: "flex", alignItems: "baseline"}}>
        <Button
            aria-label="back"
            onClick={_ => navigate(-1)}
        >
            <ArrowBackIosIcon></ArrowBackIosIcon>
        </Button>
    </div>
}

export default BackButton;