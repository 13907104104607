import {Invoice} from "../Invoice";

export const calculateTotalInvoiceAmount = (invoice: Invoice): number => {
    let invoiceTotalAmount = 0;
    invoice.invoiceItems.forEach((item => {
        invoiceTotalAmount += calculateTaxedAmount(item?.taxableAmount, item?.taxAmount)
    }))

    return invoiceTotalAmount;
}

export const calculateTotalAmount = (invoice: Invoice): number => {
    let invoiceIvaAmount = 0;
    invoice.invoiceItems.forEach((value) => {
        invoiceIvaAmount += value?.taxableAmount || 0;
    })

    return invoiceIvaAmount;
}

export const calculateTotalIva = (invoice: Invoice): number => {
    let invoiceTotalAmount = 0;

    invoice.invoiceItems.forEach((value) => {
        invoiceTotalAmount += calculateTaxAmount(value?.taxableAmount, value?.taxAmount) || 0
    })

    return invoiceTotalAmount;
}

const calculateTaxAmount = (taxableAmount: number = 0, taxAmount: number = 0): number => {
    return (taxableAmount * taxAmount) / 100;
}

const calculateTaxedAmount = (taxableAmount: number = 0, taxAmount: number = 0): number => {
    return taxableAmount + (taxableAmount * taxAmount) / 100;
}