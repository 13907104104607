export const ROUTES = {
    OKTA_LOGIN: '/login',
    OKTA_CALLBACK: '/okta/login/callback',
    ROOT: '/',
    EDIT_CUSTOMER: '/app/customers/edit/',
    ADD_CUSTOMER: '/app/customers/add',
    PROSPECT: '/app/customers/prospect',
    ACTIVE: '/app/customers/active',
    HISTORY: '/app/customers/history',
    PENDING: '/app/customers/pending',
    VENDORS: '/app/customers/vendors',
    GROUPS: '/app/groups',
    SERVICE: '/app/services/',
    PENDING_INVOICES: '/app/invoices/pending',
    APPROVED_INVOICES: '/app/invoices/approved',
    SENT_INVOICES: '/app/invoices/sent',
    INVOICE_DETAIL: '/app/invoices/edit/',
    DASHBOARD_SUMMARY: '/app/monitoring/summary',
    DASHBOARD_RATES: '/app/monitoring/rates',
    SERVICE_CONFIGURATION: '/app/configuration/services/list',
    EDIT_SERVICE_CONFIGURATION: '/app/configuration/services/edit',
    ADD_SERVICE_CONFIGURATION: '/app/configuration/services/add',
    SLA_LIST: '/app/configuration/sla/list',
    SLA_CONFIG: '/app/configuration/sla/details/'
}

export const getRedirectUrl = (redirectUrl: string): string => {
    return (redirectUrl !== '/okta' && redirectUrl.indexOf('/okta?redirectUrl') === -1) ? "?redirectUrl=" + btoa(redirectUrl) : "";
}