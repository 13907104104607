import {Autocomplete} from "@mui/material";
import TextField from "@mui/material/TextField";
import React from "react";
import {DataSourceT} from "../core/types";

const WEEK_DAYS: DataSourceT<number>[] = [
    { value: 0, label: "Domenica"},
    { value: 1, label: "Lunedì"},
    { value: 2, label: "Martedì"},
    { value: 3, label: "Mercoledì"},
    { value: 4, label: "Giovedì"},
    { value: 5, label: "Venerdì"},
    { value: 6, label: "Sabato"}
]

interface WeekDaysProps {
    day: number;
    onChange: (val: string) => void;
}

function WeekDays(props: WeekDaysProps) {
    const extractItem = (day: number) => {
        return day ? WEEK_DAYS[day] : WEEK_DAYS[0];
    }

    const [selectedDay, setSelectedDay] = React.useState<DataSourceT<number>>(extractItem(props.day));

    React.useEffect(() => {
        setSelectedDay(extractItem(props.day));
    }, [props.day])

    return <>
        <Autocomplete
            id="week-day"
            disableClearable
            fullWidth
            options={WEEK_DAYS}
            getOptionLabel={(option: DataSourceT<number>) => option.label}
            onChange={(_, value: DataSourceT<number>) => {
                setSelectedDay(value);
                props.onChange(`0 0 * * ${value.value}`);
            }}
            value={selectedDay}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Giorno"
                    InputProps={{
                        ...params.InputProps,
                        type: 'search',
                    }}
                />
            )}
            clearOnEscape
        />
    </>
}

export default WeekDays;