import {Group} from "../../../model/Customer";

const addBusinessName = (data: any, group: Group) => {
    return data.map(row => {
        const customer = group.members.find(c => +c.tppCompanyId === +row.companyId);
        return {
            ...row,
            businessName: customer ? customer.businessName : null
        };
    })
}

export default addBusinessName;