export class Sla {
    name: string;
    description: string;
    apiUrl: string;
    defaultValue: string;
}

export class SlaT extends Sla {
    slaValue: number;
}

export class SlaValue {
    batchId: number;
    sla: Sla;
    slaValue: number;
}

export class SlaConfigurationT {
    batchId: number;
    batchDescription: string;
    sla: SlaT[];

    constructor(batchId: number, batchDescription: string) {
        this.batchId = batchId;
        this.batchDescription = batchDescription;
        this.sla = [];
    }
}