export class MenuItem {
    label: string;
    icon?: string = '';
    route?: string;
    children?: MenuItem[] = [];
    open?: boolean = false;

    constructor(label: string, route?: string, icon: string = '') {
        this.label = label;
        this.route = route;
        this.icon = icon;
    }
}

export const menuItems: MenuItem[] = [
    {
        label: 'Dashboard',
        route: 'dashboard',
        icon: 'dashboard'
    },
    {
        label: 'Anagrafiche',
        icon: 'table-list',
        children: [
            new MenuItem('Prospect', 'customers/prospect', 'work_history'),
            new MenuItem('Clienti', 'customers/active', 'business'),
            new MenuItem('Disattivati', 'customers/history', 'history'),
            new MenuItem('In sospeso', 'customers/pending', 'pause_circle_outline'),
            new MenuItem('Distributori', 'customers/vendors', 'storefront'),
            new MenuItem('Gruppi', 'groups', 'workspaces'),
        ]
    },
    {
        label: 'Servizi',
        icon: 'inventory',
        children: [
            new MenuItem('Anagrafica Servizi', 'services/list', 'category'),
            //new MenuItem('Gestione Tariffe', 'prices', 'euro')
        ]
    },
    {
        label: 'Fatturazione',
        icon: 'point_of_sale',
        children: [
            new MenuItem('Gestione Fatture', 'invoices/pending', 'receipt'),
            new MenuItem("Fatture Approvate", 'invoices/approved', 'task'),
            new MenuItem('Fatture Inviate', 'invoices/sent', 'forward_to_inbox'),
            new MenuItem('Report Inviate', 'report/sent', 'summarize')
        ]
    },
    {
        label: 'Monitoraggio Aziende',
        icon: 'query_stats',
        children: [
            new MenuItem("Sintesi", 'monitoring/summary', 'summarize'),
            new MenuItem('Matching rates', 'monitoring/rates', 'insights'),
        ]
    },
    {
        label: 'Configurazioni',
        icon: 'settings',
        children: [
            new MenuItem("Servizi", 'configuration/services/list', 'view_module'),
            new MenuItem("SLA", 'configuration/sla/list', 'tune')
        ]
    }
]