import React from "react";
import {
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, TextField
} from "@mui/material";
import {ModalComponentProps} from "./CustomerComponentProps";
import {ServiceActivation} from "../../../model/Customer";
import {useFormik} from "formik";
import {Trans} from "react-i18next";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import '../../../index.scss';
import {useServiceActivation} from "../hook/useServiceActivation";
import {useServices} from "../../Services/hook/useServices";
import {ServiceStatuses} from "../core/DataSources";


function ServiceActivationModal(props: ModalComponentProps<ServiceActivation>) {
    const serviceActivationHook = useServiceActivation(props.data);
    const mapServices = React.useCallback(res => res.data, [])
    const serviceHook = useServices(mapServices);

    const formik = useFormik({
        initialValues: serviceActivationHook.serviceActivation,
        enableReinitialize: true,
       // validationSchema: GenericContactValidation(t, selectedType),
        onSubmit: (values) => {
            const v: ServiceActivation = {
                ...values,
                customer: {
                    customerId: props.customerId
                }
            };
            serviceActivationHook.saveServiceActivation(v, Boolean(values.serviceActivationId), res => res.data)
                .then(_ => props.onClose());
        },
    });

    const preventCloseOnBackdrop = () => {
        return;
    }

    return  <Dialog
        open={props.open}
        onClose={preventCloseOnBackdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{
            component: 'form',
            onSubmit: formik.handleSubmit,
        }}
    >
        <DialogTitle>
            <Trans>CUSTOMER_DETAILS.FIELDS.SERVICE_ACTIVATION.TITLE</Trans>
            <IconButton
                aria-label="close"
                onClick={props.onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <div style={{display:"flex", flexDirection: "column", gap: "1rem"}}>
                <FormControl variant="standard" fullWidth>
                    <InputLabel id="serviceLabel" error={Boolean(formik.errors.service?.serviceId)}>
                        <Trans>SERVICE_DETAILS.FIELDS.NAME</Trans>
                    </InputLabel>
                    <Select
                        labelId="serviceLabel"
                        id="service.serviceId"
                        value={formik.values?.service?.serviceId || -1}
                        defaultValue={-1}
                        onChange={formik.handleChange}
                        name="service.serviceId"
                        label={<Trans>SERVICE_DETAILS.FIELDS.NAME</Trans>}
                    >
                        <MenuItem defaultChecked value={-1}><Trans>SERVICE_DETAILS.NO_SERVICE</Trans></MenuItem>
                        {serviceHook.services.map(s => <MenuItem key={s.serviceId} value={s.serviceId}>{s.description}</MenuItem> )}
                    </Select>
                    <FormHelperText error>{formik.errors.service?.serviceId}</FormHelperText>
                </FormControl>
                <FormControl variant="standard" fullWidth>
                    <InputLabel id="serviceActivationLabel" error={Boolean(formik.errors.serviceActivationStatus)}>
                        <Trans>CUSTOMER_DETAILS.FIELDS.SERVICE_ACTIVATION.FIELDS.SERVICE_STATUS</Trans>
                    </InputLabel>
                    <Select
                        labelId="serviceActivationLabel"
                        id="serviceActivationStatus"
                        value={formik.values?.serviceActivationStatus || '-1'}
                        defaultValue='-1'
                        onChange={formik.handleChange}
                        name="serviceActivationStatus"
                        label={<Trans>CUSTOMER_DETAILS.FIELDS.SERVICE_ACTIVATION.FIELDS.SERVICE_STATUS</Trans>}
                    >
                        <MenuItem defaultChecked value={'-1'}><Trans>CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.NO_STATUS</Trans></MenuItem>
                        {ServiceStatuses.map(s => <MenuItem key={s} value={s}><Trans>SERVICE_STATUS.{s}</Trans></MenuItem> )}
                    </Select>
                    <FormHelperText error>{formik.errors.service?.serviceId}</FormHelperText>
                </FormControl>
                <TextField
                    fullWidth
                    variant="standard"
                    id="serviceActivationDate"
                    name="serviceActivationDate"
                    type="date"
                    label={<Trans>CUSTOMER_DETAILS.FIELDS.SERVICE_ACTIVATION.FIELDS.START_DATE</Trans>}
                    value={formik.values?.serviceActivationDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.serviceActivationDate && Boolean(formik.errors.serviceActivationDate)}
                    helperText={formik.touched.serviceActivationDate && formik.errors.serviceActivationDate}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    fullWidth
                    variant="standard"
                    id="serviceErpPlatform"
                    name="serviceErpPlatform"
                    label={<Trans>CUSTOMER_DETAILS.FIELDS.SERVICE_ACTIVATION.FIELDS.ERP</Trans>}
                    value={formik.values?.serviceErpPlatform}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.serviceErpPlatform && Boolean(formik.errors.serviceErpPlatform)}
                    helperText={formik.touched.serviceErpPlatform && formik.errors.serviceErpPlatform}
                    InputLabelProps={{ shrink: true }}
                />
                <FormControlLabel
                    label={<Trans>CUSTOMER_DETAILS.FIELDS.SERVICE_ACTIVATION.FIELDS.IS_BILLABLE</Trans>}
                    control={
                        <Checkbox
                            id="isBillingEnable"
                            name="isBillingEnable"
                            value={formik.values?.isBillingEnable || false}
                            checked={formik.values?.isBillingEnable || false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    }
                />
            </div>
            <DialogActions>
                <Button color="error" onClick={props.onClose}><Trans>COMMON.CANCEL</Trans></Button>
                <Button type="submit"><Trans>COMMON.SAVE</Trans></Button>
            </DialogActions>
        </DialogContent>
    </Dialog>
}

export default ServiceActivationModal;