import React from "react";
import {useAxios} from "../../../core/axiosHook";
import {ServiceConfiguration} from "../../ServiceConfiguration/core/ServiceConfiguration";
import {
    BatchParamsValue,
    CustomerConfiguration,
    CustomerConfigurationAssignRequest
} from "../core/CustomerConfiguration";
import {AxiosResponse} from "axios";


export function useCustomerConfiguration() {
    const [customerConfiguration , setCustomerConfiguration] = React.useState<CustomerConfiguration>();
    const {isLoading, request} = useAxios();

    const get = (customerId: number, serviceId: number) => {
        setCustomerConfiguration(null);
        return request({ method: "GET",
            url:`${process.env.REACT_APP_M1A009}/serviceConfig/customer/${customerId}?serviceId=${serviceId}`
        }).then((res: AxiosResponse<CustomerConfiguration[]>) => {
            return request({ method: "GET",
                url:`${process.env.REACT_APP_M1A009}/serviceConfig/customer/${customerId}/batchParams?serviceId=${serviceId}`
            }).then((r: AxiosResponse<BatchParamsValue[]>)=> {
                const data: CustomerConfiguration = res.data?.length ? res.data[0] : new CustomerConfiguration();

                setCustomerConfiguration({
                    ...data,
                    customerBatchParamList: [...r.data]
                });
            });
        });
    }

    const save = (values: CustomerConfigurationAssignRequest) => {
        return request({ method: "POST",
            url:`${process.env.REACT_APP_M1A009}/serviceConfig/customer/assign`, data: values }).then(res => {
            setCustomerConfiguration(res.data);
        });
    }

    return {customerConfiguration, isLoading, get, save};
}

export function useCustomerConfigurations() {
    const [customerConfigurations , setCustomerConfigurations] = React.useState<CustomerConfiguration[]>();
    const {isLoading, request} = useAxios();

    const get = (customerId: number) => {
        request({ method: "GET",
            url:`${process.env.REACT_APP_M1A009}/serviceConfig/customer/${customerId}`
        }).then((res: AxiosResponse<CustomerConfiguration[]>) => {
                setCustomerConfigurations(res.data);
        });

    }

    return {customerConfigurations, isLoading, get};
}

export function useServiceConfigurations() {
    const [serviceConfigurations, setServiceConfigurations] = React.useState<ServiceConfiguration[]>([]);

    const {isLoading, request} = useAxios();

    const get = (serviceId: number)  => {
        setServiceConfigurations([]);
        return request({ method: "GET",
            url:`${process.env.REACT_APP_M1A009}/serviceConfig?serviceId=${serviceId}` })
            .then(res => setServiceConfigurations(res.data));
    }

    return {serviceConfigurations, get, isLoading};
}