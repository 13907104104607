import * as yup from "yup";
import {Subscription} from "../../../../model/Customer";

export const SubAssignValidation = (t) =>
    yup.object<Subscription>().shape({
        service: yup.object({
            serviceId: yup.number().required(`${t("SERVICE_DETAILS.FIELDS.NAME")} ${t("COMMON.REQUIRED_M")}`)
        }),
        startDate: yup.date().required(`${t("CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.START_DATE")} ${t("COMMON.REQUIRED_F")}`),
        //endDate: yup.date().required(`${t("CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.END_DATE")} ${t("COMMON.REQUIRED_F")}`),
        //trialStartDate: yup.date().required(`${t("CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.TRIAL_START")} ${t("COMMON.REQUIRED_F")}`),
        //trialEndDate: yup.date().required(`${t("CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.TRIAL_END")} ${t("COMMON.REQUIRED_F")}`),
        //serviceFrequency: yup.number().required(`${t("CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.SERVICE_FREQUENCY")} ${t("COMMON.REQUIRED_F")}`)
           // .min(0, `${t("CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.SERVICE_FREQUENCY")} ${t("COMMON.GREATER_THAN_0")}`),
        //serviceFrequencyCharge: yup.number().required(`${t("CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.SERVICE_FREQUENCY_CHARGE")} ${t("COMMON.REQUIRED_F")}`)
           // .min(0, `${t("CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.SERVICE_FREQUENCY_CHARGE")} ${t("COMMON.GREATER_THAN_0")}`)
    });