import * as yup from "yup";
import {InvoiceItem} from "../Invoice";
import {TFunction} from "i18next";

export const InvoiceItemValidation = (t: TFunction) =>
    yup.object<InvoiceItem>().shape({
        //lineNumber: yup.string().required(`${t("INVOICE_DETAIL.FIELDS.")} ${t("COMMON.REQUIRED_F")}`)
        description: yup.string().required(`${t("INVOICE_DETAIL.FIELDS.DESCRIPTION")} ${t("COMMON.REQUIRED_F")}`.toString()),
        //quantity: yup.number().required(`${t("INVOICE_DETAIL.FIELDS.QUANTITY")} ${t("COMMON.REQUIRED_F")}`),
        //unit: yup.string().required(`${t("INVOICE_DETAIL.FIELDS.UNIT")} ${t("COMMON.REQUIRED_F")}`),
        //productCode: yup.string().required(`${t("INVOICE_DETAIL.FIELDS.PRODUCT_CODE")} ${t("COMMON.REQUIRED_M")}`.toString()),
        //startPeriod: yup.date().required(`${t("INVOICE_DETAIL.FIELDS.START_PERIOD")} ${t("COMMON.REQUIRED_F")}`),
        //endPeriod: yup.date().required(`${t("INVOICE_DETAIL.FIELDS.END_PERIOD")} ${t("COMMON.REQUIRED_F")}`),
        itemPrice: yup.number().required(`${t("INVOICE_DETAIL.FIELDS.ITEM_PRICE")} ${t("COMMON.REQUIRED_M")}`),
        taxableAmount: yup.string().required(`${t("INVOICE_DETAIL.FIELDS.TAXABLE_AMOUNT")} ${t("COMMON.REQUIRED_M")}`),
        taxAmount: yup.string().required(`${t("INVOICE_DETAIL.FIELDS.TAX_AMOUNT")} ${t("COMMON.REQUIRED_F")}`)
        //description: yup.string().required(`${t("INVOICE_DETAIL.FIELDS.")} ${t("COMMON.REQUIRED_F")}`)
    });