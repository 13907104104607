import React from 'react';
import Typography from "@mui/material/Typography";
import {Trans} from "react-i18next";
import BackButton from "../BackButton/BackButton";


interface PageTitleProps {
    title: string;
    enableBack?: boolean;
    children?: React.ReactNode;
}

export const PageTitle = (props: PageTitleProps) => {
    return <div className="section-header">
        <div style={{display: "flex", alignItems: "center"}} >
        {props.enableBack && <BackButton></BackButton>}
        <Typography variant="h6">
            <Trans>{props.title}</Trans>
        </Typography>
        </div>
        {props.children}
    </div>
}

export default PageTitle;