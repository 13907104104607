import Typography from "@mui/material/Typography";

interface DetailFieldProps {
    label: string;
    value: string | number;
}

export const DetailField = (props: DetailFieldProps) => {
    return <div style={{display: "flex", alignItems: "baseline"}}>
        <Typography variant="subtitle2">{props.label}:</Typography>
        &nbsp;
        <Typography variant="body1">{props.value}</Typography>
    </div>
}

export default DetailField;