import * as yup from "yup";
import {RateT, ThresholdsT} from "../../../../model/Customer";
import {TFunction} from "i18next";


export const RateValidation = (t: TFunction) =>
    yup.object<RateT>().shape({
        name: yup.string().required(`${t("SERVICE_DETAILS.FIELDS.RATES.NAME")} ${t("COMMON.REQUIRED_M")}`),
        description: yup.string().required(`${t("SERVICE_DETAILS.FIELDS.RATES.DESCRIPTION")} ${t("COMMON.REQUIRED_F")}`),
        //expirationDate: yup.string().required(),
        customerType: yup.string().required(`${t("SERVICE_DETAILS.FIELDS.RATES.CUSTOMER_TYPE")} ${t("COMMON.REQUIRED_F")}`),
        //billingFrequency: yup.number().required(`${t("SERVICE_DETAILS.FIELDS.RATES.BILLING_FREQUENCY")} ${t("COMMON.REQUIRED_F")}`)
          //  .min(0, `${t("SERVICE_DETAILS.FIELDS.RATES.BILLING_FREQUENCY")} ${t("COMMON.GREATER_THAN_0")}`),
        //isDefault: yup.string().required(),
        //trialLength: yup.number().required(`${t("SERVICE_DETAILS.FIELDS.RATES.TRIAL_LENGTH")} ${t("COMMON.REQUIRED_F")}`)
          //  .min(0, `${t("SERVICE_DETAILS.FIELDS.RATES.TRIAL_LENGTH")} ${t("COMMON.GREATER_THAN_0")}`),
        //defaultFrequency: yup.number().required(`${t("SERVICE_DETAILS.FIELDS.RATES.DEFAULT_FREQUENCY")} ${t("COMMON.REQUIRED_F")}`)
          //  .min(0, `${t("SERVICE_DETAILS.FIELDS.RATES.DEFAULT_FREQUENCY")} ${t("COMMON.GREATER_THAN_0")}`),
        //deltaFrequency: yup.number().required(`${t("SERVICE_DETAILS.FIELDS.RATES.DELTA_FREQUENCY")} ${t("COMMON.REQUIRED_F")}`)
          //  .min(0, `${t("SERVICE_DETAILS.FIELDS.RATES.DELTA_FREQUENCY")} ${t("COMMON.GREATER_THAN_0")}`),
        thresholds: yup.mixed().test('thresholds', ` ${t("COMMON.IS_PRESENT")} ${t("SERVICE_DETAILS.FIELDS.RATES.THRESHOLD.VALUE")}`, (tr: ThresholdsT[]) => {
                const notValid = tr.filter(t => !(t.flatCost && t.unitCost && t.value && t.businessDriver?.businessDriverId));
                return tr.length && notValid.length === 0;
        })
    });