import {ShortcutsValues} from "./types";

export const SHORTCUTS: ShortcutsValues[] = [
    {
        name: '@MONTHLY',
        value: '0 0 1 * *',
        position: 2
    },
    {
        name: '@WEEKLY',
        value: '0 0 * * 0',
        position: 4
    },
    {
        name: '@DAILY',
        value: '* * * * *',
        position: '*'
    },
];