import {Invoice} from "../core/Invoice";
import {Button, Card, CardContent, Grid, TextField, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import {calculateTotalAmount, calculateTotalInvoiceAmount, calculateTotalIva} from "../core/utils/InvoiceUtils";
import {currencyFormatter, dateFormatter} from "../../../core/utils";
import {useTranslation} from "react-i18next";
import {styled} from "@mui/material/styles";
import LinkIcon from "@mui/icons-material/Link";
import {ROUTES} from "../../../routes/ROUTES";
import {useNavigate} from "react-router-dom";

interface InvoicesDetailProps {
    invoice: Invoice
}

const CustomDisableInput = styled(TextField)(() => ({
    ".MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "rgb(46, 125, 50)",
    },
    "label.Mui-disabled": {
        WebkitTextFillColor: "rgb(46, 125, 50)" //"rgb(25, 118, 210)",
    }
}));

function DetailField(props: {label: string, fullWidth?: boolean, value: string | number}) {
    return <TextField
        disabled
        variant="standard"
        id="tppCompanyId"
        name="tppCompanyId"
        fullWidth={props.fullWidth}
        label={props.label}
        value={props.value}
        InputLabelProps={{shrink: true}}
    />;
}

function DetailFieldColored(props: {label: string, fullWidth?: boolean, value: string | number}) {
    return <CustomDisableInput
        disabled
        variant="standard"
        id="tppCompanyId"
        name="tppCompanyId"
        fullWidth={props.fullWidth}
        label={props.label}
        value={props.value}
        InputLabelProps={{shrink: true}}
    />;
}


function InvoicesGrid(props: InvoicesDetailProps) {
    const {t}= useTranslation();
    const navigate = useNavigate();

    const getAddress = `${props.invoice.recipientZipCode} ${props.invoice.recipientCity} ${props.invoice.recipientRegion}`;
    const getIssuerAddress = `${props.invoice.invoiceConfiguration?.issuerZipCode} ${props.invoice.invoiceConfiguration?.issuerCity} ${props.invoice.invoiceConfiguration?.issuerRegion}`

    return <Box sx={{display: 'flex', flexDirection: 'column', gap: '.5rem'}} >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Card>
                    <CardContent>
                        <Grid container columns={2} spacing={2} alignItems="center">
                            <Grid item xs={2}>
                                <DetailField fullWidth label="Denominazione" value={props.invoice.invoiceConfiguration?.issuerBusinessName}/>
                            </Grid>
                            <Grid item flexGrow={1}>
                                <DetailField fullWidth label="Indirizzo" value={props.invoice.invoiceConfiguration?.issuerStreetAddress}/>
                            </Grid>
                            <Grid item>
                                <DetailField fullWidth label="Città" value={getIssuerAddress}/>
                            </Grid>
                            <Grid item xs={2}>
                                <DetailField fullWidth label="Partita IVA" value={props.invoice.recipientVatNumber}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                </Grid>
                <Grid item xs={6}>
                <Card>
                    <CardContent>
                        {props.invoice.customer &&
                        <Grid container columns={12} spacing={2} alignItems="center">
                            <Grid item xs={3}>
                                <DetailField fullWidth label="Codice" value={props.invoice.customer.customerCode}/>
                            </Grid>
                            <Grid item xs={9} flexGrow={1} style={{display: 'flex'}}>
                                <DetailField fullWidth label="Denominazione" value={props.invoice.customer.businessName}/>
                                <Tooltip title={t('COMMON.GO_TO_CUSTOMER').toString()}>
                                    <Button onClick={() => navigate(ROUTES.EDIT_CUSTOMER + props.invoice.customer.customerId)}>
                                        <LinkIcon  />
                                    </Button>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} >
                                <DetailField fullWidth label="Indirizzo" value={props.invoice.recipientStreetAddress}/>
                            </Grid>
                            <Grid item xs={12}>
                                <DetailField fullWidth label="Città" value={getAddress}/>
                            </Grid>
                        </Grid>
                        }
                    </CardContent>
                </Card>
                </Grid>
            </Grid>

        <Card>
            <CardContent>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                        <DetailField fullWidth label="Codice Gestionale" value={props.invoice.customer?.tppCompanyId} />
                    </Grid>
                    <Grid item xs={3}>
                        <DetailField fullWidth label="Partita IVA" value={props.invoice.recipientVatNumber} />
                    </Grid>
                    <Grid item xs={3}>
                        <DetailField fullWidth label="Numero Fattura" value={props.invoice.invoiceNumber} />
                    </Grid>
                    <Grid item xs={3}>
                        <DetailField fullWidth label="Data Fattura" value={dateFormatter(props.invoice.billingDate)} />
                    </Grid>

                    <Grid item xs={6}>
                        <DetailField fullWidth label="Tipologia pagamento" value={t("PAYMENT_TYPES." + props.invoice.paymentType).toString()} />
                    </Grid>
                    <Grid item xs={6}>
                        <DetailField fullWidth label="Banca d'appoggio" value={props.invoice.customer?.iban} />
                    </Grid>

                    <Grid item xs={4}>
                        <DetailField fullWidth label="Codice Fiscale" value={props.invoice.customer?.taxCode} />
                    </Grid>
                    <Grid item xs={4}>
                        <DetailField fullWidth label="Valuta" value={'EURO'} />
                    </Grid>
                    <Grid item xs={4}>
                        <DetailField fullWidth label="Tipo documento" value={t("DOCUMENT_TYPES." + props.invoice.documentType).toString()} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        <Card>
            <CardContent>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                        <DetailField fullWidth label="Imponibile" value={currencyFormatter(calculateTotalAmount(props.invoice))} />
                    </Grid>
                    <Grid item xs={3}>
                        <DetailField fullWidth label="Imposta" value={currencyFormatter(calculateTotalIva(props.invoice))} />
                    </Grid>
                    <Grid item xs={3}>
                        <DetailFieldColored fullWidth label="Totale Documento" value={currencyFormatter(calculateTotalInvoiceAmount(props.invoice))} />
                    </Grid>
                    <Grid item xs={3}>
                        <DetailFieldColored fullWidth label="Scadenza Pagamento" value={dateFormatter(props.invoice.paymentDueDate)} />
                    </Grid>

                </Grid>
            </CardContent>
        </Card>
    </Box>
}

export default InvoicesGrid;