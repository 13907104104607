import React from "react";

const useModalState = () => {
    const [open, setOpen] = React.useState(false);

    const toggle = () => {
        setOpen(!open)
    }

    return {open, toggle}
}

export default useModalState;