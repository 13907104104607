import React from "react";
import { ManualExec } from "../core/CustomerConfiguration";
import { useAxios } from "src/core/axiosHook";

export function useManualExec() {
  const [manualExec, setManualExec] = React.useState<ManualExec>();
  const { isLoading, request } = useAxios();

  const save = (values: ManualExec, isUpdate: any) => {
    return request({
      method: isUpdate ? "PUT" : "POST",
      url: `${process.env.REACT_APP_M1A009}/serviceManualExecution`,
      data: values,
    }).then((res) => {
      setManualExec(res.data);
    });
  };

  return { manualExec, isLoading, save };
}
