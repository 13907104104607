import React from "react";
import {useAxios} from "../../../core/axiosHook";
import {ServiceActivation} from "../../../model/Customer";
import {AxiosResponse} from "axios";

export function useServiceActivation(initialValue: ServiceActivation) {
    const [serviceActivation , setServiceActivation] = React.useState<ServiceActivation>(initialValue);
    const {isLoading, request} = useAxios();

    React.useEffect(() => {setServiceActivation(initialValue)}, [initialValue]);

    const saveServiceActivation = (values: ServiceActivation, isUpdate, callback: (res:AxiosResponse<ServiceActivation>) => ServiceActivation) => {
        return request({ method: isUpdate ? "PUT" : "POST",
            url:`${process.env.REACT_APP_BE_INGRESS}/serviceActivations` + (isUpdate ? `/${values.serviceActivationId}` : ''), data: values }).then(res => {
            setServiceActivation(callback(res));
        });
    }

    return {serviceActivation, isLoading, saveServiceActivation};
}

export function useServiceActivations(customerId: number, callback: (res:AxiosResponse<ServiceActivation[]>) => ServiceActivation[]) {
    const [serviceActivations , setServiceActivations] = React.useState<ServiceActivation[]>();
    const {isLoading, request, setReload, reload} = useAxios();

    React.useEffect(() => {
        if (customerId) {
            request({
                method: "GET",
                url: `${process.env.REACT_APP_BE_INGRESS}/serviceActivations?customerId=${customerId}`
            }).then(res => {
                setServiceActivations(callback(res));
            });
        }
    }, [customerId, reload]);

    return {serviceActivations, isLoading, setReload};

}