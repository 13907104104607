import {useParams} from "react-router-dom";
import CustomerAnag from "../components/CustomerAnag";
import Typography from "@mui/material/Typography";
import {Trans} from "react-i18next";
import VendorFee from "../components/VendorFee";

function VendorDetail() {
    let { customerId } = useParams();

    return <div style={{display: 'flex', flexDirection: 'column', gap: '.5rem'}}>
        <Typography variant="h6"><Trans>CUSTOMER_DETAILS.TITLE</Trans></Typography>
        <CustomerAnag id={+customerId} key={`a_${customerId}`} />
        <VendorFee id={+customerId} key={`f_${customerId}`} />
    </div>
}

export default VendorDetail;