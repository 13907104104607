export enum SERVICE_FREQUENCY {
    DAILY = "DAILY",
    WEEKLY = "WEEKLY",
    MONTHLY = "MONTHLY",
    MANUAL = "MANUAL"
}

export class ServiceConfiguration {
    serviceConfigId: number;
    serviceId: number;
    serviceConfigName: string;
    batchParamsValues: BatchParamsValue[];
    frequency: SERVICE_FREQUENCY;

    constructor() {
        this.batchParamsValues = [];
    }
}

export class CustomerConfiguration {
    customerId: number;
    cronExpression: string;
    isEnable: boolean;
    serviceConfig: ServiceConfiguration;
    customerBatchParamList: BatchParamsValue[];

    constructor() {
        this.customerBatchParamList = [];
        this.serviceConfig = new ServiceConfiguration();
        this.isEnable = true;
    }
}

export class CustomerConfigurationAssignRequest {
    customerId: number;
    cronExpression: string;
    serviceConfigId: number;
    customerBatchParamList: BatchParamsValue[];
    isEnable: boolean;

    constructor() {
        this.customerBatchParamList = [];
    }
}

export class ManualExec {
    serviceId: number;
    customerId: number;
    executionDate: string;
}

export class BatchParam {
    batchId: number;
    paramName: string;
    valueType: string;
    defaultValue: string;
    isMandatory: boolean;
    isEnable: boolean;
    paramValue?: string;
    paramDescription: string;
}

export class BatchParamsValue {
    batchId: number;
    paramName: string;
    paramValue: string;

    constructor(batchId: number, paramName: string, paramValue: string) {
        this.batchId = batchId;
        this.paramValue = paramValue;
        this.paramName = paramName;
    }
}

export class Batch {
    batchId: number;
    batchName: string;
    batchParamList: BatchParam[];
    batchDescription: string;
}