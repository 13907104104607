import React from "react";
import {AxiosResponse} from "axios";
import {Contact} from "../../../model/Customer";
import {useCustomer} from "../hook/useCustomer";
import SuspenseComponent from "../../Layout/SuspenseComponent";
import {CustomerComponentProps} from "./CustomerComponentProps";
import Typography from "@mui/material/Typography";
import {Button, Card, CardContent, CardHeader, Icon, Tooltip} from "@mui/material";
import {GridActionsCellItem, GridColDef, GridRowParams} from "@mui/x-data-grid";
import InfoIcon from "@mui/icons-material/Info";
import {Trans, useTranslation} from "react-i18next";
import ContactDetail from "./ContactDetail";
import AddIcon from "@mui/icons-material/Add";
import FavoriteIcon from '@mui/icons-material/Favorite';
import CircleIcon from '@mui/icons-material/Circle';
import {DataGridPro} from "@mui/x-data-grid-pro";
import {getContactTypeIcon} from "../core/DataSources";
import Placeholder from "../../SkinnableComponents/Placeholder/Placehoder";

function CustomerContact(props:CustomerComponentProps) {
    const {t} = useTranslation();
    const [selectedRow, setSelectedRow] = React.useState<Contact>(null);

    const columns: GridColDef[] = [
        {
            field: 'details', headerName: '', type: 'actions',
            getActions: (params: GridRowParams<Contact>) => [
                <GridActionsCellItem label="Dettagli" icon={<Tooltip title={t('COMMON.DETAILS').toString()}><InfoIcon /></Tooltip>}
                                     onClick={() => { setSelectedRow(params.row)} }
                />
            ]
        },
        { field: 'contactType', headerName: t("CUSTOMER_DETAILS.FIELDS.CONTACTS.TYPE"), renderCell: params =>  <Tooltip title={<Trans>CONTACT_TYPE.{params.row.contactType.toString()}</Trans>}><Icon>{getContactTypeIcon(params.row.contactType)}</Icon></Tooltip> },
        { field: 'description', headerName: t("CUSTOMER_DETAILS.FIELDS.CONTACTS.DESCRIPTION"), flex:1 },
        { field: 'name', headerName: t("CUSTOMER_DETAILS.FIELDS.CONTACTS.NAME"), flex:1 },
        { field: 'surname', headerName: t("CUSTOMER_DETAILS.FIELDS.CONTACTS.SURNAME"), flex:1 },
        { field: 'email', headerName: t("CUSTOMER_DETAILS.FIELDS.CONTACTS.EMAIL"), flex: 1 },
        { field: 'phoneNumber', headerName: t("CUSTOMER_DETAILS.FIELDS.CONTACTS.TELEPHONE") },
        // { field: 'address', headerName: t("CUSTOMER_DETAILS.FIELDS.CONTACTS.STREET"), renderCell: params => `${params.row.street || ''} ${params.row.city || '-'} (${params.row.zipCode || '-'})`, flex: 1 },
        { field: 'isFavorite', headerName: t("CUSTOMER_DETAILS.FIELDS.CONTACTS.IS_FAVORITE"), renderCell: params => params.row.isFavorite && <FavoriteIcon color="primary" />},
        { field: 'isEnable', headerName: t("CUSTOMER_DETAILS.FIELDS.CONTACTS.IS_ENABLED"), renderCell: params => <CircleIcon color={params.row.isEnable ? "success" : "error"} />},

    ]

    const mapContacts = (res: AxiosResponse<Contact[]>) => {
        return {
            contacts: res.data
        }
    }

    const contactsHook = useCustomer(`/contacts?customerId=`,props.id, mapContacts);

    return (
        <SuspenseComponent isLoading={contactsHook.isLoading}>
            <Card>
                <CardHeader title={<div className="section-header">
                    <Typography variant="h6" component="div">
                        <Trans>CUSTOMER_DETAILS.FIELDS.CONTACTS.TITLE</Trans>
                    </Typography>
                    <Button
                        aria-label="add"
                        onClick={_ => setSelectedRow(new Contact())}
                    >
                        <AddIcon />
                        <Typography>
                            <Trans>COMMON.ADD</Trans>
                        </Typography>
                    </Button>
                </div>}>
                </CardHeader>
                <CardContent>
                    {contactsHook.customer.contacts?.length
                        ? <DataGridPro loading={contactsHook.isLoading}
                                     getRowId={(row: Contact) => row.contactId}
                                     rows={contactsHook.customer.contacts}
                                     columns={columns}
                                     hideFooter
                        />
                        : <Placeholder></Placeholder>
                    }
                    {selectedRow && <ContactDetail
                        onClose={() => { contactsHook.setReload(true); setSelectedRow(null) }}
                        open={Boolean(selectedRow)}
                        data={selectedRow}
                        extraData={selectedRow.contactType}
                        customerId={props.id}
                    ></ContactDetail>}
                </CardContent>
            </Card>
        </SuspenseComponent>
    )
}

export default CustomerContact;