import React from "react";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import InfoIcon from "@mui/icons-material/Info";
import { AxiosResponse } from "axios";
import {
  CustomerAnagDetails,
  ServiceActivation,
  Service,
  Subscription,
} from "../../../model/Customer";
import { useCustomer } from "../hook/useCustomer";
import SuspenseComponent from "../../Layout/SuspenseComponent";
import { Button, Card, CardContent, CardHeader, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { CustomerComponentProps } from "./CustomerComponentProps";
import { Trans, useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import SubAssignModal from "./SubAssignModal";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { dateFormatter, percentageFormatter } from "../../../core/utils";
import ServiceActivationModal from "./ServiceActivationModal";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import { useServiceActivations } from "../hook/useServiceActivation";
import Placeholder from "../../SkinnableComponents/Placeholder/Placehoder";
import CustomerConfigurationModal from "./CustomerConfigurationModal";
import useModalState from "../hook/UseModalState";
import {FiberManualRecord, PlayCircleOutline} from "@mui/icons-material";
import ManualExecModal from "./ManualExecModal";
import {useServices} from "../hook/useServices";
import {useCustomerConfigurations} from "../hook/useCustomerConfiguration";
import {CustomerConfiguration} from "../core/CustomerConfiguration";

function CustomerSubs(props: CustomerComponentProps) {

  const getColorByStatus = (data: CustomerConfiguration) => {
    if (!data) return 'error';
    return data.isEnable ? 'success' : 'warning';
  }

  const { t } = useTranslation();
  const [selectedSub, setSelectedSub] = React.useState<Subscription>(null);
  const [selectedServiceActivation, setSelectedServiceActivation] =
    React.useState<ServiceActivation>(null);

  const customerConfigurationModalHook = useModalState<Service>();
  const manualExecModalHook = useModalState<Service>();

  const isConfigureEnabled = (params: GridRowParams<ServiceActivation>): boolean => {
      const service = serviceHook.services?.find(s => s.serviceId === params.row?.service?.serviceId);
      return service?.batchSize > 0;
  }

  const serviceColumns: GridColDef<ServiceActivation>[] = [
    {
      field: "details",
      headerName: "",
      type: "actions",
      getActions: (params: GridRowParams<ServiceActivation>) => [
        <GridActionsCellItem
          label="details"
          icon={
            <Tooltip title={t("COMMON.DETAILS").toString()}>
              <InfoIcon />
            </Tooltip>
          }
          onClick={() => {
            setSelectedServiceActivation(params.row);
          }}
        />,
      ],
    },
    {
      field: "serviceId",
      headerName: t("CUSTOMER_DETAILS.FIELDS.SUBS.SERVICE_NAME"),
      flex: 2,
      valueGetter: (params: GridValueGetterParams<ServiceActivation>) =>
        params.row.service?.description,
    },
    {
      field: "serviceActivationStatus",
      flex: 1,
      headerName: t("CUSTOMER_DETAILS.FIELDS.SUBS.SERVICE_STATUS"),
    },
    {
      field: "serviceActivationDate",
      flex: 1,
      headerName: t("CUSTOMER_DETAILS.FIELDS.SUBS.START_DATE"),
      valueFormatter: (params) => dateFormatter(params.value),
    },
    {
      field: "serviceErpPlatform",
      flex: 1,
      headerName: t("CUSTOMER_DETAILS.FIELDS.SERVICE_ACTIVATION.FIELDS.ERP"),
    },
    {
      field: "assign",
      headerName: "",
      width: 50,
      type: "actions",
      getActions: (params: GridRowParams<ServiceActivation>) => [
        <GridActionsCellItem
            label={t("COMMON.ASSIGN")}
            icon={
              <Tooltip title={t("COMMON.ADD_SUB").toString()}>
                <MoreTimeIcon color="primary" />
              </Tooltip>
            }
            onClick={() => {
              setSelectedSub({
                ...new Subscription(customerHook.customer?.details, {
                  serviceId: params.row.service.serviceId,
                }),
              });
            }}
        />,
      ],
    },
    {
      field: "configure",
      headerName: "",
      type: "actions",
      width: 50,
      getActions: (params: GridRowParams<ServiceActivation>) => {
        return isConfigureEnabled(params) ? [
          <GridActionsCellItem
              label={t("COMMON.CONFIGURE")}
              icon={
                <Tooltip title={t("COMMON.CONFIGURE").toString()}>
                  <SettingsIcon color="primary"/>
                </Tooltip>
              }
              onClick={() =>
                  customerConfigurationModalHook.toggle(params.row.service)
              }
          />,
        ] : [];
      },
    },
    {
      field: "manualExec",
      headerName: "",
      type: "actions",
      width: 50,
      getActions: (params: GridRowParams<ServiceActivation>) => {
        return isConfigureEnabled(params) ? [
          <GridActionsCellItem
            label={t("CUSTOMER_DETAILS.FIELDS.MANUAL_EXEC.TITLE")}
            icon={
              <Tooltip
                title={t("CUSTOMER_DETAILS.FIELDS.MANUAL_EXEC.TITLE").toString()}
              >
                <PlayCircleOutline color="primary" />
              </Tooltip>
            }
            onClick={() => {
              manualExecModalHook.toggle(params.row.service);
            }}
          />,
        ] : []
      },
    },
    {
      field: 'config-status',
      width: 150,
      align: "center",
      headerName: t("CUSTOMER_DETAILS.FIELDS.SERVICE_ACTIVATION.FIELDS.CONFIG_STATUS"),
      renderCell: (params) => {
        const data = customerConfigurationsHook.customerConfigurations
            .find(x=> x.serviceConfig?.serviceId === params.row.service?.serviceId);

        const color = getColorByStatus(data);
        const label = t("CUSTOMER_DETAILS.FIELDS.SERVICE_ACTIVATION.CONFIG_STATUS." + color.toUpperCase());

        return <Tooltip title={label}>
          <FiberManualRecord color={color} />
        </Tooltip>
      }
    },
  ];

  const columns: GridColDef<Subscription>[] = [
    {
      field: "details",
      headerName: "",
      type: "actions",
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          label="Dettagli"
          icon={
            <Tooltip title={t("COMMON.DETAILS").toString()}>
              <InfoIcon />
            </Tooltip>
          }
          onClick={() => {
            setSelectedSub(params.row);
          }}
        />,
      ],
    },
    {
      field: "service.description",
      headerName: t("CUSTOMER_DETAILS.FIELDS.SUBS.SERVICE_NAME"),
      valueGetter: (params) => params.row.service?.description,
      flex: 1,
    },
    {
      field: "rate.name",
      headerName: t("CUSTOMER_DETAILS.FIELDS.SUBS.NAME"),
      valueGetter: (params) => params.row.rate.name,
      flex: 1,
    },
    {
      field: "rate.description",
      headerName: t("CUSTOMER_DETAILS.FIELDS.SUBS.DESCRIPTION"),
      valueGetter: (params) => params.row.rate.description,
      flex: 1,
    },
    {
      field: "startDate",
      headerName: t("CUSTOMER_DETAILS.FIELDS.SUBS.START_DATE"),
      valueFormatter: (params) => dateFormatter(params.value),
    },
    {
      field: "endDate",
      headerName: t("CUSTOMER_DETAILS.FIELDS.SUBS.END_DATE"),
      valueFormatter: (params) => dateFormatter(params.value),
    },
    {
      field: "trialStartDate",
      headerName: t("CUSTOMER_DETAILS.FIELDS.SUBS.TRIAL_START"),
      valueFormatter: (params) => dateFormatter(params.value),
    },
    {
      field: "trialEndDate",
      headerName: t("CUSTOMER_DETAILS.FIELDS.SUBS.TRIAL_END"),
      valueFormatter: (params) => dateFormatter(params.value),
    },
    {
      field: "extraCharge",
      headerName: t("CUSTOMER_DETAILS.FIELDS.SUBS.EXTRA_CHARGE"),
      valueFormatter: (params) => percentageFormatter(params.value),
    },
    {
      field: "discount",
      headerName: t("CUSTOMER_DETAILS.FIELDS.SUBS.DISCOUNT"),
      valueFormatter: (params) => percentageFormatter(params.value),
    },
  ];

  const mapSubscriptions = (res: AxiosResponse<Subscription[]>) => {
    return {
      subs: res.data || [],
    };
  };

  const mapServiceActivation = (
    res: AxiosResponse<ServiceActivation[]>
  ): ServiceActivation[] => {
    return res.data || [];
  };

  React.useEffect(() => {
    serviceHook.get();
    customerConfigurationsHook.get(props.id);
  }, [])


  const customerHook = useCustomer<CustomerAnagDetails>(
    `/customers/`,
    props.id,
    (res) => ({ details: res.data })
  );

  const serviceHook = useServices();
  const customerConfigurationsHook = useCustomerConfigurations();
  const serviceActivationHook = useServiceActivations(props.id, mapServiceActivation);
  const subsHook = useCustomer(
    `/subscriptions?customerId=`,
    props.id,
    mapSubscriptions
  );

  return (
    <SuspenseComponent isLoading={subsHook.isLoading && serviceActivationHook.isLoading}>
      <Card>
        <CardHeader
          title={
            <div className="section-header">
              <Typography variant="h6" component="div">
                <Trans>CUSTOMER_DETAILS.FIELDS.SUBS.TITLE</Trans>
              </Typography>
              <div>
                <Button
                  aria-label="assign"
                  // onClick={_ => setSelectedSub({...new Subscription(customerHook.customer?.details)})}
                  onClick={(_) =>
                    setSelectedServiceActivation({ ...new ServiceActivation() })
                  }
                >
                  <AddIcon />
                  <Typography>
                    <Trans>COMMON.ADD_SERVICE</Trans>
                  </Typography>
                </Button>
              </div>
            </div>
          }
        ></CardHeader>
        <SubAssignModal
          open={Boolean(selectedSub)}
          data={selectedSub}
          onClose={() => {
            subsHook.setReload(true);
            setSelectedSub(null);
          }}
          customerId={props.id}
        />
        <ServiceActivationModal
          open={Boolean(selectedServiceActivation)}
          data={selectedServiceActivation}
          customerId={props.id}
          onClose={() => {
            serviceActivationHook.setReload(true);
            setSelectedServiceActivation(null);
          }}
        />
        <CustomerConfigurationModal
          open={Boolean(customerConfigurationModalHook.open)}
          data={customerConfigurationModalHook.data}
          customerId={props.id}
          onClose={() => {
            customerConfigurationsHook.get(props.id);
            customerConfigurationModalHook.toggle(null);
          }}
        />
        <ManualExecModal
          open={Boolean(manualExecModalHook.open)}
          data={manualExecModalHook.data}
          customerId={props.id}
          onClose={() => {
            manualExecModalHook.toggle(null);
          }}
        />

        <CardContent>
          {serviceActivationHook.serviceActivations?.length && serviceHook.services && !customerConfigurationsHook.isLoading ? (
            <DataGridPro
              loading={serviceActivationHook.isLoading}
              getRowId={(row: ServiceActivation) => row.service?.serviceId}
              rows={serviceActivationHook.serviceActivations}
              columns={serviceColumns}
              hideFooter
              getDetailPanelHeight={() => "auto"} // Height based on the content.
              getDetailPanelContent={({ row }) => (
                <div style={{ padding: ".5rem" }}>
                  {subsHook.customer.subs.filter(
                    (s) => s.service?.serviceId === row.service.serviceId
                  )?.length ? (
                    <DataGridPro
                      loading={subsHook.isLoading}
                      getRowId={(row: Subscription) => row.subscriptionId}
                      rows={subsHook.customer.subs.filter(
                        (s) => s.service?.serviceId === row.service.serviceId
                      )}
                      columns={columns}
                      hideFooter
                    />
                  ) : (
                    <div>
                      Non sono presenti dettagli di fatturazione su questo
                      servizio per il cliente selezionato
                    </div>
                  )}
                </div>
              )}
            />
          ) : (
            <Placeholder></Placeholder>
          )}
        </CardContent>
      </Card>
    </SuspenseComponent>
  );
}

export default CustomerSubs;
