import React from "react";
import {useAxios} from "../../../core/axiosHook";
import {Service} from "../../../model/Customer";

export function useServices() {
    const [services, setServices] = React.useState<Service[]>([]);
    const {isLoading, request} = useAxios();

    const get =() => {
        request({ method: "GET", url:`${process.env.REACT_APP_BE_INGRESS}/services` }).then(res => {
            setServices(res.data);
        });
    };

    return {services, isLoading, get};
}