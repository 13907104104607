import React, {useState} from 'react';
import './App.scss';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Layout from "./modules/Layout/Layout";
import './i18n';
import OktaLogin from "./modules/Login/OktaLogin";
import {Security} from "@okta/okta-react";
import {Routes, Route, useNavigate, Navigate} from "react-router-dom";
import {oktaAuth as oAuth, oktaLogout} from "./modules/Login/oktaCustomConfig";
import OktaLoginCallback from "./modules/Login/OktaLoginCallback";
import {ROUTES} from "./routes/ROUTES";
import {AuthState} from "@okta/okta-auth-js";
import {useErrors, useIsLoading} from "./core/useIsLoading";
import {configAxios} from "./core/axiosInstance";
import {Alert, CircularProgress, Snackbar} from "@mui/material";
import ErrorMessage from "./core/ErrorMessage";
import {LicenseInfo} from "@mui/x-data-grid-pro";

function App() {
    LicenseInfo.setLicenseKey(process.env.REACT_APP_LICENSE);
    const loadingHook = useIsLoading();
    const errorHook = useErrors();

    configAxios(loadingHook, errorHook);
    const navigate = useNavigate();
    const [oAuthState, setOAuthState] = useState<AuthState>(null);

    const onAuthRequired = () => {
       oktaLogout(ROUTES.OKTA_LOGIN).then();
    };

    const restoreOriginalUri = async (_oktaAuth) => {
        navigate(ROUTES.ROOT);
    };

    oAuth().authStateManager.subscribe(state => {
        setOAuthState(state);
    })

    return <React.Suspense fallback="loading">
        <Security oktaAuth={oAuth()} onAuthRequired={onAuthRequired} restoreOriginalUri={restoreOriginalUri}>
            {oAuthState &&
            <>
                { loadingHook.isLoading && <div className="page-loader"><CircularProgress color="primary" variant="indeterminate" /></div> }
                <Routes>
                    {oAuthState.isAuthenticated
                        ? <>
                            <Route path="/" element={<Navigate to="/app"></Navigate>}/>
                            <Route path="/login" element={<Navigate to="/app"></Navigate>}/>
                            <Route path="/app/*" element={<Layout/>}/>
                          </>
                        : <>
                            <Route path="/" element={<Navigate to="/login"></Navigate>}/>
                            <Route path="/login" element={<OktaLogin/>}/>
                            <Route path="okta/login/callback" element={<OktaLoginCallback/>}/>
                            <Route path="*" element={<Navigate to="/login"></Navigate>}/>
                        </>
                    }
                    <Route path="*" element={<OktaLogin/>}/>
                </Routes>
                <> {errorHook.errors.map((err: ErrorMessage, index: number) =>
                    <Snackbar key={index} open={err.visible} anchorOrigin={{ vertical: 'bottom', horizontal:'center'}} autoHideDuration={6000} onClose={_ => errorHook.hide(index)}>
                        <Alert onClose={_ => errorHook.hide(index)} severity="error" sx={{ width: '100%' }}>
                            {err.description}
                        </Alert>
                    </Snackbar>
                )}
                </>

            </>
            }
        </Security>
    </React.Suspense>

}

export default App;
