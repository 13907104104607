import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, FormHelperText,
    Grid, InputLabel,
    TextField
} from "@mui/material";
import {useFormik} from "formik";
import {Trans, useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import '../../../index.scss';
import {InvoiceItem} from "../core/Invoice";
import {InvoiceHookT} from "../hook/useInvoices";
import {InvoiceItemValidation} from "../core/validation/InvoiceItemValidation";
import CurrencyInput from "react-currency-input-field";


export interface InvoiceItemDetailProps {
    open: boolean;
    data: InvoiceItem;
    onClose: () => void;
    invoiceHook: InvoiceHookT;
}

function InvoiceItemDetail(props: InvoiceItemDetailProps) {
    const {t} = useTranslation();


    const formik = useFormik({
        initialValues: props.data,
        enableReinitialize: true,
        validationSchema: InvoiceItemValidation(t),
        onSubmit: (values) => {
            props.invoiceHook.save(values, res => res.data);
        },
    });

    return  <Dialog
        open={props.open}
        onClose={_ => { return }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{
            component: 'form',
            onSubmit: formik.handleSubmit,
        }}
    >
        <DialogTitle>
            <Trans>INVOICE_DETAIL.INVOICE_ITEM_TITLE</Trans>
            <IconButton
                aria-label="close"
                onClick={props.onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
                <Grid container spacing={1} columns={12}>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            variant="standard"
                            id="productCode"
                            name="productCode"
                            label={<Trans>INVOICE_DETAIL.FIELDS.PRODUCT_CODE</Trans>}
                            value={formik.values.productCode}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.productCode && Boolean(formik.errors.productCode)}
                            helperText={formik.touched.productCode && formik.errors.productCode}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            fullWidth
                            variant="standard"
                            id="description"
                            name="description"
                            label={<Trans>INVOICE_DETAIL.FIELDS.DESCRIPTION</Trans>}
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            type="number"
                            variant="standard"
                            id="quantity"
                            name="quantity"
                            label={<Trans>INVOICE_DETAIL.FIELDS.QUANTITY</Trans>}
                            value={formik.values.quantity}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                            helperText={formik.touched.quantity && formik.errors.quantity}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            variant="standard"
                            id="unit"
                            name="unit"
                            label={<Trans>INVOICE_DETAIL.FIELDS.UNIT</Trans>}
                            value={formik.values.unit}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.unit && Boolean(formik.errors.unit)}
                            helperText={formik.touched.unit && formik.errors.unit}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl variant="standard" fullWidth
                                     className="input-currency">
                            <InputLabel id="itemPriceLabel" error={Boolean(formik.errors.itemPrice)}>
                                <Trans>INVOICE_DETAIL.FIELDS.ITEM_PRICE</Trans>
                            </InputLabel>
                            <div>
                                <CurrencyInput
                                    id="itemPrice"
                                    name="itemPrice"
                                    value={formik.values.itemPrice}
                                    onValueChange={value => value ? formik.setFieldValue('itemPrice', value) : 0}
                                    decimalsLimit={2}
                                    allowNegativeValue={false}
                                    defaultValue={0}
                                    prefix="€"
                                    decimalSeparator=","
                                    groupSeparator="."
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            <FormHelperText error>{formik.errors.itemPrice}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            type="date"
                            variant="standard"
                            id="startPeriod"
                            name="startPeriod"
                            label={<Trans>INVOICE_DETAIL.FIELDS.START_PERIOD</Trans>}
                            value={formik.values.startPeriod}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.startPeriod && Boolean(formik.errors.startPeriod)}
                            helperText={formik.touched.startPeriod && formik.errors.startPeriod}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            variant="standard"
                            type="date"
                            id="endPeriod"
                            name="endPeriod"
                            label={<Trans>INVOICE_DETAIL.FIELDS.END_PERIOD</Trans>}
                            value={formik.values.endPeriod}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.endPeriod && Boolean(formik.errors.endPeriod)}
                            helperText={formik.touched.endPeriod && formik.errors.endPeriod}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl variant="standard" fullWidth
                                     className="input-currency">
                            <InputLabel id="taxableAmountLabel" error={Boolean(formik.errors.taxableAmount)}>
                                <Trans>INVOICE_DETAIL.FIELDS.TAXABLE_AMOUNT</Trans>
                            </InputLabel>
                            <div>
                                <CurrencyInput
                                    id="taxableAmount"
                                    name="taxableAmount"
                                    value={formik.values.taxableAmount}
                                    onValueChange={value => value ? formik.setFieldValue('taxableAmount', value) : 0}
                                    decimalsLimit={2}
                                    allowNegativeValue={false}
                                    defaultValue={0}
                                    prefix="€"
                                    decimalSeparator=","
                                    groupSeparator="."
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            <FormHelperText error>{formik.errors.taxableAmount}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            variant="standard"
                            type="number"
                            id="taxAmount"
                            name="taxAmount"
                            label={<Trans>INVOICE_DETAIL.FIELDS.TAX_AMOUNT</Trans>}
                            value={formik.values.taxAmount}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.taxAmount && Boolean(formik.errors.taxAmount)}
                            helperText={formik.touched.taxAmount && formik.errors.taxAmount}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>
            <DialogActions>
                <Button color="error" onClick={props.onClose}><Trans>COMMON.CANCEL</Trans></Button>
                <Button type="submit"><Trans>COMMON.SAVE</Trans></Button>
            </DialogActions>
        </DialogContent>
    </Dialog>
}

export default InvoiceItemDetail;