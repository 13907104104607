import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AnalysGrid from "./AnalysGrid";
import {TransitionProps} from "@mui/material/transitions";
import {Slide} from "@mui/material";
import {useRatiosDetail} from "../hook/UseMonitoring";
import dayjs from "dayjs";
import SuspenseComponent from "../../Layout/SuspenseComponent";
import Placeholder from "../../SkinnableComponents/Placeholder/Placehoder";
import {Trans, useTranslation} from "react-i18next";
import {GridCellParams} from "@mui/x-data-grid";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface ModalState {
    data: GridCellParams;
    companyIds: number;
    open: boolean;
    onClose: () => void;
}

const DialogDetailGrid = (props: ModalState) => {
    const {t} = useTranslation();
    const summaryHook = useRatiosDetail(t);

    React.useEffect(() => {
        if (props.data?.field) {
            summaryHook.get(props.companyIds, dayjs(props.data.field).format('YYYY-MM-DD'));
        }
    }, [props.companyIds, props.data?.field]);

    return (
        <Dialog
            onClose={props.onClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}
            TransitionComponent={Transition}
            fullScreen
        >
            <DialogTitle sx={{m: 0, p: 2}} id="customized-dialog-title">
                <Trans>DASHBOARD_MONITORING.DASHBOARD_RATES.DETAILGRID.TITLE</Trans>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={props.onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>
            <DialogContent dividers>
                {Boolean(summaryHook.monitoringColumns)
                    ? <SuspenseComponent isLoading={!summaryHook.monitoringColumns && !summaryHook.isLoading}>
                        <AnalysGrid
                            data={summaryHook.customersMonitoring}
                            columns={summaryHook.monitoringColumns}
                            rows={summaryHook.monitoringRows}
                        ></AnalysGrid>
                    </SuspenseComponent>
                    : <Placeholder></Placeholder>
                }
            </DialogContent>
        </Dialog>
    );
}

export default DialogDetailGrid;