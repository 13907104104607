import React from "react";
import Typography from "@mui/material/Typography";
import {
  Batch,
  BatchParam,
  BatchParamsValue,
} from "../core/ServiceConfiguration";
import {Box, Checkbox, Grid, TextField} from "@mui/material";

interface ServiceBatchConfigurationProps {
  batches: Batch[];
  batchParamsValue: BatchParamsValue[];
  onChange: (p: BatchParamsValue[]) => void;
}

function ServiceBatchConfigurationComponent(
  props: ServiceBatchConfigurationProps
) {
  const [values, setValues] = React.useState<BatchParamsValue[]>([]);

  React.useEffect(() => {
    if (props.batches && props.batchParamsValue) {
      const v: BatchParamsValue[] = [];
      props.batches.forEach(b => {
        b.batchParamList.forEach(p => {
          const val = props.batchParamsValue.find(value => value.batchId === b.batchId && value.paramName === p.paramName);
          v.push(new BatchParamsValue(b.batchId, p.paramName, val ? val.paramValue : p.defaultValue))
        })
      });

      setValues(v);
      props.onChange(v);
    }
  }, [props.batches, props.batchParamsValue]);

  const getValue = (batchId: number, paramName: string) => {
    const v = values?.find(
      (p) => p.batchId === batchId && p.paramName === paramName
    );

    return v ? v.paramValue : null;
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    batchId: number,
    paramName: string
  ) => {
    const newValue =
      event.target.type === "checkbox"
        ? (event.target as HTMLInputElement).checked.toString()
        : event.target.value;

    const updatedValues = values;

    const item = updatedValues.find((p) => p.batchId === batchId && p.paramName === paramName);

    if (item) {
      item.paramValue = newValue;
    }

    setValues([...updatedValues]);
    props.onChange(updatedValues);
  };

  const renderInputNumber = (b: Batch,p: BatchParam) => {
    return (
      <>
        <TextField
            type="number"
            value={+getValue(b.batchId, p.paramName)}
            onChange={(e) => handleChange(e, b.batchId, p.paramName)}
            //label={p.paramDescription}
            InputLabelProps={{ shrink: true }}
        />
      </>
    );
  };

  const renderInputString = (b: Batch, p: BatchParam) => {
    return (
      <>
        <TextField
          type="text"
          value={getValue(b.batchId, p.paramName)}
          onChange={(e) => handleChange(e, b.batchId, p.paramName)}
          //label={p.paramDescription}
          InputLabelProps={{ shrink: true }}
        />
      </>
    );
  };

  const renderInputBoolean = (b: Batch,p: BatchParam) => {
    return <Checkbox
            defaultChecked={false}
            defaultValue={p.defaultValue}
            checked={
              (getValue(b.batchId, p.paramName)) === "true"
            }
            onChange={(e) => handleChange(e, b.batchId, p.paramName)}
          />
  }

  const renderFormComponent = (b: Batch, p: BatchParam) => {
    switch (p.valueType) {
      case "number":
        return renderInputNumber(b, p);
      case "string":
        return renderInputString(b, p);
      case "boolean":
        return renderInputBoolean(b, p);
      default:
        return renderInputString(b, p);
    }
  };

  return (
    <>
      <Typography variant="h6">Configurazione Parametri</Typography>
      {props.batches
          .filter(b => b.batchParamList.length)
          .map((batch: Batch) => (
        <Box
          key={batch.batchId}
          display="flex"
          flexDirection="column"
          gap=".5rem"
        >
          <Typography fontWeight="bold">{batch.batchDescription}</Typography>
          {batch.batchParamList.map((param) => (
            <Grid container key={param.paramName} columns={2}>
              <Grid item xs={1}>
              <Typography>{param.paramDescription}</Typography>
              </Grid>
              <Grid item xs={1}>
              {renderFormComponent(batch, param)}
              </Grid>
            </Grid>
          ))}
        </Box>
      ))}
    </>
  );
}

export default ServiceBatchConfigurationComponent;
