import React from "react";
import {Autocomplete, TextField} from "@mui/material";
import {Trans} from "react-i18next";
import {SERVICE_FREQUENCY} from "../core/ServiceConfiguration";

const manualItem = {value: SERVICE_FREQUENCY.MANUAL, label: "Manuale"};
const frequencyItems = [
    {value: SERVICE_FREQUENCY.DAILY, label: "Giornaliero"},
    {value: SERVICE_FREQUENCY.WEEKLY, label: "Settimanale"},
    {value: SERVICE_FREQUENCY.MONTHLY, label: "Mensile"},
    manualItem,
];

interface FrequencyConfigurationProps {
    frequency: SERVICE_FREQUENCY;
    onChange: (frequency: SERVICE_FREQUENCY) => void;
}

function FrequencyConfigurationComponent(props: FrequencyConfigurationProps) {
    const [value, setValue] = React.useState<{value: SERVICE_FREQUENCY, label: string}>(manualItem);

    React.useEffect(() => {
        const item = frequencyItems.find(i => i.value === props.frequency);
        setValue(item ? item : manualItem);
    }, [props.frequency])

    const handleChange = (val: {value: SERVICE_FREQUENCY, label: string}) => {
        setValue(val);
        props.onChange(val.value);
    }

    return <Autocomplete
            fullWidth
            id="frequence"
            value={value}
            onChange={(_, _value) => handleChange(_value)}
            renderInput={(params) => <TextField
                {...params}
                label={<Trans>SERVICE_CONFIG.FIELDS.FREQUENCY</Trans>}
                InputLabelProps={{ shrink: true }}
                variant="standard"
            />}
            options={frequencyItems}
            getOptionLabel={c => `${c.label}`}
            isOptionEqualToValue={(option, value) => option.value === value.value}
        />
}

export default FrequencyConfigurationComponent;