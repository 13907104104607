import React from "react";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogContent,
    DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField
} from "@mui/material";
import {useFormik} from "formik";
import {Trans, useTranslation} from "react-i18next";
import {BusinessDriverT, Rate, RateT, ThresholdsT} from "../../../model/Customer";
import {useRate, useService, useServiceConfigurations} from "../hook/useServices";
import {AxiosResponse} from "axios";
import {ModalComponentProps} from "../core/ModalComponentProps";
import {
    DataGridPro,
    GridToolbarContainer
} from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import {random} from '@mui/x-data-grid-generator';
import {Service} from "../core/Service";
import {GridColDef} from "@mui/x-data-grid";
import {CustomerTypes} from "../../Customers/core/DataSources";
import {RateValidation} from "../core/validation/RateValidation";
import {ServiceConfiguration} from "../../ServiceConfiguration/core/ServiceConfiguration";

interface EditToolbarProps {
    setRows: (newRows: ThresholdsT[]) => void;
}

function EditToolbar(props: EditToolbarProps) {
    const { setRows } = props;

    const handleClick = () => {
        const t = new ThresholdsT();
        t.thresholdId = random(-9999, -1);
        setRows([t] );
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Add record
            </Button>
        </GridToolbarContainer>
    );
}

function RateEditModal(props: ModalComponentProps<RateT>) {
    const {t} = useTranslation();

    const mapService = React.useCallback((res:AxiosResponse<Service>): Service => {
        return res.data;
    }, []);


    const serviceConfigHook = useServiceConfigurations(props.serviceId);
    const serviceHook = useService(props.serviceId, mapService);

    const thresholdColumns: GridColDef<ThresholdsT>[] = [
        {
            field: 'businessDriver.businessDriverId',
            headerName: t("SERVICE_DETAILS.FIELDS.RATES.THRESHOLD.BUSINESS_DRIVER"),
            editable: true,
            type: 'singleSelect',
            flex: 1,
            valueOptions: serviceHook.service?.businessDrivers,
            valueGetter: (params) => {
                return params.row.businessDriver?.businessDriverId
            },
            valueSetter: (params) => {
                params.row.businessDriver = {
                    ...params.row.businessDriver,
                    businessDriverId: params.value
                }
                return params.row;
            },
            getOptionLabel: (value: BusinessDriverT) => value?.description,
            getOptionValue: (value: BusinessDriverT) => value?.businessDriverId
        },
        { field: 'value', headerName: t("SERVICE_DETAILS.FIELDS.RATES.THRESHOLD.VALUE"), editable: true, type: 'number'},
        { field: 'unitCost', headerName: t("SERVICE_DETAILS.FIELDS.RATES.THRESHOLD.UNIT"), editable: true, type: 'number'},
        { field: 'flatCost', headerName: t("SERVICE_DETAILS.FIELDS.RATES.THRESHOLD.FLAT"), editable: true, type: 'number'}
    ]

    const mapToRateT = React.useCallback((res: AxiosResponse<Rate>, r : ServiceConfiguration): RateT => {
        return {
            ...res.data,
            businessDrivers: Array.from(new Set( res.data.thresholds.map(obj => obj.businessDriver?.businessDriverId))).map(b => ({
                businessDriverId: b,
                thresholds: res.data.thresholds.filter(t => t.businessDriver?.businessDriverId === b) || []
            })),
            serviceConfig: r
        }
    }, []);

    const rateHook = useRate(props.rateId, mapToRateT);

    const formik = useFormik({
        initialValues: rateHook.rate,
        enableReinitialize: true,
        validationSchema: RateValidation(t),
        onSubmit: (values: RateT) => {
            const v = {
                ...values,
                thresholds: values.thresholds.map(t => ({
                    ...t,
                    thresholdId: t.thresholdId < 0 ? null : t.thresholdId
                }))
            };

            rateHook.saveRate({
                    ...props.data,
                    ...v,
                    rateId: props.rateId
                },
                values.rateId,
                (res) => {
                    if (values.serviceConfig?.serviceConfigId) {
                        rateHook.assignConfig(res.data.rateId, values.serviceConfig.serviceConfigId).then(_ => {
                            handleClose();
                            return mapToRateT(res, values.serviceConfig);
                        });
                    }

                   handleClose();
                   return mapToRateT(res, values.serviceConfig);
                });



        },
    });

    const setRows = (rows: ThresholdsT[]) => {
        formik.setValues(prevState => ({
            ...prevState,
            thresholds: [ ...formik.values.thresholds, ...rows ]
        }))
    }

    const onUpdateCell = (updatedRow: ThresholdsT, originalRow: ThresholdsT) => {
        const data = [...formik.values.thresholds];
        const idx = data.findIndex(x => x.thresholdId === originalRow.thresholdId);
        data[idx] = updatedRow;
        formik.setValues(prevState => ({
            ...prevState,
            thresholds: data
        }))
        return updatedRow;
    }

    const handleClose = () => {
        formik.resetForm();
        props.onClose();
    }

    return  <Dialog
        open={props.open}
        fullScreen
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{
            component: 'form',
            onSubmit: formik.handleSubmit
        }}
    >
        <DialogTitle className="section-header">
            <Trans>SERVICE_DETAILS.RATE_MODAL.TITLE</Trans>
            <div>
                <Button color="error" tabIndex={-1} onClick={handleClose}><Trans>COMMON.CANCEL</Trans></Button>
                <Button type="button" tabIndex={-1} onClick={formik.submitForm}><Trans>COMMON.SAVE</Trans></Button>
            </div>
        </DialogTitle>
        <DialogContent>
                <Grid container columns={1} spacing={2}>
                    <Grid item xs={12} >
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    id="name"
                                    name="name"
                                    label={<Trans>SERVICE_DETAILS.FIELDS.RATES.NAME</Trans>}
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    id="description"
                                    name="description"
                                    label={<Trans>SERVICE_DETAILS.FIELDS.RATES.DESCRIPTION</Trans>}
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.description && Boolean(formik.errors.description)}
                                    helperText={formik.touched.description && formik.errors.description}
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="customerTypeLabel" error={Boolean(formik.errors.customerType)}>
                                        <Trans>SERVICE_DETAILS.FIELDS.RATES.CUSTOMER_TYPE</Trans>
                                    </InputLabel>
                                    <Select
                                        labelId="customerTypeLabel"
                                        id="customerType"
                                        value={formik.values.customerType || ''}
                                        name="customerType"
                                        onChange={formik.handleChange}
                                        label={<Trans>SERVICE_DETAILS.FIELDS.RATES.CUSTOMER_TYPE</Trans>}
                                    >
                                        {CustomerTypes.map(tp => <MenuItem key={tp} value={tp}><Trans>CUSTOMER_TYPES.{tp}</Trans></MenuItem>)}
                                    </Select>
                                    <FormHelperText error>{formik.errors.customerType}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    variant="standard"
                                    id="billingFrequency"
                                    name="billingFrequency"
                                    label={<Trans>SERVICE_DETAILS.FIELDS.RATES.BILLING_FREQUENCY</Trans>}
                                    value={formik.values.billingFrequency}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.billingFrequency && Boolean(formik.errors.billingFrequency)}
                                    helperText={formik.touched.billingFrequency && formik.errors.billingFrequency}
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    id="rateFrequency"
                                    name="rateFrequency"
                                    type="number"
                                    label={<Trans>SERVICE_DETAILS.FIELDS.RATES.RATE_FREQUENCY</Trans>}
                                    value={formik.values.rateFrequency}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.rateFrequency && Boolean(formik.errors.rateFrequency)}
                                    helperText={formik.touched.rateFrequency && formik.errors.rateFrequency}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl variant="standard" fullWidth>
                                    <Autocomplete
                                        id="serviceConfig"
                                        fullWidth
                                        value={formik.values.serviceConfig}
                                        onChange={(_, value) => formik.setFieldValue('serviceConfig', value)}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            label={
                                                <Trans>SERVICE_DETAILS.FIELDS.RATES.SERVICE_CONFIGURATION</Trans>}
                                            InputLabelProps={{shrink: true}}
                                            variant="standard"/>}
                                        options={serviceConfigHook.serviceConfigurations}
                                        getOptionLabel={c => `${c?.serviceConfigName || ''}`}
                                       // isOptionEqualToValue={(option, value) => option.serviceConfigId === value.serviceConfigId}
                                    />
                                    <FormHelperText error>{formik.errors.serviceConfigId}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    type="number"
                                    id="deltaFrequency"
                                    name="deltaFrequency"
                                    label={<Trans>SERVICE_DETAILS.FIELDS.RATES.DELTA_FREQUENCY</Trans>}
                                    value={formik.values.deltaFrequency}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.deltaFrequency && Boolean(formik.errors.deltaFrequency)}
                                    helperText={formik.touched.deltaFrequency && formik.errors.deltaFrequency}
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    type="number"
                                    id="trialLength"
                                    name="trialLength"
                                    label={<Trans>SERVICE_DETAILS.FIELDS.RATES.TRIAL_LENGTH</Trans>}
                                    value={formik.values.trialLength}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.trialLength && Boolean(formik.errors.trialLength)}
                                    helperText={formik.touched.trialLength && formik.errors.trialLength}
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    type="date"
                                    id="expirationDate"
                                    name="expirationDate"
                                    label={<Trans>SERVICE_DETAILS.FIELDS.RATES.EXPIRATION</Trans>}
                                    value={formik.values.expirationDate}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.expirationDate && Boolean(formik.errors.expirationDate)}
                                    helperText={formik.touched.expirationDate && formik.errors.expirationDate}
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item flex={1} xs={8} sx={{display: 'grid'}}>
                        {rateHook.rate?.thresholds &&
                            <>
                            <FormHelperText error>{formik.errors.thresholds?.toString()}</FormHelperText>
                            <DataGridPro
                                //  rowModesModel={rowModes}
                                columns={thresholdColumns}
                                rows={formik.values.thresholds}
                                getRowId={(row: ThresholdsT) => row.thresholdId}
                                slots={{
                                    toolbar: EditToolbar,
                                }}
                                slotProps={{
                                    toolbar: {setRows},
                                }}
                                processRowUpdate={onUpdateCell}
                                onProcessRowUpdateError={console.log}
                            />

                            </>
                        }
                    </Grid>
                </Grid>


            </DialogContent>
    </Dialog>
}

export default RateEditModal;