import React from 'react';
import ServiceConfigurationComponent from "../components/ServiceConfigurationComponent";
import {useParams} from "react-router-dom";
import {useServiceConfiguration} from "../hook/useServices";
import SuspenseComponent from "../../Layout/SuspenseComponent";

function ServiceConfigurationDetailView() {
    let { serviceConfigId } = useParams();
    let { serviceId } = useParams();
    const serviceConfigurationHook = useServiceConfiguration();

    React.useEffect(() => {
        if (serviceConfigId) {
            serviceConfigurationHook.get(+serviceConfigId).then();
        }
    }, [serviceConfigId])

    return <>
        <SuspenseComponent isLoading={!serviceConfigurationHook.serviceConfiguration}>
            <ServiceConfigurationComponent serviceId={serviceId ? +serviceId : null}
                                           serviceConfigurationHook={serviceConfigurationHook} />
        </SuspenseComponent>
</>
}

export default ServiceConfigurationDetailView;