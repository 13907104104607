import {DataGridPro} from "@mui/x-data-grid-pro";
import {Service} from "../../Services/core/Service";
import {useServices} from "../hook/useServices";
import {GridActionsCellItem, GridColDef, GridRowParams} from "@mui/x-data-grid";
import {Tooltip} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import {ROUTES} from "../../../routes/ROUTES";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PageTitle from "../../SkinnableComponents/PageTitle/PageTitle";
import SuspenseComponent from "../../Layout/SuspenseComponent";

function SlaConfigurationListView() {
    const serviceHook = useServices();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const columns: GridColDef[] = [
        {
            field: 'details', headerName: '', type: 'actions',
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem label="Dettagli" icon={<Tooltip title={t('COMMON.DETAILS').toString()}><InfoIcon /></Tooltip>}
                                     onClick={() => navigate(ROUTES.SLA_CONFIG + params.row.serviceId)}
                />
            ]
        },
        { field: 'name', headerName: t("SERVICE_LIST.FIELDS.NAME") },
        { field: 'description', headerName: t("SERVICE_LIST.FIELDS.DESCRIPTION"), flex: 1 },

    ]

    return <SuspenseComponent isLoading={serviceHook.isLoading}>
        <PageTitle title="SLA_CONFIG_LIST.TITLE"></PageTitle>
        <DataGridPro getRowId={(row: Service) => row.serviceId} rows={serviceHook.services} columns={columns} hideFooter />
    </SuspenseComponent>
}

export default SlaConfigurationListView;