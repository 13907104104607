import {AxiosResponse} from "axios";
import React from "react";
import {useAxios} from "../../../core/axiosHook";
import {Invoice, InvoiceDetails, InvoiceItem} from "../core/Invoice";
import {GenerateInvoiceRequestT} from "../core/GenerateInvoiceRequestT";

export function useInvoices() {
    const [invoices, setInvoices] = React.useState<Invoice[] | InvoiceDetails []>([]);
    const {isLoading, request} = useAxios();

    const get = (status: string, callback: (res:AxiosResponse<Invoice[]>) => Invoice[] | InvoiceDetails []) => {
       return request({ method: "GET", url:`${process.env.REACT_APP_BE_INGRESS}/invoices?invoiceStatus=${status}` }).then(res => {
            setInvoices(callback(res));
       });
    }

    const approve = (invoiceIdList: number[]) => {
        return request({ method: "POST", url:`${process.env.REACT_APP_BE_INGRESS}/invoices/approve`, data: { invoiceIdList }}).then();
    }

    const download = (invoiceIdList: number[]) => {
        return request({ method: "POST", url:`${process.env.REACT_APP_BE_INGRESS}/invoices/download`, data: { invoiceIdList }, responseType: 'blob'}).then(res => {
            handleDownload(res)
            return res;
        });
    }

    return {invoices, get, approve, download, isLoading};
}

export interface InvoiceHookT {
    invoice, isLoading, save, get
}

export function useInvoice(invoiceId: number, callback: (res:AxiosResponse<Invoice>) => Invoice) : InvoiceHookT {
    const [invoice, setInvoice] = React.useState<Invoice>(new Invoice());
    const {isLoading, request} = useAxios();

    React.useEffect(() => {
        get();
    }, [invoiceId]);

    const get = () => {
        request({ method: "GET", url:`${process.env.REACT_APP_BE_INGRESS}/invoices/${invoiceId}` }).then(res => {
            setInvoice(callback(res));
        });
    }

    const save = (item: InvoiceItem) => {
        const _invoice = {...invoice};
        item.lineNumber = invoice.invoiceItems.length;
        _invoice.invoiceItems.push(item);
        request({ method: "PUT", url:`${process.env.REACT_APP_BE_INGRESS}/invoices/${invoiceId}`, data: _invoice }).then(() => {
            get();
        });
    }

    return {invoice, isLoading, get, save};
}


export enum DOWNLOAD_FORMAT {
    XML = 'xml',
    PDF = 'pdf'
}
export function useDownload() {
    const {isLoading, request} = useAxios();

    const download = (invoiceId: number, format: DOWNLOAD_FORMAT) => {
        return request({ method: "GET", url:`${process.env.REACT_APP_BE_INGRESS}/invoices/${invoiceId}/${format}`, responseType: 'blob' }).then(res => {
            handleDownload(res);
            return res;
        });
    }

    return {download, isLoading};
}

export const handleDownload = (res: AxiosResponse): void => {
    const a = document.createElement('a');
    const url = window.URL.createObjectURL(res.data);
    a.href = url;
    a.target = '_blank';
    //a.download = extract_filename(res);

    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
}

/*function extract_filename(res: AxiosResponse) {
    let contentDisposition: string = res.headers['content-disposition'];
    if (contentDisposition) {
        return contentDisposition.split('filename=')[1].split(';')[0];
    }
    return null;
}*/

export const useGenerateInvoice = () => {
    const {isLoading, request} = useAxios();

    const generate = (values: GenerateInvoiceRequestT) => {
        return request({ method: "POST", url:`${process.env.REACT_APP_BE_INGRESS}/invoices/generate`, data: values })
    }
    return {generate, isLoading};
}
