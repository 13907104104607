import dayjs from "dayjs";

export const dateFormatter = (value: string) => {
    return value ? dayjs(value).format('DD/MM/YYYY') : '-';
}

export const percentageFormatter = (value: number) => {
    return value ? `${value} %` : '-';
}

const _currencyFormatter =  new Intl.NumberFormat('it-IT', { currency: 'EUR', style: 'currency'})

export const currencyFormatter = (value: number) => {
    return _currencyFormatter.format(value);
}