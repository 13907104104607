import {OktaAuth} from '@okta/okta-auth-js';
import {getRedirectUrl, ROUTES} from "../../routes/ROUTES";

const OKTA_DOMAIN = process.env.REACT_APP_OKTA_DOMAIN;
const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const OIDC_CLIENT = process.env.REACT_APP_OKTA_OIDC_CLIENT;
const ISSUER = `https://${OKTA_DOMAIN}/oauth2/default`;
const HOST = window.location.host;
const SCOPES = 'openid profile email offline_access';
const PROTOCOL = window.location.protocol;
const CALLBACK_PATH = ROUTES.OKTA_CALLBACK;
const REDIRECT_URI = `${PROTOCOL}//${HOST}${CALLBACK_PATH}`;

export const oktaCustomConfig = {
    issuer: ISSUER,
    clientId: CLIENT_ID,
    redirectUri: REDIRECT_URI,
    scopes: SCOPES.split(/\s+/),
    postLogoutRedirectUri: window.location.origin,
    tokenManager: {
      autoRenew: true
    },
    oidcClient: OIDC_CLIENT
  };

  let _oktaAuth: OktaAuth;
  export const oktaAuth = () => {
          if (!_oktaAuth) {
              _oktaAuth = new OktaAuth(oktaCustomConfig);
          }
          return _oktaAuth;
  }


  export const oktaLogout = async(redirectUrl: string) => {
      await oktaAuth().signOut().then();
      localStorage.removeItem("okta-token-storage");
      window.location.href = ROUTES.OKTA_LOGIN + getRedirectUrl(redirectUrl);
  }
