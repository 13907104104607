import React from "react";
import {useAxios} from "../../../core/axiosHook";
import {Subscription} from "../../../model/Customer";

export function useCustomerSubs() {
    const [subs, setSubs] = React.useState<Subscription[]>([]);
    const {isLoading, request} = useAxios();

    const get = (customerId, serviceId) => {
        if (customerId && serviceId && serviceId !== -1) {
            request({
                method: "GET",
                url: `${process.env.REACT_APP_BE_INGRESS}/subscriptions?customerId=${customerId}&serviceId=${serviceId}`
            }).then(res => {
                setSubs(res.data);
            });
        } else {
            setSubs([]);
        }
    }

    const subscribe = (s: Subscription) => {
        return request({
            method: s.subscriptionId ? "PUT" : "POST",
            url:`${process.env.REACT_APP_BE_INGRESS}/subscriptions` + (s.subscriptionId ? `/${s.subscriptionId}` : ''),
            data: s
        })
    }

    return {subs, isLoading, get, subscribe};
}
