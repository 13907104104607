import {GridColDef} from "@mui/x-data-grid";
import {InvoiceItem} from "../core/Invoice";
import {useTranslation} from "react-i18next";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {currencyFormatter} from "../../../core/utils";

interface InvoicesItemsGridProps {
    invoiceItems: InvoiceItem[]
}

function InvoicesItemsGrid(props: InvoicesItemsGridProps) {
    const {t} = useTranslation();

    const columns: GridColDef<InvoiceItem>[] = [
        /*{
            field: 'details', headerName: '', type: 'actions',
            getActions: (params: GridRowParams<InvoiceItem>) => [
                <GridActionsCellItem label="Dettagli" icon={<Tooltip title={t('COMMON.DETAILS').toString()}><InfoIcon /></Tooltip>}
                                     onClick={() => console.log(params.row.invoiceItemId) }
                />
            ]
        },*/
        { field: 'productCode', headerName: t("INVOICE_DETAIL.FIELDS.PRODUCT_CODE")  },
        { field: 'description', headerName: t("INVOICE_DETAIL.FIELDS.DESCRIPTION"), flex: 1  },
        { field: 'startPeriod', headerName: t("INVOICES_LIST.FIELDS.START_PERIOD")},
        { field: 'endPeriod', headerName: t("INVOICES_LIST.FIELDS.END_PERIOD")},
        { field: 'quantity', headerName: t("INVOICE_DETAIL.FIELDS.QUANTITY")},
        { field: 'unit', headerName: t("INVOICE_DETAIL.FIELDS.UNIT") },
        { field: 'itemPrice', headerName: t("INVOICE_DETAIL.FIELDS.ITEM_PRICE"), valueGetter: ({value}) => currencyFormatter(value)},
        { field: 'taxAmount', headerName: t("INVOICE_DETAIL.FIELDS.TAX_AMOUNT"), valueGetter: ({value}) => currencyFormatter(value)},
        { field: 'taxableAmount', headerName: t("INVOICE_DETAIL.FIELDS.TAXABLE_AMOUNT"), valueGetter: ({value}) => currencyFormatter(value)},
    ];

    return (props.invoiceItems && props.invoiceItems.length)
            && <DataGridPro
                getRowId={(row: InvoiceItem) => row.invoiceItemId}
                rows={props.invoiceItems}
                columns={columns}
                hideFooter
                />


}

export default InvoicesItemsGrid;