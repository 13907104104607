import React from "react";
import axiosInstance from "./axiosInstance";
import {AxiosRequestConfig, AxiosResponse} from "axios";



const req = (config) => axiosInstance.request(config);

export function useAxios() {
    const [isLoading, setIsLoading] = React.useState(true);
    const [reload, setReload] = React.useState(false);

    const _request = (config: AxiosRequestConfig, callback: (res: AxiosResponse<any, any>) => any = null) => {
            setIsLoading(true);
            return req(config).then(res => {
                callback && callback(res);
                return res;
            }).finally(() => {
                setTimeout(_ => {
                    setIsLoading(false)
                    setReload(false);
                }, 100);
            })
    }

    const request = React.useCallback(_request, []);

    return {isLoading, request, setIsLoading, reload, setReload};
}
