import {styled, useTheme} from "@mui/material/styles";
import React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar/AppBar";
import MuiAppBar from "@mui/material/AppBar";
import {Trans} from "react-i18next";
import './Layout.scss';
import Sidebar from "./Sidebar";
import {Navigate, Route, Routes} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material";
import {itIT} from "@mui/x-data-grid";
import DrawerHeader from "./DrawerHeader";
import DashboardView from '../Dashboard/DashboardView'
import CustomersHoc from "../Customers/views/CustomersHoc";
import CustomerDetail from "../Customers/views/CustomerDetail";
import ServicesView from "../Services/views/ServicesView";
import ServiceDetail from "../Services/views/ServiceDetail";
import GroupsView from "../Customers/views/GroupsView";
import VendorDetail from "../Customers/views/VendorDetail";
import InvoicesView from "../Invoices/views/InvoicesView";
import InvoiceDetail from "../Invoices/views/InvoiceDetail";
import InvoicesDetailsView from "../Invoices/views/InvoicesDetailsView";
import MonitoringHoc from "../Monitoring/views/MonitoringHoc";
import SummaryMonitoringHoc from "../Monitoring/views/SummaryMonitoringHoc";
import ServiceConfigurationListView from "../ServiceConfiguration/views/ServiceConfigurationListView";
import ServiceConfigurationDetailView from "../ServiceConfiguration/views/ServiceConfigurationDetailView";
import SlaConfigurationListView from "../ServiceConfiguration/views/SlaConfigurationListView";
import SlaConfigurationDetailView from "../ServiceConfiguration/views/SlaConfigurationDetailView";

const drawerWidth = 275;
const appTitle = <Trans>TITLE</Trans>;


interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {shouldForwardProp: (prop) => prop !== 'open',})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export default function Layout() {
    const theme = useTheme();
    const appTheme =  createTheme(
        {},
        itIT
    );
    const [open, setOpen] = React.useState(false);


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const TopBar = () => <AppBar position="fixed" open={open}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                        marginRight: 5,
                        ...(open && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div">
                    {appTitle}
                </Typography>
            </Toolbar>
        </AppBar>

    return (
    <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <TopBar></TopBar>
        <Sidebar theme={theme} open={open} handleDrawerClose={handleDrawerClose}></Sidebar>
        <ThemeProvider theme={appTheme}>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader />
            <Routes>
                        <Route path="/" element={<Navigate to="dashboard"/>}/>
                        <Route path="dashboard" element={<DashboardView/>}/>

                        <Route path="customers">
                            <Route path="prospect" element={<CustomersHoc mode="prospect"/>} />
                            <Route path="active" element={<CustomersHoc  mode="active"/>} />
                            <Route path="history" element={<CustomersHoc  mode="history"/>} />
                            <Route path="pending" element={<CustomersHoc  mode="pending"/>} />
                            <Route path="vendors" element={<CustomersHoc mode="active" customerType="V"/>} />
                            <Route path="vendors/:customerId" element={<VendorDetail/>} />
                            <Route path="add" element={<CustomerDetail></CustomerDetail>}/>
                            <Route path="edit/:customerId" element={<CustomerDetail></CustomerDetail>}/>
                        </Route>

                        <Route path="groups" element={<GroupsView/>} />

                        <Route path="services" >
                            <Route path="list" element={<ServicesView />} />
                            <Route path=":serviceId" element={<ServiceDetail />}/>
                        </Route>
                        <Route path="prices" element={<DashboardView/>}/>
                        <Route path="invoices">
                            <Route path="pending" element={<InvoicesView mode="pending"/>}/>
                            <Route path="approved" element={<InvoicesView mode="approved"/>}/>
                            <Route path="sent" element={<InvoicesView mode="sent"/>}/>
                            <Route path="edit/:invoiceId" element={<InvoiceDetail/>}/>
                        </Route>

                        <Route path="monitoring">
                            <Route path="summary" element={<SummaryMonitoringHoc mode="dashboard_summary"/>}/>
                            <Route path="rates" element={<MonitoringHoc mode="dashboard_rates"/>}/>
                            <Route path="rates/:companyId" element={<MonitoringHoc mode="dashboard_rates"/>}/>
                        </Route>

                        <Route path="report">
                            <Route path="sent" element={<InvoicesDetailsView mode="sent" />}/>
                        </Route>

                        <Route path="configuration">
                            <Route path="services">
                                <Route path="list" element={<ServiceConfigurationListView />} />
                                <Route path="list/:serviceId" element={<ServiceConfigurationListView />} />
                                <Route path="edit/:serviceConfigId" element={<ServiceConfigurationDetailView />}/>
                                <Route path="add" element={<ServiceConfigurationDetailView />}/>
                                <Route path="add/:serviceId" element={<ServiceConfigurationDetailView />}/>
                            </Route>
                            <Route path="sla">
                                <Route path="list" element={<SlaConfigurationListView />}  />
                                <Route path="details/:serviceId" element={<SlaConfigurationDetailView />}/>
                            </Route>
                        </Route>

                        <Route path="*" element={<Navigate to="/login" replace />}/>
                    </Routes>
            </Box>
        </ThemeProvider>
    </Box>
);
}