import React from 'react';
import {StatCard} from "./components/StatCard";
import {useCustomers} from "../Customers/hook/useCustomer";
import {CustomerStatusesMap} from "../Customers/core/DataSources";
import {useGroups} from "../Customers/hook/useGroups";
import {CustomerType} from "../../model/Customer";
import "./DashboardView.scss";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../routes/ROUTES";
import {Trans} from "react-i18next";
import Typography from "@mui/material/Typography";
import {CompactStatCard} from "./components/CompactStatCard";

function DashboardView() {
    const [stat, setStat] = React.useState({ prospect: 0, active: 0, history: 0, vendors: 0, pending: 0})
    const customerHook = useCustomers('', res => res.data);
    const groupsHook = useGroups();

    const navigate = useNavigate();

    React.useEffect(() => {
        const active = customerHook.customers.filter(x => CustomerStatusesMap.active.indexOf(x.customerStatus) !== -1).length;
        const prospect = customerHook.customers.filter(x => CustomerStatusesMap.prospect.indexOf(x.customerStatus) !== -1).length;
        const history = customerHook.customers.filter(x => CustomerStatusesMap.history.indexOf(x.customerStatus) !== -1).length;
        const vendors = customerHook.customers.filter(x => x.customerType === CustomerType.VENDOR).length;
        const pending = customerHook.customers.filter(x => CustomerStatusesMap.pending.indexOf(x.customerStatus) !== -1).length;

        setStat({
            active,
            prospect,
            vendors,
            history,
            pending
        })
    },[customerHook.customers]);

    const handleClick =(route: string) => {
        navigate(route)
    }

    return <div className="dashboard-container">
        <div className="dashboard-row">
            <StatCard icon={"work_history"} value={stat.prospect} isLoading={customerHook.isLoading}
                      id="prospect"
                      onClick={() => handleClick(ROUTES.PROSPECT)}
                      description={"Clienti Prospect"}
                      gradient={"linear-gradient(34deg, hsla(225, 89%, 47%, 1) 0%, hsla(192, 95%, 50%, 1) 100%)"}  />
            <StatCard icon={"business"} value={stat.active} isLoading={customerHook.isLoading}
                      id="active"
                      onClick={() => handleClick(ROUTES.ACTIVE)}
                      description={"Clienti Effettivi"}
                      gradient={"linear-gradient(34deg, hsla(165, 89%, 31%, 1) 0%, hsla(161, 46%, 49%, 1) 100%)"} />
            <StatCard icon={"history"} value={stat.history} isLoading={customerHook.isLoading}
                      id="history"
                      onClick={() => handleClick(ROUTES.HISTORY)}
                      description={"Clienti Disattivati"}
                      gradient={"linear-gradient(34deg, hsla(333, 100%, 53%, 1) 0%, hsla(33, 94%, 57%, 1) 100%)"} />

        </div>

        <div className="dashboard-row">
            <StatCard icon={"pause_circle_outline"} value={stat.pending} isLoading={customerHook.isLoading}
                      id="pending"
                      onClick={() => handleClick(ROUTES.PENDING)}
                      description={"Clienti Sospesi"}
                      gradient={"linear-gradient(34deg, #FF6A88 0%, #FF9A8B 100%)"} />
            <StatCard icon={"storefront"} value={stat.vendors} isLoading={customerHook.isLoading && groupsHook.isLoading}
                      id="vendors"
                      description={"Distributori"}
                      onClick={() => handleClick(ROUTES.VENDORS)}
                      gradient={"linear-gradient(34deg, hsla(266, 75%, 55%, 1) 0%, hsla(340, 47%, 77%, 1) 100%)"}/>
            <StatCard icon={"workspaces"} value={groupsHook.groups.length} isLoading={customerHook.isLoading && groupsHook.isLoading}
                      id="groups"
                      onClick={() => handleClick(ROUTES.GROUPS)}
                      description={"Gruppi / Studi"}/>
        </div>

        <Typography variant="h6">
            <Trans>DASHBOARD.MONITORING</Trans>
        </Typography>

        <div className="dashboard-row">
            <StatCard icon={"insights"} isLoading={customerHook.isLoading && groupsHook.isLoading}
                      id="monitoring-ratios"
                      gradient={"linear-gradient(34deg, #6f86d6 0%, #48c6ef 100%)"}
                      onClick={() => handleClick(ROUTES.DASHBOARD_RATES)}
                      description={"Matching rates"}/>
            <StatCard icon={"summarize"} isLoading={customerHook.isLoading && groupsHook.isLoading}
                      id="monitoring-ratios"
                      gradient={" linear-gradient(34deg, hsla(213, 77%, 14%, 1) 0%, hsla(202, 27%, 45%, 1) 100%)"}
                      onClick={() => handleClick(ROUTES.DASHBOARD_SUMMARY)}
                      description={"Sintesi"}/>
        </div>

        <Typography variant="h6">
            <Trans>DASHBOARD.CONFIGURATION</Trans>
        </Typography>

        <div className="dashboard-row">
            <CompactStatCard icon={"view_module"} isLoading={false}
                      id="settings-service"
                      gradient={"linear-gradient(34deg, #d53369 0%, #daae51 100%)"}
                      onClick={() => handleClick(ROUTES.SERVICE_CONFIGURATION)}
                      description={"Servizi"}/>
            <CompactStatCard icon={"tune"} isLoading={false}
                      id="settings-sla"
                      gradient={" linear-gradient(34deg, #c33764 0%, #1d2671 100%)"}
                      onClick={() => handleClick(ROUTES.SLA_LIST)}
                      description={"SLA"}/>
        </div>
    </div>
}

export default DashboardView;