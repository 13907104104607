import React from "react";
import {DataSourceT} from "../core/types";
import {Autocomplete} from "@mui/material";
import TextField from "@mui/material/TextField";

const DAYS_COUNT = 31;
const generateDataSource = (): DataSourceT<number>[] => {
    const res = [];
    for (let i = 1; i <= DAYS_COUNT; i++) {
        res.push({value: i, label: i.toString()});
    }
    return res;
}

const MONTH_DAYS: DataSourceT<number>[] = generateDataSource();

interface MonthDaysProps {
    day: number;
    onChange: (val: string) => void;
}

function MonthDays(props: MonthDaysProps) {
    const extractItem = (day: number) => {
        return day ? MONTH_DAYS[day-1] : MONTH_DAYS[0];
    }

    const [selectedDay, setSelectedDay] = React.useState<DataSourceT<number>>(extractItem(props.day));

    React.useEffect(() => {
        setSelectedDay(extractItem(props.day));
    }, [props.day])

    return <>
        <Autocomplete
            id="month-day"
            disableClearable
            fullWidth
            options={MONTH_DAYS}
            getOptionLabel={(option: DataSourceT<number>) => option.label}
            onChange={(_, value: DataSourceT<number>) => {
                setSelectedDay(value);
                props.onChange(`0 0 ${value.value} * *`);
            }}
            value={selectedDay}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Giorno"
                    InputProps={{
                        ...params.InputProps,
                        type: 'search',
                    }}
                />
            )}
            clearOnEscape
        />
    </>
}

export default MonthDays;