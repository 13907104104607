import React from "react";
import {useAxios} from "../../../core/axiosHook";
import {Sla, SlaValue} from "../core/Sla";
import {AxiosResponse} from "axios";


export function useSla(serviceId: number, callback: (res:AxiosResponse<Sla[]>) => Sla[]) {
    const [slas, setSlas] = React.useState<Sla[]>([]);

    const {isLoading, request} = useAxios();

    React.useEffect(()  => {
        request({ method: "GET",
            url:`${process.env.REACT_APP_M1EXX2}/sla?serviceId=${serviceId}` })
            .then(res => setSlas(callback(res)));
    }, [serviceId]);

    return {slas, isLoading};
}


export function useSlaValues() {
    const [slaValues, setSlaValues] = React.useState<SlaValue[]>([]);

    const {isLoading, request} = useAxios();

    const get = (batchIds: number[]): Promise<SlaValue[]>  => {
        return request({ method: "GET",
            url:`${process.env.REACT_APP_M1EXX2}/sla/values?batchId=${batchIds.join(',')}` })
            .then(res => {
                setSlaValues(res.data);
                return res.data;
            } );
    }

    const save = (values: SlaValue)  => {
        return request({ method: "PUT",
            url:`${process.env.REACT_APP_M1EXX2}/sla/value`, data: values })
            .then(res => {
                return res;
            } );
    }

    return {slaValues, get, save, isLoading};
}