import React from "react";
import {useAxios} from "../../../core/axiosHook";
import {Fee} from "../../../model/Customer";

export function useFee() {
    const [fees, setFees] = React.useState<Fee[]>([]);
    const {isLoading, request} = useAxios();

    const _get = (vendorId: number) => {
        setFees([
            { vendorId, customerId: 1, serviceId: 2, serviceName: 'DAS01', year: 2024, value: 10 },
            { vendorId, customerId: 2, serviceId: 2, serviceName: 'DAS01', year: 2024, value: 10 },
            { vendorId, customerId: 3, serviceId: 2, serviceName: 'DAS01', year: 2024, value: 10 },
            { vendorId, customerId: 4, serviceId: 2, serviceName: 'DAS01', year: 2024, value: 20 },
            { vendorId, customerId: 1, serviceId: 2, serviceName: 'DAS01', year: 2025, value: 20 },
            { vendorId, customerId: 2, serviceId: 2, serviceName: 'DAS01', year: 2025, value: 20 },
            { vendorId, customerId: 3, serviceId: 2, serviceName: 'DAS01', year: 2025, value: 20 },
            { vendorId, customerId: 4, serviceId: 2, serviceName: 'DAS01', year: 2025, value: 20 },
            { vendorId, customerId: 1, serviceId: 3, serviceName: 'DAS02', year: 2024, value: 20 },
            { vendorId, customerId: 2, serviceId: 3, serviceName: 'DAS02', year: 2024, value: 15 },
            { vendorId, customerId: 3, serviceId: 3, serviceName: 'DAS02', year: 2024, value: 20 },
        ]);
    }

    const get = (vendorId: number) => {
        if (vendorId) {
            request({
                method: "GET",
                url: `${process.env.REACT_APP_BE_INGRESS}/fees?vendorId=${vendorId}`
            }).then(res => {
                setFees(res.data);
            });
        } else {
            setFees([]);
        }
    }

    return {fees, isLoading, get, _get};
}
