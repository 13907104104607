import {Service} from "../../Services/core/Service";
import React from "react";
import {useAxios} from "../../../core/axiosHook";

export function useServices() {
    const [services, setServices] = React.useState<Service[]>([]);
    const {isLoading, request} = useAxios();

    React.useEffect(() => {
        request({ method: "GET", url:`${process.env.REACT_APP_BE_INGRESS}/services` }).then(res => {
            setServices(res.data);
        });
    }, [request]);

    return {services, isLoading};
}