import React from "react";
import {useAxios} from "../../../core/axiosHook";
import {Contact} from "../../../model/Customer";
import {AxiosResponse} from "axios";

export function useContact(intialValue: Contact) {
    const [contact, setContact] = React.useState<Contact>(intialValue);
    const {isLoading, request} = useAxios();

    const saveContact = (values: Contact, isUpdate, callback: (res:AxiosResponse<Contact>) => Contact) => {
        request({ method: isUpdate ? "PUT" : "POST",
            url:`${process.env.REACT_APP_BE_INGRESS}/contacts` + (isUpdate ? `/${values.contactId}` : ''), data: values }).then(res => {
            setContact(callback(res));
        });
    }

    return {contact, isLoading, saveContact};
}
