import React from 'react';

function not<T>(a: readonly T[], b: readonly T[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection<T>(a: readonly T[], b: readonly T[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union<T>(a: readonly T[], b: readonly T[]) {
  return [...a, ...not(b, a)];
}

interface ListCompositionProps<T> {
  dataSource: T[];
  selected: T[];
}

export enum COMPOSITION_LIST {
  DATA_SOURCE,
  SELECTED,
}

export function useListComposition<T>() {
  const [checked, setChecked] = React.useState<readonly T[]>([]);
  const [dataSource, setDataSource] = React.useState<readonly T[]>([]);
  const [selected, setSelected] = React.useState<readonly T[]>([]);

  const [originalDataSource, setOriginalDataSource] = React.useState<
    readonly T[]
  >([]);
  const [originalSelected, setOriginalSelected] = React.useState<readonly T[]>(
    []
  );

  const leftChecked = intersection(checked, originalDataSource);
  const rightChecked = intersection(checked, originalSelected);

  const init = (props: ListCompositionProps<T>) => {
    setDataSource(props.dataSource);
    setSelected(props.selected);
    setOriginalSelected(props.selected);
    setOriginalDataSource(props.dataSource);
  };

  const handleToggle = (value: T) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: readonly T[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: readonly T[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    const _selected = selected.concat(leftChecked);
    const _selectedOriginal = originalSelected.concat(leftChecked);
    const _dataSource = not(dataSource, leftChecked);
    const _dataSourceOriginal = not(originalDataSource, leftChecked);
    setSelected(_selected);
    setDataSource(_dataSource);
    setOriginalSelected(_selectedOriginal);
    setOriginalDataSource(_dataSourceOriginal);
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    const _selected = not(selected, rightChecked);
    const _selectedOriginal = not(originalSelected, rightChecked);
    const _dataSource = dataSource.concat(rightChecked);
    const _dataSourceOriginal = originalDataSource.concat(rightChecked);
    setDataSource(_dataSource);
    setSelected(_selected);
    setChecked(not(checked, rightChecked));

    setOriginalSelected(_selectedOriginal);
    setOriginalDataSource(_dataSourceOriginal);
  };

  const handleFilter = (
    value: string,
    fields: string[],
    source: COMPOSITION_LIST
  ) => {
    if (source === COMPOSITION_LIST.DATA_SOURCE)
      setDataSource(_filterByFields(originalDataSource, fields, value));
    //setDataSource(originalDataSource.filter(v => value && value !== '' ? v[field]?.toUpperCase().indexOf(value.toUpperCase()) !== -1 : true))
    else setSelected(_filterByFields(originalSelected, fields, value));
    //setSelected(originalSelected.filter(v => value && value !== '' ? v[field]?.toUpperCase().indexOf(value.toUpperCase()) !== -1 : true))
  };

  const _checkByField = (item: T, field: string, value: string) => {
    return item[field]?.toUpperCase().indexOf(value.toUpperCase()) > -1;
  };

  const _filterByFields = (
    source: readonly T[],
    fields: string[],
    value: string
  ) => {
    return source.filter((v) =>
      value && value !== ''
        ? fields.some((f) => _checkByField(v, f, value))
        : true
    );
  };

  return {
    init,
    selected,
    checked,
    dataSource,
    originalDataSource,
    originalSelected,
    leftChecked,
    rightChecked,
    handleFilter,
    handleToggle,
    handleToggleAll,
    handleCheckedLeft,
    handleCheckedRight,
    numberOfChecked,
  };
}
