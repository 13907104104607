import React from "react";
import {useAxios} from "../../../core/axiosHook";
import {Group, Summary} from "../../../model/Customer";
import addBusinessName from "./UseAddBusinessName";

export function useGroups() {
    const [groups, setGroups] = React.useState<Group[]>([]);
    const {isLoading, request, reload, setReload} = useAxios();

    React.useEffect(() => {
        request({method: "GET", url: `${process.env.REACT_APP_BE_INGRESS}/groups`})
            .then(res => {
                setGroups(res.data);
            });
    }, [reload, request]);

    return {groups, isLoading, setReload};
}

export function useSummary() {
    const [summary, setSummary] = React.useState<Summary[]>();
    const [summaryCmr, setSummaryCmr] = React.useState<number>();
    const {isLoading, request} = useAxios();

    const get = (day: string, companies: number[], group: Group) => {
        setSummary([]);
        request({
            method: "GET",
            url: `${process.env.REACT_APP_BE_INGRESS_RATIOS}/summary/${day}`,
            params: {
                companyIds: companies.join(",")
            }
        }).then(res => {
            setSummary(addBusinessName(res.data.data, group));
            setSummaryCmr(res.data.groupCmr)
        });
    };

    return {summary, summaryCmr, get, isLoading};
}
