import React from 'react';
import SuspenseComponent from "../../Layout/SuspenseComponent";
import {Trans, useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import {useGroups} from "../hook/useGroups";
import {Group} from "../../../model/Customer";
import {GridActionsCellItem, GridColDef, GridRowParams} from "@mui/x-data-grid";
import InfoIcon from "@mui/icons-material/Info";
import LinkIcon from "@mui/icons-material/Link";
import {DataGridPro} from "@mui/x-data-grid-pro";
import CustomerGrid from "../components/CustomersGrid";
import {useNavigate} from "react-router-dom";
import GroupAddModal from "../components/GroupAddModal";
import {ROUTES} from "../../../routes/ROUTES";
import AddIcon from "@mui/icons-material/Add";
import {Button, Tooltip} from "@mui/material";

function GroupsView() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [selectedGroup, setSelectedGroup] = React.useState<Group>(null);
    const [createOpen, setCreateOpen] = React.useState<boolean>(false);

    const columns: GridColDef[] = [
        {
            field: 'details', headerName: '', type: 'actions', width: 50,
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem label={t("COMMON.DETAILS")} title={t("COMMON.DETAILS")}
                                     icon={<Tooltip title={t('COMMON.DETAILS').toString()}><InfoIcon /></Tooltip>}
                                     onClick={() => {
                                         setCreateOpen(true);
                                         setSelectedGroup(params.row);
                                     } }
                />
            ]
        },
        {
            field: 'goto', headerName: '', type: 'actions', width: 50,
            getActions: (params: GridRowParams<Group>) => [
                <GridActionsCellItem
                                     label={t("COMMON.GO_TO")}
                                     title={t("COMMON.GO_TO")}
                                     icon={<Tooltip title={t('COMMON.GO_TO').toString()}><LinkIcon /></Tooltip>}
                                     onClick={() => navigate(ROUTES.EDIT_CUSTOMER + params.row.ownerId)}
                />
            ]
        },
        { field: 'description', headerName: t("CUSTOMER_DETAILS.FIELDS.GROUPS.NAME"), flex: 1},
    ]


    const groupsHook = useGroups();

    const onCloseModal = (callback) => {
        groupsHook.setReload(true);
        callback();
    }

    return <SuspenseComponent isLoading={groupsHook.isLoading}>
        <div className="section-header">
            <Typography variant="h6">
                <Trans>GROUP_LIST.TITLE</Trans>
            </Typography>
            <Button
                aria-label="add"
                onClick={_ => {
                    setCreateOpen(true);
                    setSelectedGroup(new Group())}
                }>
                <AddIcon />
                <Typography>
                    <Trans>COMMON.ADD</Trans>
                </Typography>
            </Button>
        </div>

        <GroupAddModal open={createOpen}
                       data={selectedGroup}
                       onClose={() => onCloseModal(() => {setSelectedGroup(null); setCreateOpen(false)})}
                       ></GroupAddModal>

        <DataGridPro
            loading={groupsHook.isLoading}
            getRowId={(row: Group) => row.groupId}
            rows={groupsHook.groups}
            columns={columns}
            getDetailPanelContent={({ row }) => <div style={{padding: ".5rem"}}>
                <Typography variant="h6"><Trans>CUSTOMER_DETAILS.FIELDS.GROUPS.MEMBERS</Trans></Typography>
                <CustomerGrid customers={row.members} disableToolbar={true} />
            </div>}
            getDetailPanelHeight={() => 'auto'} // Height based on the content.
        />
    </SuspenseComponent>
}

export default GroupsView;