import React from "react";
import {Button, Card, CardContent, CardHeader} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Trans} from "react-i18next";
import SuspenseComponent from "../../Layout/SuspenseComponent";
import InvoicesGrid from "../../Invoices/components/InvoicesGrid";
import {useCustomerInvoices} from "../hook/useCustomer";
import {CustomerComponentProps} from "./CustomerComponentProps";
import Placeholder from "../../SkinnableComponents/Placeholder/Placehoder";
import AddIcon from "@mui/icons-material/Add";
import GenerateInvoiceModal from "./GenerateInvoiceModal";

function CustomerInvoices(props: CustomerComponentProps) {
    const customerInvoiceHook = useCustomerInvoices();
    const [generateModalVisible, setGenerateModalVisible] = React.useState(false);

    React.useEffect(() => {
        if (props.id) {
            customerInvoiceHook.get(props.id).then();
        }
    }, [props.id])

    const generateInvoice = () => {
        setGenerateModalVisible(true);
    }

    return <SuspenseComponent isLoading={customerInvoiceHook.isLoading && customerInvoiceHook.isLoading}>
        <Card>
            <CardHeader title={<div className="section-header">
                <Typography variant="h6" component="div">
                    <Trans>CUSTOMER_DETAILS.FIELDS.INVOICES.TITLE</Trans>
                </Typography>
                <Button
                    aria-label="generate"
                    onClick={generateInvoice}
                >
                    <AddIcon />
                    <Typography>
                        <Trans>COMMON.GENERATE</Trans>
                    </Typography>
                </Button>
            </div>}>
            </CardHeader>
            <CardContent>
                <GenerateInvoiceModal open={generateModalVisible} onClose={() => {
                    setGenerateModalVisible(false);
                    customerInvoiceHook.get(props.id).then();
                }} customerId={props.id} />
                {customerInvoiceHook.invoices?.length
                    ? <InvoicesGrid invoices={customerInvoiceHook.invoices}
                                    onSelectionChange={null}
                                    disableSelection
                                    onCreditNoteGenerated={() => customerInvoiceHook.get(props.id).then()}
                    />
                    : <Placeholder></Placeholder>
                }
            </CardContent>
        </Card>
    </SuspenseComponent>
}

export default CustomerInvoices;