import {ServiceActivation} from "../../../model/Customer";
import {useAxios} from "../../../core/axiosHook";
import {AxiosResponse} from "axios";
import {ServiceStatusesMap} from "../../Customers/core/DataSources";

export function useServiceActivations() {
    const {isLoading, request, setReload} = useAxios();

    const get = (customerId: number[], serviceId: number) => {
        if (customerId?.length) {
            return request({
                method: "GET",
                url: `${process.env.REACT_APP_BE_INGRESS}/serviceActivations?customerId=${customerId.join(',')}&serviceId=${serviceId}`
            }).then((res: AxiosResponse<ServiceActivation[]>) => {
                return res.data?.filter(s => ServiceStatusesMap.active.indexOf(s.serviceActivationStatus) !== -1 && s.customer?.tppCompanyId)
                    .map(s => s.customer?.tppCompanyId) || [];
            });
        }
    };

    return {get, isLoading, setReload};

}