import {DataGridPro} from "@mui/x-data-grid-pro";
import {Service} from "../../Services/core/Service";
import {useServiceConfigurations, useServices} from "../hook/useServices";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React from "react";
import {ServiceConfiguration} from "../core/ServiceConfiguration";
import {GridActionsCellItem, GridColDef, GridRowParams} from "@mui/x-data-grid";
import {Button, Grid, Tooltip} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import {ROUTES} from "../../../routes/ROUTES";
import {useNavigate, useParams} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import SuspenseComponent from "../../Layout/SuspenseComponent";
import Placeholder from "../../SkinnableComponents/Placeholder/Placehoder";

function ServiceConfigurationListView() {
    let { serviceId } = useParams();
    const [selectedService, setSelectedService] = React.useState<Service>(null);
    const navigate = useNavigate();
    const {t} = useTranslation();

    const serviceConfigHook = useServiceConfigurations();
    const servicesHook = useServices();


    const columns: GridColDef[] = [
        {
            field: 'details', headerName: '', type: 'actions',
            getActions: (params: GridRowParams<ServiceConfiguration>) => [
                <GridActionsCellItem label="Dettagli" icon={<Tooltip title={t('COMMON.DETAILS').toString()}><InfoIcon /></Tooltip>}
                                     onClick={() => navigate(ROUTES.EDIT_SERVICE_CONFIGURATION + "/" + params.row.serviceConfigId) }
                />
            ]
        },
        { field: 'serviceConfigName', headerName: t("SERVICE_CONFIG.FIELDS.NAME"), flex: 1 },
        { field: 'frequency', headerName: t("SERVICE_CONFIG.FIELDS.FREQUENCY"), width: 150, valueFormatter: ({value}) => t("FREQUENCY_PERIODS." + value) }
    ]

    React.useEffect(() => {
        if (servicesHook.services?.length && serviceId) {
            const sx = servicesHook.services.find(s => s.serviceId === +serviceId);
            setSelectedService(sx);
        }
    }, [serviceId, servicesHook.services])

    React.useEffect(() => {
        if (selectedService) {
            serviceConfigHook.get(selectedService.serviceId).then();
           // modalState.toggle({ serviceId: 2, serviceConfigName: '', frequency: SERVICE_FREQUENCY.WEEKLY, batchParamsValues: [], serviceConfigId: null});
        }
    }, [selectedService])

    return <>
        <div className="section-header">
            <Typography variant="h6">
               <Trans>SERVICE_CONFIG_LIST.TITLE</Trans>
            </Typography>
            <Button
                aria-label="add"
                onClick={_ => navigate(ROUTES.ADD_SERVICE_CONFIGURATION + (selectedService?.serviceId ? `/${selectedService.serviceId}` : ''))}
            >
                <AddIcon />
                <Typography>
                    <Trans>COMMON.ADD</Trans>
                </Typography>
            </Button>
        </div>
        <Grid container columns={1} spacing={2}>
            <Grid item xs={1}>
        <Autocomplete
            clearOnEscape
            disableClearable
            fullWidth
            id="search-customer"
            options={servicesHook.services}
            value={selectedService}
            onChange={(_, value: Service) => {
                setSelectedService(value);
            }}
            getOptionLabel={(option: Service) => option?.description}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Servizio"
                    InputProps={{
                        ...params.InputProps,
                        type: 'search',
                    }}
                />
            )}
        />
            </Grid>
            <Grid item xs={1}>
                {selectedService && <SuspenseComponent isLoading={serviceConfigHook.isLoading}>
                    {serviceConfigHook.serviceConfigurations.length
                        ? <DataGridPro getRowId={(row: ServiceConfiguration) => row.serviceConfigId}
                                       rows={serviceConfigHook.serviceConfigurations}
                                       columns={columns}
                                       hideFooter/>
                        : <Placeholder></Placeholder>
                    }
                </SuspenseComponent>
                }
            </Grid>
        </Grid>
    </>
}

export default ServiceConfigurationListView;