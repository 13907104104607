import {useNavigate} from "react-router-dom";
import {GridActionsCellItem, GridColDef, GridRowParams, GridToolbar} from "@mui/x-data-grid";
import InfoIcon from "@mui/icons-material/Info";
import {CustomerAnagDetails} from "../../../model/Customer";
import {useTranslation} from "react-i18next";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {ROUTES} from "../../../routes/ROUTES";
import {Tooltip} from "@mui/material";

interface CustomerGridProps {
    customers: CustomerAnagDetails[];
    disableToolbar?: boolean;
}

function CustomerGrid(props: CustomerGridProps) {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const columns: GridColDef[] = [
        {
            field: 'details', headerName: '', type: 'actions',
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem label="Dettagli"
                                     icon={<Tooltip title={t('COMMON.DETAILS').toString()}><InfoIcon /></Tooltip>}
                                     onClick={() => navigate(ROUTES.EDIT_CUSTOMER + params.row.customerId) }
                />
            ]
        },
        { field: 'customerCode', headerName: t("CUSTOMER_LIST.FIELDS.CODE")},
        { field: 'vatNumber', headerName: t("CUSTOMER_LIST.FIELDS.VAT_NUMBER"), width: 200},
        { field: 'taxCode', headerName: t("CUSTOMER_LIST.FIELDS.TAX_CODE"), width: 200},
        { field: 'businessName', headerName: t("CUSTOMER_LIST.FIELDS.NAME"), flex: 1},
        { field: 'customerStatus', headerName: t("CUSTOMER_LIST.FIELDS.STATUS"), filterable: true},
        { field: 'customerType', headerName: t("CUSTOMER_LIST.FIELDS.TYPE"), valueGetter: (params) => t(`CUSTOMER_TYPES.${params.row.customerType}`)}

    ]

    function MyCustomToolbar(props: any) {
        return <GridToolbar {...props} printOptions={{disableToolbarButton: true }}/>;
    }

    return <DataGridPro getRowId={(row: CustomerAnagDetails) => row.customerId} rows={props.customers} columns={columns}
                            slots={{
                                toolbar: props.disableToolbar ? null : MyCustomToolbar,
                            }}
            />
}

export default CustomerGrid;