import React from "react";
import {useAxios} from "../../../core/axiosHook";
import {Group} from "../../../model/Customer";
import {AxiosResponse} from "axios";

export function useGroups() {
    const [groups, setGroups] = React.useState<Group[]>([]);
    const {isLoading, request, reload, setReload} = useAxios();

    React.useEffect(() => {
        request({ method: "GET", url:`${process.env.REACT_APP_BE_INGRESS}/groups` }).then(res => {
            setGroups(res.data);
        });
    }, [reload]);

    return {groups, isLoading, setReload};
}

export function useGroup() {
    const [group, setGroup] = React.useState<Group>(new Group());
    const {isLoading, request} = useAxios();

    const saveGroup = (values: Group, callback: (res: AxiosResponse<Group>) => Group) => {
        request({  method: values.groupId ? "PUT" : "POST",
            url:`${process.env.REACT_APP_BE_INGRESS}/groups` + (values.groupId  ? `/${values.groupId}` : ''), data: values }).then(res => {
            setGroup(callback(res.data));
        });
    }

    return {group, isLoading, saveGroup};
}

