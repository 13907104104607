import React from 'react';
import {AxiosResponse} from "axios";
import SuspenseComponent from "../../Layout/SuspenseComponent";
import {Service} from "../core/Service";
import {useServices} from "../hook/useServices";
import ServicesGrid from "../components/ServicesGrid";
import PageTitle from "../../SkinnableComponents/PageTitle/PageTitle";

function ServicesView() {

    const mapService = React.useCallback((res: AxiosResponse<Service[]>): Service[] => {
        return res.data;
    }, []);

    const serviceHook = useServices(mapService);

    return <SuspenseComponent isLoading={serviceHook.isLoading}>
        <PageTitle title="SERVICE_LIST.TITLE"></PageTitle>
        <ServicesGrid services={serviceHook.services} />
    </SuspenseComponent>
}

export default ServicesView;