import React from "react";
import {GridColDef, GridDensity, GridToolbarExport} from "@mui/x-data-grid";
import {DataGridPro, GridToolbarContainer} from "@mui/x-data-grid-pro";
import moment from "moment";
import {Summary} from "../../../model/Customer";

interface CustomerGridProps {
    rows: Summary[];
    columns: GridColDef[];
    groupName: string;
    day: string;
    disableToolbar?: boolean;
}

function MonitoringGrid(props: CustomerGridProps) {
    const [density] = React.useState<GridDensity>('compact');

    function formatGroupName(groupName: string) {
        return groupName.replace(/[^\w]/g, '').toUpperCase();
    }

    function MyCustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    className='Mui'
                    csvOptions={{
                        delimiter: ';',
                        utf8WithBom: true,
                        fileName: 'M2Z002_' + formatGroupName(props.groupName) + '_' + props.day + '_' + moment().format('YYYYMMDD'),
                    }}
                    printOptions={{disableToolbarButton: true}}
                />
            </GridToolbarContainer>
        );
    }

    return (props.rows &&
        <div style={{width: '100%'}}>
            <DataGridPro
                columns={props.columns}
                rows={props.rows}
                getRowId={(row: Summary) => row.companyId}
                columnBuffer={2}
                slots={{toolbar: props.disableToolbar ? null : MyCustomToolbar}}
                density={density}
                hideFooter
            />
        </div>
    );
}

export default MonitoringGrid;