import React from "react";
import {Skeleton} from "@mui/material";

interface SuspenseComponentProps {
    children: React.ReactNode,
    isLoading: boolean
}

function SuspenseComponent(props: SuspenseComponentProps) {
    return (
        props.isLoading
            ? <Skeleton variant="rectangular" width="100%" height={118} />
            : <>{props.children}</>
    )
}

export default SuspenseComponent;