import React from "react";

export function useDebounceValue<T>(inputValue: T, delay: number) {
    const [debouncedValue, setDebouncedValue] = React.useState<T>(inputValue);

    React.useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(inputValue);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [inputValue, delay]);

    return debouncedValue;
}