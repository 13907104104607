import {useAxios} from "../../../core/axiosHook";

export const useGenerateCreditNote = () => {
    const {isLoading, request} = useAxios();

    const generate = (invoiceId: number) => {
        return request({ method: "POST", url:`${process.env.REACT_APP_BE_INGRESS}/invoices/${invoiceId}/reverse`})
    }
    return {generate, isLoading};
}
