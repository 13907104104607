import {
    BILLING_TYPE,
    ContactType,
    CustomerStatus,
    CustomerType,
    PaymentMethod,
    PaymentType,
    ServiceStatus,
    SubscriptionType
} from "../../../model/Customer";
import {InvoiceStatus} from "../../Invoices/core/Invoice";

export const CustomerTypes: CustomerType[] = [
    CustomerType.GROUP,
    CustomerType.OFFICE,
    CustomerType.SINGLE,
    CustomerType.VENDOR
]

export const CustomerStatuses: CustomerStatus[] = [
    CustomerStatus.L00,
    CustomerStatus.L01,
    CustomerStatus.L02,
    CustomerStatus.L03,
    CustomerStatus.L04,
    CustomerStatus.L05,
    CustomerStatus.L0X,
    CustomerStatus.L0_
]


export const ServiceStatuses: ServiceStatus[] = [
    ServiceStatus.L10,
    ServiceStatus.L11,
    ServiceStatus.L12,
    ServiceStatus.L13,
    ServiceStatus.L14,
    ServiceStatus.L15,
    ServiceStatus.L1X,
    ServiceStatus.L1_
]


export const CustomerStatusesMap = {
    active: [ CustomerStatus.L04, CustomerStatus.L05 ],
    history: [ CustomerStatus.L0X ],
    pending: [  CustomerStatus.L0_ ],
    prospect: [ CustomerStatus.L00, CustomerStatus.L01, CustomerStatus.L02, CustomerStatus.L03 ],
    valid: [ CustomerStatus.L00, CustomerStatus.L01, CustomerStatus.L02, CustomerStatus.L03, CustomerStatus.L04, CustomerStatus.L05 ]
}

export const InvoicesStatusesMap = {
    sent: [ InvoiceStatus.SENT ],
    approved: [ InvoiceStatus.APPROVED ],
    pending: [  InvoiceStatus.GENERATED ],
}

export const ServiceStatusesMap = {
    active: [ServiceStatus.L10, ServiceStatus.L11, ServiceStatus.L12, ServiceStatus.L13, ServiceStatus.L14, ServiceStatus.L15],
    inactive: [ServiceStatus.L1X, ServiceStatus.L1_]
}

export const ContactTypes = [
    { value: ContactType.FATTURAZIONE, icon: 'euro' },
    { value: ContactType.CONTATTO, icon: 'person' },
    { value: ContactType.ADDRESS, icon: 'home' },
    { value: ContactType.NOTIFY, icon: 'mail' },
]

export const SubscriptionTypes = [
    SubscriptionType.FLAT,
    SubscriptionType.PAY_FOR_USE
]

export const PaymentTypes = [
    PaymentType.TP01,
    PaymentType.TP02,
    PaymentType.TP03
];

export const PaymentMethods = [
    PaymentMethod.MP01,
    PaymentMethod.MP02,
    PaymentMethod.MP03,
    PaymentMethod.MP04,
    PaymentMethod.MP05,
    PaymentMethod.MP06,
    PaymentMethod.MP07,
    PaymentMethod.MP08,
    PaymentMethod.MP09,
    PaymentMethod.MP10,
    PaymentMethod.MP11,
    PaymentMethod.MP12,
    PaymentMethod.MP13,
    PaymentMethod.MP14,
    PaymentMethod.MP15,
    PaymentMethod.MP16,
    PaymentMethod.MP17,
    PaymentMethod.MP18,
    PaymentMethod.MP19,
    PaymentMethod.MP20,
    PaymentMethod.MP21,
    PaymentMethod.MP22,
    PaymentMethod.MP23
];

export const BillingTypes = [
    BILLING_TYPE.ANTICIPATO,
    BILLING_TYPE.CONSUNTIVO
];

export const getContactTypeIcon = (type: ContactType) => ContactTypes[ContactTypes.findIndex(x => x.value === type)]?.icon || '';
