import React, {useState} from 'react';
import "./SummaryMonitoringHoc.scss"
import SuspenseComponent from "../../Layout/SuspenseComponent";
import {Trans, useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import {Card, CardContent, CardHeader} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {Group, Service} from "../../../model/Customer";
import Placeholder from "../../SkinnableComponents/Placeholder/Placehoder";
import {useGroups, useSummary} from "../hook/useGroups";
import SummaryGrid from "../components/SummaryGrid";
import Stack from "@mui/material/Stack";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";
import {useDebounceValue} from "../../../core/hook/useDebounceValue";
import {createSummaryColumns} from "../core/RowColumnDefinitions";
import {useServices} from "../hook/useServices";
import {useServiceActivations} from "../hook/useServiceActivation";
import {useNavigate} from "react-router-dom";


interface MonitoringHocProps {
    mode: string;
    customerType?: string;
}

function SummaryMonitoringHoc(props: MonitoringHocProps) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [day, setDay] = useState(moment());
    const dayDebounced = useDebounceValue(day, 500);
    const groupsHook = useGroups();
    const servicesHook = useServices();
    const serviceActivationsHook = useServiceActivations();
    const [selectedGroup, setSelectedGroup] = React.useState<Group>(new Group());
    const [selectedGroupIds, setSelectedGroupIds] = React.useState<number[]>([]);
    const selectedSummaryGroup = useSummary();
    const [selectedService, setSelectedService] = React.useState<Service>();

    React.useEffect(() => {
        if (selectedGroupIds.length > 0 && day.isValid()) {
            selectedSummaryGroup.get(day.format('YYYY-MM-DD'), selectedGroupIds, selectedGroup);
        }
    }, [selectedGroupIds, dayDebounced, selectedGroup])

    const onChange = (selection: Group) => {
        setSelectedGroup(selection);
        setSelectedGroupIds([]);
        const customerIds = selection.members.map(member => member.customerId);
        serviceActivationsHook.get(customerIds, selectedService.serviceId).then(ids => {
            setSelectedGroupIds(ids);
        });
    }

    React.useEffect(() => {
        const serv = servicesHook.services.filter(s => s.name === "DAS01");
        setSelectedService(serv?.length ? serv[0] : new Service());
    }, [servicesHook.services]);

    return <SuspenseComponent isLoading={groupsHook.isLoading}>
        <Card className="card-summary">
            <CardHeader title={<div className="section-header">
                <Stack direction="column" spacing={2}>
                    <Typography variant="h6">
                        <div className="section-title">
                            <Trans>DASHBOARD_MONITORING.TITLES.{props.mode?.toUpperCase()}</Trans>
                        </div>
                    </Typography>
                </Stack>
            </div>}>
            </CardHeader>
            <div className="summary-element">
                <div className="summary-selection-element">
                    <div className="groups-selection">
                        <Autocomplete
                            clearOnEscape
                            disableClearable
                            sx={{width: 300, marginRight: "2rem"}}
                            id="search-customer"
                            options={groupsHook.groups}
                            getOptionLabel={(option: Group) => option?.description}
                            onChange={(_, value: Group) => {
                                onChange(value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Gruppo"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                        <LocalizationProvider
                            dateAdapter={AdapterMoment}
                            adapterLocale="it-IT"
                        >
                            <DatePicker
                                label="Data (Reg)"
                                format="DD-MM-YYYY"
                                value={day}
                                onChange={(newValue) => setDay(newValue)}
                            />
                        </LocalizationProvider>
                        <Autocomplete
                            clearOnEscape
                            disableClearable
                            sx={{width: 200, marginLeft: "2rem"}}
                            id="search-customer"
                            options={servicesHook.services}
                            value={selectedService}
                            disabled
                            getOptionLabel={(option: Service) => option?.description}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Servizio"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className="picker-group">
                        {selectedSummaryGroup.summaryCmr !== undefined &&
                            <div className="picked-group">
                                <div className="group-text">
                                    <Trans>DASHBOARD_MONITORING.DASHBOARD_RATES.GROUP_CRM</Trans>

                                </div>
                                <span className="group-cmr">{selectedSummaryGroup.summaryCmr}%</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <CardContent style={{height: 'calc(100vh - 261px)', overflow: 'auto'}}>
                {Boolean(!groupsHook.isLoading && selectedGroup.groupId)
                    ? <SuspenseComponent isLoading={serviceActivationsHook.isLoading}>
                        {Boolean(!selectedSummaryGroup.isLoading && selectedGroupIds.length > 0)
                            ? <SummaryGrid
                                columns={createSummaryColumns(t, navigate, day.format('YYYY-MM-DD'))}
                                rows={selectedSummaryGroup.summary}
                                groupName={selectedGroup.description}
                                day={day.format('YYYYMMDD')}
                            />
                            : <Placeholder></Placeholder>
                        }
                    </SuspenseComponent>
                    : <Placeholder></Placeholder>
                }
            </CardContent>
        </Card>
    </SuspenseComponent>
}

export default SummaryMonitoringHoc;