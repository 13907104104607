import React from 'react';
import {
    Button, Checkbox,
    Dialog,
    DialogContent,
    DialogTitle, FormControlLabel, Tooltip
} from "@mui/material";
import {ModalComponentProps} from "./CustomerComponentProps";
import {Trans} from "react-i18next";
import '../../../index.scss';
import {useCustomerConfiguration, useServiceConfigurations} from "../hook/useCustomerConfiguration";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {ServiceConfiguration} from "../../ServiceConfiguration/core/ServiceConfiguration";
import Cron from "../../../core/pmt-cron-editor/Cron";
import ServiceBatchConfigurationComponent
    from "../../ServiceConfiguration/components/ServiceBatchConfigurationComponent";
import {useBatches} from "../hook/useBatches";
import {CustomerConfiguration, CustomerConfigurationAssignRequest} from "../core/CustomerConfiguration";
import {Service} from "../../../model/Customer";
import {SHORTCUTS} from "../../../core/pmt-cron-editor/core/constants";
import SuspenseComponent from "../../Layout/SuspenseComponent";



function CustomerConfigurationModal(props: ModalComponentProps<Service>) {
    const [values, setValues] = React.useState<CustomerConfiguration>(null);
    const [selectedConfiguration, setSelectedConfiguration] = React.useState<ServiceConfiguration>(null);
    const customerConfigurationHook = useCustomerConfiguration();
    const serviceConfigurationsHook = useServiceConfigurations();
    const batchHook = useBatches();

    React.useEffect(() => {
        if (props.customerId && props.data) {
            customerConfigurationHook.get(props.customerId, props.data.serviceId).then();
            serviceConfigurationsHook.get(props.data.serviceId).then();
            batchHook.get(props.data.serviceId).then();
        }
    }, [props.customerId, props.data])

    React.useEffect(() => {
        if (customerConfigurationHook.customerConfiguration && serviceConfigurationsHook.serviceConfigurations) {
            const c = serviceConfigurationsHook.serviceConfigurations
                .find(s => s.serviceConfigId === customerConfigurationHook.customerConfiguration?.serviceConfig?.serviceConfigId);
            if (c) {
                setSelectedConfiguration(c);
            }
        }
    }, [customerConfigurationHook.customerConfiguration, serviceConfigurationsHook.serviceConfigurations])

    React.useEffect(() => {
        let conf = new CustomerConfiguration();

        if (selectedConfiguration) {
            const cron = SHORTCUTS.find(s => s.name === `@${selectedConfiguration.frequency.toUpperCase()}`);

            conf = {
                customerBatchParamList: selectedConfiguration.batchParamsValues,
                customerId: props.customerId,
                serviceConfig: selectedConfiguration,
                isEnable: true,
                cronExpression: cron ? cron.value : null
            };
        }

        if (customerConfigurationHook.customerConfiguration) {
            const p = customerConfigurationHook.customerConfiguration?.customerBatchParamList;
            conf = {
                ...conf,
                cronExpression: customerConfigurationHook.customerConfiguration?.cronExpression || conf.cronExpression,
                customerBatchParamList: p?.length ? [...conf.customerBatchParamList, ...p] : conf.customerBatchParamList,
                isEnable: customerConfigurationHook.customerConfiguration?.isEnable
            }
        }

        setValues(conf);
    }, [selectedConfiguration, customerConfigurationHook.customerConfiguration])


    const preventCloseOnBackdrop = () => {
        return;
    }

    const submit = () => {
        console.log(values.customerBatchParamList);
        const c: CustomerConfigurationAssignRequest = {
            customerId: values.customerId,
            serviceConfigId: values.serviceConfig?.serviceConfigId,
            cronExpression: values.cronExpression,
            customerBatchParamList: values.customerBatchParamList,
            isEnable: values.isEnable
        }
        customerConfigurationHook.save(c).then(_ => handleClose());
    }

    const handleClose = () => {
        setValues(new CustomerConfiguration());
        setSelectedConfiguration(null);
        props.onClose();
    }

   const handleChange = (val: string) => {
        setValues({
            ...values,
            cronExpression: val
        })
    }

    const handleParamChange = (val) => {
        setValues({
                ...values,
                customerBatchParamList: val
            })
    }

    const handleCheckboxChange = (e) => {
        setValues({
            ...values,
            isEnable: e.target.checked
        })
    }

    return  <Dialog
        fullScreen
        open={props.open}
        onClose={preventCloseOnBackdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <DialogTitle className="section-header">
            <Trans>CUSTOMER_DETAILS.FIELDS.CUSTOMER_CONFIGURATION.TITLE</Trans>
            <div>
                <Button color="error" onClick={handleClose}><Trans>COMMON.CANCEL</Trans></Button>
                <Button type="submit" onClick={submit}><Trans>COMMON.SAVE</Trans></Button>
            </div>
        </DialogTitle>
        <DialogContent>
            <SuspenseComponent isLoading={customerConfigurationHook.isLoading}>
                <div style={{padding: '1rem', display: 'flex', flexDirection: 'column', gap: '.5rem'}}>
                    <div style={{display: 'flex', flexDirection: 'row', gap: '.5rem'}}>
                    <Autocomplete
                        clearOnEscape
                        disableClearable
                        fullWidth
                        id="search-customer"
                        options={serviceConfigurationsHook.serviceConfigurations}
                        value={selectedConfiguration}
                        onChange={(_, value: ServiceConfiguration) => {
                            setSelectedConfiguration(value);
                        }}
                        getOptionLabel={(option: ServiceConfiguration) => option?.serviceConfigName}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Configurazione"
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                }}
                            />
                        )}
                    />
                    <FormControlLabel
                        label='Stato:'
                        labelPlacement="start"
                        control={
                            <Tooltip title={values?.isEnable ? 'Disabilita' : 'Abilita'}>
                                <Checkbox
                                    checked={values?.isEnable}
                                    name="isEnable"
                                    id="isEnable"
                                    onChange={handleCheckboxChange}
                                />
                            </Tooltip>
                        }
                    />
                </div>
                    {selectedConfiguration
                        ? <>
                            <Cron cron={values.cronExpression} onChange={handleChange} />
                            <ServiceBatchConfigurationComponent batches={batchHook.batches} batchParamsValue={values.customerBatchParamList} onChange={handleParamChange} />
                        </>
                        : <></>
                    }
                </div>
        </SuspenseComponent>
        </DialogContent>
    </Dialog>
}

export default CustomerConfigurationModal;