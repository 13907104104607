import React, {useState} from 'react';
import './MonitoringHoc.scss';
import SuspenseComponent from "../../Layout/SuspenseComponent";
import {Trans, useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import {Card, CardContent, CardHeader} from "@mui/material";
import {useCustomers, useCustomersMonitoring} from "../hook/UseMonitoring";
import MonitoringGrid from "../components/MonitoringGrid";
import {CustomerStatusesMap} from "../../Customers/core/DataSources";
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {CustomerAnagDetails} from "../../../model/Customer";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import moment, {Moment} from "moment";
import {AxiosResponse} from "axios";
import Placeholder from "../../SkinnableComponents/Placeholder/Placehoder";
import {useDebounceValue} from "../../../core/hook/useDebounceValue";
import {useParams} from "react-router-dom";

interface MonitoringHocProps {
    mode: string;
    customerType?: string;
}

function MonitoringHoc(props: MonitoringHocProps) {
    const {t} = useTranslation();
    let { companyId } = useParams();
    const [startDate, setStartDate] = useState<Moment>(moment().utc().subtract(1, 'month').startOf('day'));
    const [endDate, setEndDate] = useState<Moment>(moment().utc().startOf('day'));

    const startDateDebounced = useDebounceValue(startDate, 500);
    const endDateDebounced = useDebounceValue(endDate, 500);
    const [selectedCustomers, setSelectedCustomers] = React.useState<CustomerAnagDetails>(new CustomerAnagDetails());

    const mapCustomer = (res: AxiosResponse<CustomerAnagDetails[]>): CustomerAnagDetails[] => {
        return res.data.map(c => c);
    }

    const customersHook = useCustomers(mapCustomer);
    const customerRatiosHook = useCustomersMonitoring(t);

    React.useEffect(() => {
        if (customersHook.customers && companyId) {
            const selected = customersHook.customers.filter(c => +c.tppCompanyId === +companyId);
            if (selected?.length) {
                setSelectedCustomers(selected[0]);
            }
        }
    }, [customersHook.customers, companyId])

    React.useEffect(() => {
        customersHook.get(`?status=${CustomerStatusesMap.active.join(',')}`)
    }, [])

    React.useEffect(() => {
        if (selectedCustomers.tppCompanyId && startDate && endDate) {
            customerRatiosHook.get(selectedCustomers.tppCompanyId, startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"));
        }
    }, [selectedCustomers.customerId, startDateDebounced, endDateDebounced])

    const onChange = (selection: CustomerAnagDetails) => {
        setSelectedCustomers(selection)
    }

    return <SuspenseComponent isLoading={customersHook.isLoading}>
        <Card className="card-monitoring">
            <CardHeader title={<div className="section-header">
                <Typography variant="h6">
                    <div className="section-title">
                        <Trans>DASHBOARD_MONITORING.TITLES.{props.mode?.toUpperCase()}</Trans>
                        <div className="selection-element">
                            <Autocomplete
                                sx={{width: 300, marginLeft: "2rem"}}
                                id="search-customer"
                                disableClearable
                                options={customersHook.customers}
                                getOptionLabel={(option: CustomerAnagDetails) => option.customerCode ? option?.customerCode + " - " + option?.businessName : ''}
                                onChange={(_, value: CustomerAnagDetails) => {
                                    onChange(value);
                                }}
                                value={selectedCustomers || null}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Azienda"
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                                clearOnEscape
                            />
                        </div>
                    </div>
                </Typography>
                <Stack direction="row" spacing={2}>
                    <Stack direction="row" spacing={2} sx={{mb: 1, mt: 1}} className="Warning-title">
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it-IT">
                            <DatePicker
                                label="da"
                                format="DD-MM-YYYY"
                                value={startDate}
                                onChange={(newValue) => setStartDate(newValue)}
                            />
                            <DatePicker
                                label="a"
                                format="DD-MM-YYYY"
                                value={endDate}
                                onChange={(newValue) => setEndDate(newValue)}
                            />
                        </LocalizationProvider>
                    </Stack>
                </Stack>
            </div>}>
            </CardHeader>
            <CardContent style={{height: 'calc(100vh - 200px)', overflow: 'auto'}}>
                {Boolean(selectedCustomers.tppCompanyId)
                    ? <SuspenseComponent isLoading={customerRatiosHook.isLoading}>
                        <MonitoringGrid
                            data={customerRatiosHook.customersMonitoring}
                            columns={customerRatiosHook.monitoringColumns}
                            rows={customerRatiosHook.monitoringRows}
                            customer={selectedCustomers.customerCode}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    </SuspenseComponent>
                    : <Placeholder></Placeholder>
                }
            </CardContent>
        </Card>
    </SuspenseComponent>
}

export default MonitoringHoc;