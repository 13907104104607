import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormGroup,
    FormHelperText, Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {ModalComponentProps} from "./CustomerComponentProps";
import {Contact, ContactType} from "../../../model/Customer";
import {useFormik} from "formik";
import {Trans, useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {useContact} from "../hook/useContact";
import {ContactTypes} from "../core/DataSources";
import {GenericContactValidation} from "../core/validation/ContactValidation";
import '../../../index.scss';


function ContactDetail(props: ModalComponentProps<Contact, ContactType>) {
    const [selectedType, setSelectedType] = React.useState<ContactType>(ContactType.CONTATTO);
    const {t} = useTranslation();
    const contactHook = useContact(props.data);

    const formik = useFormik({
        initialValues: contactHook.contact,
        enableReinitialize: true,
        validationSchema: GenericContactValidation(t, selectedType),
        onSubmit: (values) => {
                contactHook.saveContact({
                    ...props.data,
                    ...values,
                    isEnable: true,
                    customerId: props.customerId
                },
                values.contactId,
                (res) => {
                    props.onClose()
                    return res.data;
                })
        },
    });

    const preventCloseOnBackdrop = () => {
        return;
    }

    React.useEffect(() => {
        setSelectedType(formik.values.contactType);
    }, [formik.values.contactType])

    return  <Dialog
        open={props.open}
        onClose={preventCloseOnBackdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{
            component: 'form',
            onSubmit: formik.handleSubmit,
        }}
    >
        <DialogTitle>
            <Trans>CUSTOMER_DETAILS.FIELDS.CONTACTS.DETAILS.TITLE</Trans>
            <IconButton
                aria-label="close"
                onClick={props.onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <div style={{display:"flex", flexDirection: "column", gap: "1rem"}}>
                <FormControl variant="standard" sx={{minWidth: "100%" }}>
                    <InputLabel id="contactTypeLabel" error={Boolean(formik.errors.contactType)}>
                        <Trans>CUSTOMER_DETAILS.FIELDS.CONTACTS.TYPE</Trans>
                    </InputLabel>
                    <Select
                        labelId="contactTypeLabel"
                        id="contactType"
                        value={formik.values.contactType}
                        name="contactType"
                        onChange={formik.handleChange}
                        label={<Trans>CUSTOMER_DETAILS.FIELDS.CONTACTS.TYPE</Trans>}
                    >
                        {ContactTypes.map(tp => <MenuItem key={tp.value} value={tp.value}><Trans>CONTACT_TYPE.{tp.value.toString()}</Trans></MenuItem> )}
                    </Select>
                    <FormHelperText error>{formik.errors.contactType}</FormHelperText>
                </FormControl>

                <TextField
                    fullWidth
                    variant="standard"
                    id="description"
                    name="description"
                    label={<Trans>CUSTOMER_DETAILS.FIELDS.CONTACTS.DESCRIPTION</Trans>}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                    InputLabelProps={{ shrink: true }}
                />
                {[ContactType.CONTATTO, ContactType.FATTURAZIONE].includes(selectedType) &&
                    <>
                        <FormGroup title="Anagrafica" className="form-group-horizontal">
                            <TextField
                                variant="standard"
                                id="name"
                                name="name"
                                label={<Trans>CUSTOMER_DETAILS.FIELDS.CONTACTS.NAME</Trans>}
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                variant="standard"
                                id="surname"
                                name="surname"
                                label={<Trans>CUSTOMER_DETAILS.FIELDS.CONTACTS.SURNAME</Trans>}
                                value={formik.values.surname}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.surname && Boolean(formik.errors.surname)}
                                helperText={formik.touched.surname && formik.errors.surname}
                                InputLabelProps={{shrink: true}}
                            />
                        </FormGroup>
                        <FormGroup title="Contatti" className="form-group-horizontal">
                            <TextField
                                variant="standard"
                                type="email"
                                id="email"
                                name="email"
                                label={<Trans>CUSTOMER_DETAILS.FIELDS.CONTACTS.EMAIL</Trans>}
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                variant="standard"
                                id="phoneNumber"
                                name="phoneNumber"
                                label={<Trans>CUSTOMER_DETAILS.FIELDS.CONTACTS.TELEPHONE</Trans>}
                                value={formik.values.phoneNumber}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                variant="standard"
                                id="fax"
                                name="fax"
                                label={<Trans>CUSTOMER_DETAILS.FIELDS.CONTACTS.FAX</Trans>}
                                value={formik.values.fax}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.fax && Boolean(formik.errors.fax)}
                                helperText={formik.touched.fax && formik.errors.fax}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormGroup>
                    </>
                }

                {[ContactType.NOTIFY].includes(selectedType) &&
                    <FormGroup title="Notifiche" className="form-group-horizontal">
                        <TextField
                            variant="standard"
                            type="email"
                            id="email"
                            name="email"
                            style={{width: '300px'}}
                            label={<Trans>CUSTOMER_DETAILS.FIELDS.CONTACTS.EMAIL</Trans>}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            InputLabelProps={{shrink: true}}
                        />
                    </FormGroup>
                }

                {![ContactType.NOTIFY].includes(selectedType) &&
                    <FormGroup title="Indirizzo" className="form-group-horizontal">
                        <Grid container columns={3} spacing={2}>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    id="street"
                                    name="street"
                                    label={<Trans>CUSTOMER_DETAILS.FIELDS.CONTACTS.STREET</Trans>}
                                    value={formik.values.street}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.street && Boolean(formik.errors.street)}
                                    helperText={formik.touched.street && formik.errors.street}
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    id="city"
                                    name="city"
                                    label={<Trans>CUSTOMER_DETAILS.FIELDS.CONTACTS.CITY</Trans>}
                                    value={formik.values.city}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.city && Boolean(formik.errors.city)}
                                    helperText={formik.touched.city && formik.errors.city}
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    id="zipCode"
                                    name="zipCode"
                                    label={<Trans>CUSTOMER_DETAILS.FIELDS.CONTACTS.ZIP</Trans>}
                                    value={formik.values.zipCode}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                                    helperText={formik.touched.zipCode && formik.errors.zipCode}
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    id="region"
                                    name="region"
                                    label={<Trans>CUSTOMER_DETAILS.FIELDS.CONTACTS.REGION</Trans>}
                                    value={formik.values.region}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.region && Boolean(formik.errors.region)}
                                    helperText={formik.touched.region && formik.errors.region}
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    id="country"
                                    name="country"
                                    label={<Trans>CUSTOMER_DETAILS.FIELDS.CONTACTS.COUNTRY</Trans>}
                                    value={formik.values.country}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.country && Boolean(formik.errors.country)}
                                    helperText={formik.touched.country && formik.errors.country}
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                        </Grid>
                    </FormGroup>
                }
            </div>
            <DialogActions>
                <Button color="error" onClick={props.onClose}><Trans>COMMON.CANCEL</Trans></Button>
                <Button type="submit"><Trans>COMMON.SAVE</Trans></Button>
            </DialogActions>
        </DialogContent>
    </Dialog>
}

export default ContactDetail;