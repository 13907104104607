export enum SERVICE_FREQUENCY {
    DAILY = "DAILY",
    WEEKLY = "WEEKLY",
    MONTHLY = "MONTHLY",
    MANUAL = "MANUAL"
}
export class ServiceConfiguration {
    serviceConfigId: number;
    serviceId: number;
    serviceConfigName: string;
    batchParamsValues: BatchParamsValue[];
    frequency: SERVICE_FREQUENCY;

    constructor() {
        this.serviceId = null;
        this.serviceConfigName = '';
        this.batchParamsValues = [];
        this.frequency = SERVICE_FREQUENCY.WEEKLY;
    }
}

export class BatchParam {
    batchId: number;
    paramName: string;
    valueType: string;
    defaultValue: string;
    isMandatory: boolean;
    isEnable: boolean;
    paramValue?: string;
    paramDescription: string;
}

export class BatchParamsValue {
    batchId: number;
    paramName: string;
    paramValue: string;

    constructor(batchId: number, paramName: string, paramValue: string) {
        this.batchId = batchId;
        this.paramValue = paramValue;
        this.paramName = paramName;
    }
}

export class Batch {
    batchId: number;
    batchName: string;
    batchParamList: BatchParam[];
    batchDescription: string;
}