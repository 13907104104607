import {AxiosResponse} from "axios";
import SuspenseComponent from "../../Layout/SuspenseComponent";
import {Invoice, InvoiceItem} from "../core/Invoice";
import {DOWNLOAD_FORMAT, useDownload, useInvoice} from "../hook/useInvoices";
import PageTitle from "../../SkinnableComponents/PageTitle/PageTitle";
import {calculateTotalInvoiceAmount} from "../core/utils/InvoiceUtils";
import InvoiceItemsGrid from "../components/InvoiceItemsGrid";
import InvoiceHeader from "../components/InvoiceHeader";
import {useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import {Button, Card, CardContent, CardHeader} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Trans} from "react-i18next";
import DownloadIcon from '@mui/icons-material/Download';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import InvoiceItemDetail from "../components/InvoiceItemDetail";
import {useSelection} from "../../../core/hook/useSelection";
import AddIcon from "@mui/icons-material/Add";

function InvoiceDetail() {
    let { invoiceId } = useParams();

    const mapInvoice = (res: AxiosResponse<Invoice>): Invoice => {
        return {
            ...res.data,
            invoiceTotal: calculateTotalInvoiceAmount(res.data)
        };
    }

    const selectionHook = useSelection<InvoiceItem>();
    const invoicesHook = useInvoice(+invoiceId, mapInvoice);
    const downloadHook = useDownload();


    // TODO - Fix style after render
    return <SuspenseComponent isLoading={invoicesHook.isLoading}>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '.5rem'}}>
            <PageTitle title="INVOICE_DETAIL.TITLE" enableBack>
                 <span style={{display: 'flex', gap: '.7rem'}}>
                    <Button
                        aria-label="approve"
                        className="action-button"
                        onClick={() => downloadHook.download(+invoiceId, DOWNLOAD_FORMAT.XML)}
                    >
                        <DownloadIcon></DownloadIcon>
                        <Typography>
                            <Trans>COMMON.DOWNLOAD_XML</Trans>
                        </Typography>
                    </Button>
                    <Button
                        className="action-button"
                        aria-label="download"
                        onClick={() => downloadHook.download(+invoiceId, DOWNLOAD_FORMAT.PDF)}
                    >
                        <FindInPageIcon></FindInPageIcon>
                        <Typography>
                            <Trans>COMMON.DOWNLOAD_PDF</Trans>
                        </Typography>
                    </Button>
                </span>
            </PageTitle>
            <InvoiceHeader invoice={invoicesHook.invoice}></InvoiceHeader>
        </Box>
        <Box sx={{height: ".5rem"}}></Box>
        <Card>
            <CardHeader title={<div className="section-header">
                <Typography variant="h6" component="div">
                    <Trans>INVOICE_DETAIL.INVOICE_ITEM_LIST_TITLE</Trans>
                </Typography>
                <Button
                    aria-label="add"
                    onClick={_ => selectionHook.setSelected(new InvoiceItem())}
                    disabled
                >
                    <AddIcon />
                    <Typography>
                        <Trans>COMMON.ADD</Trans>
                    </Typography>
                </Button>
            </div>}>
            </CardHeader>
            <CardContent>

                <InvoiceItemsGrid invoiceItems={invoicesHook.invoice.invoiceItems.filter(r => r)} />
            </CardContent>
        </Card>
        {selectionHook.selected &&
            <InvoiceItemDetail data={selectionHook.selected} open={selectionHook.isSelected}
                               onClose={selectionHook.clear} invoiceHook={invoicesHook}/>
        }
    </SuspenseComponent>

}

export default InvoiceDetail;