import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import dayjs from "dayjs";
import 'dayjs/locale/it';
import moment from 'moment';
import 'moment/locale/it';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

dayjs.locale('it');
moment.locale('it');

root.render(
      <BrowserRouter>
          <App />
      </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
