import axios from "axios";
import {CUSTOM_HEADER} from "./CUSTOM_HEADER";
import {mapErrorMessage} from "./ErrorMessage";

let axiosInstance = axios.create({
  baseURL:
    process.env.REACT_APP_API_URL === ""
      ? window?.location?.origin
        ? window.location.origin
        : "/"
      : process.env.REACT_APP_API_URL,
});

export const axiosAll = (requests, callbackFunction, parameters) => {
  return axios.all(requests).then(
    axios.spread((...responses) => {
      callbackFunction(responses, parameters);
    })
  );
};

export const configAxios = (loadingHook, errorsHook) => {
  configInterceptor(loadingHook, errorsHook);
};

let requestNumber = 0;
const configInterceptor = (loadingHook, errorsHook) => {

  axiosInstance.interceptors.request.use((req) => {
    const oktaToken = localStorage.getItem("okta-token-storage");

    if (oktaToken) {
      const token = JSON.parse(oktaToken);
      if (token && token.accessToken) {
        req.headers.Authorization = "Bearer " + token.accessToken.accessToken;
      }
    }

    if (req.headers[CUSTOM_HEADER.ENABLE_LOADING]) {
      requestNumber++;
      loadingHook.startLoading();
    }

    return req;
  });

  axiosInstance.interceptors.response.use(
    (res) => {

      if (requestNumber > 0) requestNumber--;

      if (requestNumber === 0) loadingHook.endLoading();


      if ((res.data.status === "KO"  || res.data.status === "WARNING") && isErrorHandlerEnabled(res)) {
        errorsHook.addError(mapErrorMessage(res.data));
        if (isContinueOnErrorEnabled(res)  || res.data.status === "WARNING") {
          return res;
        }

        throw res;
      }

      const SUCCESS_CODES = [200, 201];

      if (!SUCCESS_CODES.includes(res.status) && isErrorHandlerEnabled(res)) {
        errorsHook.addError(mapErrorMessage({
          errors: [
            {
              code: res.status,
              description: `${res.data.status} ${res.data?.error ? ' - ' + res.data?.error : ''}`,
            },
          ],
        }));
      }

      return res;
    },
  (err) => {
      // TODO - commentare in fase di rilascio
      if (err.response.status === 500 || err.response.status === 405) {
        if (requestNumber > 0) requestNumber--;

        if (requestNumber === 0) loadingHook.endLoading();
        return err.response;
      }

    if (isErrorHandlerEnabled(err)) {

        errorsHook.addError(
            mapErrorMessage({
              errors: [
                {
                  code: err.response?.status,
                  description: `${err.response?.statusText}${err.response?.data?.message ? ' - ' + err.response?.data?.message : ''}`,
                },
              ],
            })
          )
      }
      requestNumber--;

      if (requestNumber === 0) loadingHook.endLoading();

      if (!isContinueOnErrorEnabled(err)) {
        throw err;
      }

      return err.response;
    }
  );
};


const isErrorHandlerEnabled = (res) => {
  return !res.config?.headers[CUSTOM_HEADER.DISABLE_ERROR_HANDLER];
};

const isContinueOnErrorEnabled = (res) => {
  return res.config?.headers[CUSTOM_HEADER.CONTINUE_ON_ERROR];
};

axiosInstance.defaults.headers.post["Content-Type"] =
  "application/json; charset=utf-8;";
axiosInstance.defaults.headers.post["Accept"] =
  "application/json; charset=utf-8;";

export default axiosInstance;
