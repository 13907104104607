import React from "react";
import {useAxios} from "../../../core/axiosHook";
import {Customer, CustomerAnagDetails} from "../../../model/Customer";
import {AxiosResponse} from "axios";
import {Invoice} from "../../Invoices/core/Invoice";

export function useCustomers(url: string, callback: (res:AxiosResponse<CustomerAnagDetails[]>) => CustomerAnagDetails[]) {
    const [customers, setCustomers] = React.useState<CustomerAnagDetails[]>([]);
    const {isLoading, request} = useAxios();

    React.useEffect(() => {
        setCustomers([]);
        request({ method: "GET", url:`${process.env.REACT_APP_BE_INGRESS}/customers${url}` }).then(res => {
            setCustomers(callback(res));
        });
    }, [url]);

    return {customers, isLoading};
}

export function useCustomersList(callback: (res:AxiosResponse<CustomerAnagDetails[]>) => CustomerAnagDetails[]) {
    const [customers, setCustomers] = React.useState<CustomerAnagDetails[]>([]);
    const {isLoading, request} = useAxios();

    const get = (url: string, _callback: (res:AxiosResponse<CustomerAnagDetails[]>) => CustomerAnagDetails[] = callback) => {
        setCustomers([]);
        request({ method: "GET", url:`${process.env.REACT_APP_BE_INGRESS}/customers${url}` }, res => {
            setCustomers(callback(res));
        }).then();
    }

    return {customers, get, isLoading}
}

export function useCustomer<T>(url: string, id: number, callback: (res:AxiosResponse<T>) => Customer, extraPath: string = '') {
    const [customer, setCustomer] = React.useState<Customer>(new Customer());
    const {isLoading, setIsLoading, request, setReload, reload} = useAxios();

    React.useEffect(() => {
        if (id) {
            request({method: "GET", url: `${process.env.REACT_APP_BE_INGRESS}${url}${id}${extraPath}`}).then(res => {
                setCustomer(callback(res));
            }).catch(err => {
                console.error(err);
                setCustomer(new Customer());
            });
        } else {
            setIsLoading(false);
        }
    }, [url, reload]);

    const saveCustomer = (values: CustomerAnagDetails, isUpdate: boolean,
                          callback: (res:AxiosResponse<CustomerAnagDetails>) => CustomerAnagDetails) => {
        request({ method: isUpdate ? "PUT" : "POST",
            url:`${process.env.REACT_APP_BE_INGRESS}/customers` + (isUpdate ? `/${values.customerId}` : ''), data: values }).then(res => {
            setCustomer({details: callback(res)});
        });
    }

    return {customer, isLoading, saveCustomer, setReload};
}


export function useCustomerInvoices() {
    const [invoices, setInvoices] = React.useState<Invoice[]>([]);
    const {isLoading, request} = useAxios();

    const get = (customerId: number) => {
        return request({ method: "GET", url:`${process.env.REACT_APP_BE_INGRESS}/invoices?customerId=${customerId}` }).then(res => {
            setInvoices(res.data);
        });
    }

    return {invoices, get, isLoading};
}