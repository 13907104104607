import React from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormGroup,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {ModalComponentProps} from "./CustomerComponentProps";
import {Trans, useTranslation} from "react-i18next"
import {useCustomerSubs} from "../hook/useSubs";
import {DataGridPro, GridRowSelectionModel} from "@mui/x-data-grid-pro";
import {GridColDef} from "@mui/x-data-grid";
import {BILLING_TYPE, Rate, Subscription, SubscriptionType, ThresholdsT} from "../../../model/Customer";
import {useServiceRates, useServices} from "../../Services/hook/useServices";
import {useFormik} from "formik";
import Box from "@mui/material/Box";
import {SubAssignValidation} from "../core/validation/SubAssignValidation";
import {BillingTypes, ServiceStatuses, SubscriptionTypes} from "../core/DataSources";
import {currencyFormatter} from "../../../core/utils";
import CurrencyInput from "react-currency-input-field";
import './InputCurrencyMui.scss';


function SubAssignModal(props: ModalComponentProps<Subscription>) {
    const {t} = useTranslation();
    const [selectedSub, setSelectedSub] = React.useState<Rate>(null);
    const [selectedThresholds, setSelectedThresholds] = React.useState<number>(null);

    const columns: GridColDef[] = [
        { field: 'name', headerName: t("CUSTOMER_DETAILS.FIELDS.SUBS.NAME")},
        { field: 'description', headerName: t("CUSTOMER_DETAILS.FIELDS.SUBS.DESCRIPTION"), flex:1},
        { field: 'expirationDate', headerName: t("CUSTOMER_DETAILS.FIELDS.SUBS.EXPIRATION")},
    ]

    const thresholdColumns: GridColDef<ThresholdsT>[] = [
        { field: 'value', headerName: t("SERVICE_DETAILS.FIELDS.RATES.THRESHOLD.VALUE")},
        { field: 'unitCost', headerName: t("SERVICE_DETAILS.FIELDS.RATES.THRESHOLD.UNIT"), valueGetter: ({value}) => currencyFormatter(value)},
        { field: 'flatCost', headerName: t("SERVICE_DETAILS.FIELDS.RATES.THRESHOLD.FLAT"), valueGetter: ({value}) => currencyFormatter(value)}
    ]

    const onRowSelection = (row: GridRowSelectionModel) => {
        const rate = ratesHook.rates.filter(r => r.rateId === +row);
        setSelectedSub(rate.length ? rate[0] : null);
    }

    const onThresholdsSelection = (row: GridRowSelectionModel) => {
        setSelectedThresholds(+row);
    }

    const mapServices = React.useCallback((res) => {
        return res.data;
    }, []);

    const handleClose = () => {
        formik.resetForm();
        props.onClose();
    }

    const enabledHook = useCustomerSubs();
    const serviceHook = useServices(mapServices);
    const ratesHook = useServiceRates();

    const formik = useFormik({
        initialValues: props.data || new Subscription(props.data?.customer),
        enableReinitialize: true,
        validationSchema: SubAssignValidation(t),
        onSubmit: (values) => {
            if (selectedSub && selectedThresholds) {
                const sub: Subscription = {
                    ...props.data,
                    ...values,
                    service: {
                        serviceId: values.service?.serviceId !== -1 ? values.service.serviceId : null
                    },
                    customer: {
                        customerId: props.customerId
                    },
                    rate: {
                        rateId: selectedSub.rateId
                    },
                    thresholds: [{
                        thresholdId: selectedThresholds
                    }]
                }
                enabledHook.subscribe(sub).then(_ => {
                    handleClose();
                });
            }

            return;
        }
    });

    const onContractCodeChange = (e) => {
        formik.setValues(prevState => ({
            ...prevState,
            contractName: `${e.target.value}-${props.data?.customer?.customerCode}`,
            contractCode: e.target.value
        }))
    }

    const updateData = React.useCallback(() => {
        ratesHook.get(formik.values.service?.serviceId, props.data?.customer?.customerType);
        enabledHook.get(props.customerId, formik.values.service?.serviceId)
    }, [formik.values.service, props.customerId])


    React.useEffect(() => {
        updateData();
    }, [updateData])

    React.useEffect(() => {
        setSelectedSub(props.data?.rate);
        setSelectedThresholds(props.data?.thresholds?.length ? props.data?.thresholds[0].thresholdId: null);
    }, [enabledHook.subs])

    return  <Dialog
        fullScreen
        open={props.open}
        onClose={() => {return}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        scroll={"paper"}
        PaperProps={{
            component: 'form',
            onSubmit: formik.handleSubmit
        }}
    >
        <DialogTitle className="section-header">
            <Trans>CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.TITLE</Trans>
            <div>
                <Button color="error" onClick={handleClose}><Trans>COMMON.CANCEL</Trans></Button>
                <Button type="button" onClick={formik.submitForm}><Trans>COMMON.SAVE</Trans></Button>
            </div>
        </DialogTitle>
        <DialogContent>
            { enabledHook.subs && serviceHook.services && <>
            <Box sx={{ display: 'grid',
                gap: 1,
                height: '100%',
                gridTemplateColumns: 'repeat(6, 1fr)',
                gridTemplateRows: '0fr 0fr 0fr 0fr 0fr 1fr' }}>
                <Box gridColumn={"1/6"}>
                    <FormGroup>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="serviceLabel" error={Boolean(formik.errors.service?.serviceId)}>
                            <Trans>SERVICE_DETAILS.FIELDS.NAME</Trans>
                        </InputLabel>
                        <Select
                            labelId="serviceLabel"
                            id="service.serviceId"
                            value={formik.values?.service?.serviceId || -1}
                            defaultValue={-1}
                            onChange={formik.handleChange}
                            name="service.serviceId"
                            label={<Trans>SERVICE_DETAILS.FIELDS.NAME</Trans>}
                        >
                            <MenuItem defaultChecked value={-1}><Trans>SERVICE_DETAILS.NO_SERVICE</Trans></MenuItem>
                            {serviceHook.services.map(s => <MenuItem key={s.serviceId} value={s.serviceId}>{s.description}</MenuItem> )}
                        </Select>
                        <FormHelperText error>{formik.errors.service?.serviceId}</FormHelperText>
                    </FormControl>
                </FormGroup>
                </Box>
                <Box>
                    <FormGroup>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="subscriptionTypeLabel" error={Boolean(formik.errors.subscriptionType)}>
                                <Trans>CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.SUBSCRIPTION_TYPE</Trans>
                            </InputLabel>
                            <Select
                                labelId="subscriptionTypeLabel"
                                id="subscriptionType"
                                value={formik.values?.subscriptionType || SubscriptionType.FLAT}
                                defaultValue={SubscriptionType.FLAT}
                                onChange={formik.handleChange}
                                name="subscriptionType"
                                label={<Trans>CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.SUBSCRIPTION_TYPE</Trans>}
                            >
                                {SubscriptionTypes.map(s => <MenuItem defaultChecked={s === SubscriptionType.FLAT} key={s} value={s}>{s}</MenuItem> )}
                            </Select>
                            <FormHelperText error>{formik.errors.subscriptionType}</FormHelperText>
                        </FormControl>
                    </FormGroup>
                </Box>
                <Box>
                    <TextField
                        fullWidth
                        variant="standard"
                        id="startDate"
                        name="startDate"
                        type="date"
                        label={<Trans>CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.START_DATE</Trans>}
                        value={formik.values.startDate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                        helperText={formik.touched.startDate && formik.errors.startDate}
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box>
                    <TextField
                        fullWidth
                        variant="standard"
                        id="endDate"
                        name="endDate"
                        type="date"
                        label={<Trans>CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.END_DATE</Trans>}
                        value={formik.values.endDate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                        helperText={formik.touched.endDate && formik.errors.endDate}
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box>
                    <TextField
                        fullWidth
                        variant="standard"
                        id="trialStartDate"
                        name="trialStartDate"
                        type="date"
                        label={<Trans>CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.TRIAL_START</Trans>}
                        value={formik.values.trialStartDate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.trialStartDate && Boolean(formik.errors.trialStartDate)}
                        helperText={formik.touched.trialStartDate && formik.errors.trialStartDate}
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box>
                    <TextField
                        fullWidth
                        variant="standard"
                        id="trialEndDate"
                        name="trialEndDate"
                        type="date"
                        label={<Trans>CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.TRIAL_END</Trans>}
                        value={formik.values.trialEndDate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.trialEndDate && Boolean(formik.errors.trialEndDate)}
                        helperText={formik.touched.trialEndDate && formik.errors.trialEndDate}
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
              {/*  <Box></Box>
                <Box></Box>*/}
                <Box>
                    <TextField
                        fullWidth
                        variant="standard"
                        id="billingFrequency"
                        name="billingFrequency"
                        type="number"
                        label={<Trans>CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.BILLING_FREQUENCY</Trans>}
                        value={formik.values.billingFrequency}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.billingFrequency && Boolean(formik.errors.billingFrequency)}
                        helperText={formik.touched.billingFrequency && formik.errors.billingFrequency}
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="billingTypeLabel" error={Boolean(formik.errors.billingType)}>
                            <Trans>CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.BILLING_TYPE</Trans>
                        </InputLabel>
                        <Select
                            labelId="billingTypeLabel"
                            id="billingType"
                            value={formik.values?.billingType || BILLING_TYPE.ANTICIPATO}
                            defaultValue={BILLING_TYPE.ANTICIPATO}
                            onChange={formik.handleChange}
                            name="billingType"
                            label={<Trans>CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.BILLING_TYPE</Trans>}
                        >
                            {BillingTypes.map(t => <MenuItem key={t} value={t}><Trans>BILLING_TYPES.{t.toString()}</Trans></MenuItem> )}
                        </Select>
                        <FormHelperText error>{formik.errors.billingType}</FormHelperText>
                    </FormControl>
                </Box>
                <Box>
                    <TextField
                        fullWidth
                        variant="standard"
                        id="rateFrequency"
                        name="rateFrequency"
                        type="number"
                        label={<Trans>CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.RATE_FREQUENCY</Trans>}
                        value={formik.values.rateFrequency}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.rateFrequency && Boolean(formik.errors.rateFrequency)}
                        helperText={formik.touched.rateFrequency && formik.errors.rateFrequency}
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box>
                    <FormControl variant="standard" fullWidth
                                 className="input-currency">
                        <InputLabel id="serviceFrequencyChargeLabel" error={Boolean(formik.errors.serviceFrequencyCharge)}>
                            <Trans>CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.SERVICE_FREQUENCY_CHARGE</Trans>
                        </InputLabel>
                        <div>
                            <CurrencyInput
                                //variant="standard"
                                id="serviceFrequencyCharge"
                                name="serviceFrequencyCharge"
                                //type="number"
                                //label={<Trans>CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.SERVICE_FREQUENCY_CHARGE</Trans>}
                                value={formik.values.serviceFrequencyCharge}
                                onValueChange={value => value ? formik.handleChange(value) : 0}
                                decimalsLimit={2}
                                allowNegativeValue={false}
                                defaultValue={0}
                                prefix="€"
                                decimalSeparator=","
                                groupSeparator="."
                                onBlur={formik.handleBlur}
                                //error={formik.touched.serviceFrequencyCharge && Boolean(formik.errors.serviceFrequencyCharge)}
                                //helperText={formik.touched.serviceFrequencyCharge && formik.errors.serviceFrequencyCharge}
                                //InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <FormHelperText error>{formik.errors.serviceFrequencyCharge}</FormHelperText>
                    </FormControl>
                </Box>
                {/*<Box>
                    <TextField
                        fullWidth
                        variant="standard"
                        id="extraCharge"
                        name="extraCharge"
                        type="number"
                        label={<Trans>CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.EXTRA_CHARGE</Trans>}
                        value={formik.values.extraCharge}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.extraCharge && Boolean(formik.errors.extraCharge)}
                        helperText={formik.touched.extraCharge && formik.errors.extraCharge}
                        InputLabelProps={{ shrink: true }}

                </Box>/>*/}
                {/*<Box>
                    <TextField
                        fullWidth
                        variant="standard"
                        id="discount"
                        name="discount"
                        type="number"
                        label={<Trans>CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.DISCOUNT</Trans>}
                        value={formik.values.discount}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.discount && Boolean(formik.errors.discount)}
                        helperText={formik.touched.discount && formik.errors.discount}
                        InputLabelProps={{ shrink: true }}

                </Box>/>*/}
                <Box>
                    <TextField
                        fullWidth
                        variant="standard"
                        id="contractCode"
                        name="contractCode"
                        label={<Trans>CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.CONTRACT_CODE</Trans>}
                        value={formik.values.contractCode}
                        onChange={onContractCodeChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.contractCode && Boolean(formik.errors.contractCode)}
                        helperText={formik.touched.contractCode && formik.errors.contractCode}
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box>
                    <TextField
                        fullWidth
                        disabled
                        variant="standard"
                        id="contractName"
                        name="contractName"
                        label={<Trans>CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.CONTRACT_NAME</Trans>}
                        value={formik.values.contractName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.contractName && Boolean(formik.errors.contractName)}
                        helperText={formik.touched.contractName && formik.errors.contractName}
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box>
                    <TextField
                        fullWidth
                        variant="standard"
                        id="contractDate"
                        name="contractDate"
                        type="date"
                        label={<Trans>CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.CONTRACT_DATE</Trans>}
                        value={formik.values.contractDate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.contractDate && Boolean(formik.errors.contractDate)}
                        helperText={formik.touched.contractDate && formik.errors.contractDate}
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="serviceActivationLabel" error={Boolean(formik.errors.serviceActivation?.serviceActivationStatus)}>
                            <Trans>CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.SERVICE_STATUS</Trans>
                        </InputLabel>
                        <Select
                            labelId="serviceActivationLabel"
                            id="serviceActivation.serviceActivationStatus"
                            value={formik.values?.serviceActivation?.serviceActivationStatus}
                            defaultValue={''}
                            onChange={formik.handleChange}
                            name="serviceActivation.serviceActivationStatus"
                            label={<Trans>CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.SERVICE_STATUS</Trans>}
                        >
                            <MenuItem defaultChecked value={-1}><Trans>CUSTOMER_DETAILS.FIELDS.SUBS.ASSIGN.NO_STATUS</Trans></MenuItem>
                            {ServiceStatuses.map(s => <MenuItem key={s} value={s}><Trans>SERVICE_STATUS.{s}</Trans></MenuItem> )}
                        </Select>
                        <FormHelperText error>{formik.errors.service?.serviceId}</FormHelperText>
                    </FormControl>
                </Box>

                <Box gridColumn={"1/7"}>
                    {ratesHook.rates.length ? <>
                        {selectedSub && selectedThresholds
                            ? <></>
                            : <FormHelperText
                                error><>{t("COMMON.IS_SELECTED")} {t("SERVICE_DETAILS.FIELDS.RATES.THRESHOLD.VALUE")}</>
                            </FormHelperText>
                        }
                    <Box sx={{ display: 'grid',
                        gap: 1,
                        height: '100%',
                        gridTemplateColumns: 'repeat(2, 1fr)'}}>

                            <DataGridPro
                                disableMultipleRowSelection={true}
                                onRowSelectionModelChange={onRowSelection}
                                rowSelectionModel={selectedSub?.rateId}
                                // loading={ratesHook.isLoading && formik.values.service?.serviceId === -1}
                                getRowId={(row: Rate) => row.rateId}
                                rows={ratesHook.rates}
                                columns={columns}
                                hideFooter
                            />
                            <DataGridPro
                                disableMultipleRowSelection={true}
                                onRowSelectionModelChange={onThresholdsSelection}
                                rowSelectionModel={[selectedThresholds]}
                                getRowId={(row: ThresholdsT) => row.thresholdId}
                                rows={selectedSub?.thresholds || []}
                                columns={thresholdColumns}
                                hideFooter
                            />
                        </Box>
                    </> : <></>
                    }
                </Box>
            </Box>
            </>}
        </DialogContent>
    </Dialog>
}

export default SubAssignModal;