import * as React from 'react';
import {GridColDef, GridDensity} from '@mui/x-data-grid';
import {DataGridPro} from "@mui/x-data-grid-pro";
import {RowDefinitionDetail} from "../core/model/MonitoringRowDefinition";
import {CustomerMonitoringAnagDetails} from "../../../model/Customer";

interface AnalysGridProps {
    data: CustomerMonitoringAnagDetails;
    rows: RowDefinitionDetail[];
    columns: GridColDef[];
}
function AnalysGrid(props: AnalysGridProps) {
    const [density] = React.useState<GridDensity>('compact');

    return (
        <div style={{width: '100%'}}>
            <DataGridPro
                columns={props.columns}
                initialState={{
                    pinnedColumns: {
                        left: ['label'],
                    },
                }}
                rows={props.rows}
                getRowId={(row) => row.id}
                getRowClassName={(params) => params.row.classes}
                columnBuffer={2}
                density={density}
                hideFooter
            />
        </div>
    );
}

export default AnalysGrid;