import React from "react";
import {GridCellParams, GridColDef, GridDensity, GridToolbarExport} from "@mui/x-data-grid";
import {DataGridPro, GridToolbarContainer} from "@mui/x-data-grid-pro";
import {CustomerMonitoringAnagDetails} from "../../../model/Customer";
import moment, {Moment} from "moment";
import useModalState from "../hook/UseModalState";
import DialogDetailGrid from "./DialogDetailGrid";
import dayjs from "dayjs";
import {RowDefinition} from "../core/model/MonitoringRowDefinition";
import Das01MovementsDetailDialog from "./Das01MovementsDetailDialog";

interface CustomerGridProps {
    data: CustomerMonitoringAnagDetails;
    rows: RowDefinition[];
    columns: GridColDef[];
    customer: string;
    startDate: Moment;
    endDate: Moment;
    disableToolbar?: boolean;
}

function MonitoringGrid(props: CustomerGridProps) {
    const modalState = useModalState();
    const movementDetailsModalState = useModalState();
    const customerCode = props.customer;
    const startDate = moment(props.startDate).format('DDMMYYYY');
    const endDate = moment(props.endDate).format('DDMMYYYY');
    const [density] = React.useState<GridDensity>('compact');
    const [selectedCell, setSelectedCell] = React.useState<GridCellParams>();

    function MyCustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    className='Mui'
                    csvOptions={{
                        delimiter: ';',
                        utf8WithBom: true,
                        fileName: customerCode + '_' + startDate + '_' + endDate
                    }}
                    printOptions={{disableToolbarButton: true}}
                />
            </GridToolbarContainer>
        );
    }

    function handleEvent(params: GridCellParams) {
        if (params.id === 'cmr') {
            if (dayjs(params.field).isValid()) {
                setSelectedCell(params);
                modalState.toggle();
            }
        }

        if (params.id === "titleMatchingRates") {
            if (dayjs(params.field).isValid() && params.value !== '') {
                setSelectedCell(params);
                movementDetailsModalState.toggle();
            }
        }
    }

    return (
        <div style={{width: '100%'}}>
            <DataGridPro
                columns={props.columns}
                initialState={{
                    pinnedColumns: {
                        left: ['label'],
                    },
                }}
                rows={props.rows}
                getRowId={(row) => row.id}
                getRowClassName={(params) => 'monitoring-row ' + params.row.classes}
                onCellClick={handleEvent}
                columnBuffer={2}
                slots={{toolbar: props.disableToolbar ? null : MyCustomToolbar}}
                density={density}
                hideFooter
            />
            <DialogDetailGrid
                data={selectedCell}
                companyIds={props.data.tppCompanyId}
                open={modalState.open}
                onClose={() => modalState.toggle()}
            />
            <Das01MovementsDetailDialog
                data={selectedCell}
                companyId={props.data.tppCompanyId}
                open={movementDetailsModalState.open}
                onClose={() => movementDetailsModalState.toggle()}
            />
        </div>
    );
}

export default MonitoringGrid;