export default class ErrorMessage {
  code: string;
  description: string;
  visible: boolean = true;
  autoClose?: boolean = true;
  type?: string;

  constructor(
    code: string,
    description: string,
    type = "KO",
    autoClose: boolean = true
  ) {
    this.code = code;
    this.description = description;
    this.autoClose = autoClose;
    this.visible = true;
    this.type = type;
  }
}

export const mapErrorMessage = (
  payload: any,
  type: string = "KO",
  autoClose: boolean = true
): ErrorMessage[] => {
  const errors = (typeof payload.errors === "string")
    ? [payload.errors]
    : payload.errors;
  if (errors && errors.length > 0) {
    return errors.map((error: any) => {
      if (error.code) {
        return new ErrorMessage(error.code, error.description, error.type ? error.type: type, autoClose);
      } else if (error.description) {
        return new ErrorMessage("500", error.description,error.type ? error.type: type, autoClose);
      }
      return new ErrorMessage("500", error, error.type ? error.type: type, autoClose);
    });
  }

  return [];
};
