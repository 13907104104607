import React from 'react';
import ErrorMessage from "./ErrorMessage";

export function useIsLoading() {
    const [isLoading, setIsLoading] = React.useState(false);

    const startLoading = () => {
        setIsLoading(true);
    }

    const endLoading = () => {
        setIsLoading(false);
    }

    return {isLoading, startLoading, endLoading};
}


export function useErrors() {
    const [errors, setErrors] = React.useState<ErrorMessage[]>([]);

    const addError = (ex: ErrorMessage[]) => {
        setErrors([...errors, ...ex]);
    }

    const hide = (index: number) => {
        errors[index].visible = false;
        setErrors([...errors]);
    }

    return {errors, addError, hide};
}