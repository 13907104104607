import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import {MenuItem, menuItems} from "../../model/MenuItem";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import {Collapse, Icon, Tooltip} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import {ExitToApp, ExpandLess, ExpandMore} from "@mui/icons-material";
import {oktaLogout} from "../Login/oktaCustomConfig";
import React from "react";
import {CSSObject, styled, Theme} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import DrawerHeader from "./DrawerHeader";
import {useLocation, useNavigate} from "react-router-dom";

const drawerWidth = 275;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        height: '100%',
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


interface SidebarProps {
    open: boolean,
    theme: Theme,
    handleDrawerClose: () => void
}

function Sidebar(props: SidebarProps) {
    const location = useLocation();

    const navigate = useNavigate();
    const [items, setItems] = React.useState(menuItems);

    const isActive = (item: MenuItem) => {
        if (item.children?.length) {
            return false;
        }

        return location.pathname.indexOf('app/' + item.route) !== -1;
    }

    const itemClick = (item: MenuItem, index: number) => {
        if (item.children?.length) {
            items[index].open = !items[index].open;
            setItems([...items]);
        } else {
            navigate(item.route);
        }
    }

    return <Drawer variant="permanent" open={props.open}>
        <DrawerHeader>
            <IconButton onClick={props.handleDrawerClose}>
                {props.theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
            </IconButton>
        </DrawerHeader>
        <Divider/>
        <List style={{height: "100%"}}>
            {menuItems.map((item: MenuItem, index: number) => (
                <ListItem key={index} disablePadding sx={{display: 'block'}}>
                    <Tooltip title={item.label} placement="right">
                        <ListItemButton
                            onClick={_ => itemClick(item, index)}
                            sx={{
                                minHeight: 48,
                                justifyContent: props.open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                            selected={isActive(item)}
                        >
                            {item.icon && <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: props.open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <Icon>{item.icon}</Icon>
                            </ListItemIcon>
                            }
                            <ListItemText primary={item.label} sx={{opacity: props.open ? 1 : 0}}/>
                            {item.children?.length &&
                                (item.open ? <ExpandLess/> : <ExpandMore/>)
                            }
                        </ListItemButton>
                    </Tooltip>
                    {item.children?.length && (
                        <Collapse in={item.open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {
                                    item.children.map((sub: MenuItem, idx: number) => (
                                        <Tooltip title={sub.label} key={index + '-' + idx} placement="right">
                                            <ListItemButton sx={{pl: 4, justifyContent: props.open ? 'initial' : 'center'}}
                                                            onClick={_ => itemClick(sub, index)}
                                                            selected={isActive(sub)}
                                            >
                                                <ListItemIcon>
                                                    <Icon>{sub.icon}</Icon>
                                                </ListItemIcon>
                                                <ListItemText primary={sub.label}/>
                                            </ListItemButton>
                                        </Tooltip>
                                    ))
                                }
                            </List>
                        </Collapse>
                    )}
                </ListItem>
            ))}
            <ListItemButton
                className="nav-link"
                style={{position: "absolute", bottom: "10px"}}
                onClick={_ => oktaLogout("")}
                sx={{
                    minHeight: 48,
                    //justifyContent: props.open ? 'initial' : 'center',
                    px: 2.5,
                    width: '100%'
                }}
            >
                <ListItemIcon>
                    <ExitToApp></ExitToApp>
                </ListItemIcon>
                {props.open && <ListItemText primary="Logout"/>}
            </ListItemButton>
        </List>
    </Drawer>
}

export default Sidebar;