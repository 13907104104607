import {Button, Card, CardContent, CardMedia, Icon} from "@mui/material";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import SuspenseComponent from "../../Layout/SuspenseComponent";

const StyledCard = styled(Card)(({ theme: { breakpoints, spacing } }) => ({
    width: 165,
    maxWidth: "100%",

    // 16px
    borderRadius: spacing(2),

    transition: "0.3s",
    boxShadow: "0px 14px 80px rgba(34, 35, 58, 0.2)",
    position: "relative",
    overflow: "initial",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    paddingLeft: 8,
    paddingRight: 8,

    background:
        "linear-gradient(34deg, rgba(55,16,83,1) 0%, rgba(162,73,190,1) 29%, rgba(33,16,83,1) 92%)",

    [breakpoints.up("sm")]: {
        textAlign: "left",
        flexDirection: "row-reverse",
    },
}));

const CardMediaMedia = styled(CardMedia)(({ theme: { breakpoints } }) => ({
    flexShrink: 0,
    width: "64px",

    //paddingTop: "30%",
    marginLeft: "auto",
    marginRight: "auto",

    [breakpoints.up("sm")]: {
       // marginRight: "initial",
    },
}));

const TypographyOverline = styled(Typography)(() => ({
    lineHeight: 2,
    color: "#ffffff",
    fontWeight: "bold",
    fontSize: "0.625rem",
    opacity: 0.7,
}));

const StyledButton = styled(Button)(({ theme: { breakpoints } }) => ({
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    borderRadius: 100,
    paddingLeft: 32,
    paddingRight: 32,
    color: "#ffffff",
    textTransform: "none",
    width: "100%",

    "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.32)",
    },

    [breakpoints.up("sm")]: {
        width: "auto",
    },
}));

interface StatCardProps {
    description: string;
    value?: number;
    icon: string;
    gradient?: string;
    onClick?: () => void;
    isLoading: boolean;
    id: string;
}

export function CompactStatCard(props: StatCardProps) {

    return (
        <SuspenseComponent isLoading={props.isLoading}>
        <StyledCard style={props.gradient ? {background: props.gradient}: {}} id={props.id}>
            <CardContent>
                <TypographyOverline variant={"overline"}>
                    {props.description}
                </TypographyOverline>
                <CardMediaMedia children={<Icon style={{fontSize: '64px', color: '#ffffff'}}>{props.icon}</Icon>}/>
                <StyledButton onClick={props.onClick}>Dettagli</StyledButton>
            </CardContent>
        </StyledCard>
        </SuspenseComponent>
    );
}