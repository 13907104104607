import React from "react";
import {useParams} from "react-router-dom";
import PageTitle from "../../SkinnableComponents/PageTitle/PageTitle";
import {useSlaValues} from "../hook/useSla";
import {useBatches} from "../hook/useBatches";
import {SlaConfigurationT, SlaValue} from "../core/Sla";
import Typography from "@mui/material/Typography";
import {Button, Card, CardContent, Grid, Modal, TextField} from "@mui/material";
import {Trans} from "react-i18next";
import useModalState from "../hook/UseModalState";
import Box from "@mui/material/Box";
import {CheckCircle, Error} from "@mui/icons-material";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    alignItems: 'center',
    gap: 2
};

function SlaConfigurationDetailView() {
    const {serviceId} = useParams();

    const [slaConfiguration, setSlaConfiguration] = React.useState<SlaConfigurationT[]>([]);
    const feedbackModalHook = useModalState();

    const slaValuesHook = useSlaValues();
    const batchHook = useBatches();

    React.useEffect(() => {
        setSlaConfiguration([]);
        if (serviceId) {
            batchHook.get(+serviceId).then(res => {
                slaValuesHook.get(res.map(b => b.batchId)).then();
            });

        }
    }, [serviceId])

    React.useEffect(() => {
        const cfg: SlaConfigurationT[] = [];

        if (batchHook.batches.length) {
            batchHook.batches?.forEach(b => {
                const config = new SlaConfigurationT(b.batchId, b.batchDescription);
                slaValuesHook.slaValues?.filter(sv => sv.batchId === b.batchId)
                    .forEach(sv => {
                        const slaT = {
                            ...sv.sla,
                            slaValue: sv.slaValue || +sv.sla?.defaultValue
                        }
                        config.sla.push(slaT);
                    })

                cfg.push(config);
            });

            setSlaConfiguration([...cfg]);

        }
    }, [batchHook.batches, slaValuesHook.slaValues])

    const handleChange = (batchId: number, slaName: string, value: string) => {
        const c = slaConfiguration;
        const batch = c.find(b => b.batchId === batchId);
        const sla = batch.sla.find(s => s.name === slaName);

        sla.slaValue = +value;

        setSlaConfiguration([...c]);
    }

    const submit = (batchId: number) => {
        slaConfiguration
            .filter(c => c.batchId === batchId)
            .forEach(c=> {
                c.sla.forEach(s => {
                    const v = new SlaValue();
                    v.sla = s;
                    v.slaValue = s.slaValue;
                    v.batchId = c.batchId;

                    slaValuesHook.save(v).then(() => feedbackModalHook.toggle(true));
                });
        })

    }

    return <>
        <PageTitle title="SLA_CONFIG.TITLE" enableBack></PageTitle>
        <Grid container columns={12} spacing={2} width='100%'>
            <Modal
                open={feedbackModalHook.open}
                onClose={() => feedbackModalHook.toggle(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {feedbackModalHook.data
                        ? <>
                        <CheckCircle color="success" fontSize="large"></CheckCircle>
                        <Typography id="modal-modal-title" variant="h6" color="success.main" component="h2">
                            Salvataggio completato
                        </Typography>
                            </>
                        : <>
                            <Error color="error" fontSize="large"></Error>
                            <Typography id="modal-modal-title" variant="h6" color="error.main" component="h2">
                                Errore durante il salvataggio
                            </Typography>

                        </>
                        }
                </Box>
            </Modal>

        {slaConfiguration?.map(c => {
            return <Grid item xs={12} key={c.batchId}>
            <Card>
                <CardContent>
                    <Typography variant="h6">{c.batchDescription}</Typography>
                    <Grid container columns={6} spacing={2} alignItems='center'>
                    {c.sla?.map(s => <> <Grid item xs={4} key={c.batchId + "_" + s.name}>
                            <Typography>{s.description}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <TextField
                                type="number"
                                label={s.description}
                                value={s.slaValue}
                                onChange={e => handleChange(c.batchId, s.name, e.target.value)}></TextField>
                        </Grid>
                        <Grid item xs={1}>
                            <Button  aria-label="save" onClick={() => submit(c.batchId)} >
                                <Typography>
                                    <Trans>COMMON.SAVE</Trans>
                                </Typography>
                            </Button>
                        </Grid>
                        </>
                    )}
                    </Grid>
                </CardContent>
            </Card>
            </Grid>
        })}
        </Grid>
    </>
}

export default SlaConfigurationDetailView;