import {useParams} from "react-router-dom";
import ServiceAnag from "../components/ServiceAnag";
import ServiceRates from "../components/ServiceRates";
import PageTitle from "../../SkinnableComponents/PageTitle/PageTitle";

function ServiceDetail() {
    let { serviceId } = useParams();

    return <div style={{display: 'flex', flexDirection: 'column', gap: '.5rem'}}>
        <PageTitle title="SERVICE_DETAILS.TITLE" enableBack={true} />
        <ServiceAnag id={+serviceId} />
        <ServiceRates id={+serviceId} />

    </div>
}

export default ServiceDetail;