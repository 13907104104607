import {
    BaseBanksRatios,
    CompanyRatios,
    CustomerMonitoringAnagDetails, Summary
} from "../../../model/Customer";
import {TFunction} from "i18next";
import {Tooltip} from "@mui/material";
import dayjs from "dayjs";
import {
    MonitoringRowDefinition,
    MonitoringRowDefinitionDetail
} from "./model/MonitoringRowDefinition";
import {GridActionsCellItem, GridColDef, GridRowParams} from "@mui/x-data-grid";
import InfoIcon from "@mui/icons-material/Info";
import {ROUTES} from "../../../routes/ROUTES";
import {NavigateFunction} from "react-router-dom";
import { currencyFormatter as _currencyFormatter } from '../../../core/utils';

const numberFormatter = (val: number): number | string => !val ? '-' : val;
const detailIcon = (val: string | number) : number | string => !val ? '' : `🔍`;
const percentageFormatter = (val: number): string => `${val ? Math.ceil(val) : val}%`;
const currencyFormatter = (val: number): string => val === undefined || val === null ? '-' : `${_currencyFormatter(val)}`;

export function extractColumns(data: CustomerMonitoringAnagDetails, t: TFunction) {
    const columnsTemp: any[] = [];
    columnsTemp.push({field: 'label', headerName: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.LABEL'), width: 200,
        renderCell: (params: GridRowParams) => {
            const shouldDisplayTooltip = params.row.id.includes('_BANK') || params.row.id.includes('_ERP');
            return (
                <div>
                    {shouldDisplayTooltip ? (
                        <Tooltip title={params.row.id.replace('_BANK', '').replace('_ERP', '')} placement="right">
                            <span>{params.row.label}</span>
                        </Tooltip>
                    ) : (
                        <span>{params.row.label}</span>
                    )}
                </div>
            );
        }
    });
    data.ratios.forEach((ratio) => {
        columnsTemp.push({
            field: dayjs(ratio.registrationDate).format('YYYYMMDD'),
            headerName: dayjs(ratio.registrationDate).format('DD/MM'),
            align: 'center',
            headerAlign: 'center'
        });
    });
    return columnsTemp
}

export function setRows(data: CustomerMonitoringAnagDetails, t: TFunction) {
    const rowsDefinition = {...createRowDefinitions(t), ...createAnalysTitle(t)};
    let rowsDefinitionBank = createGroupRows('bankLable', t);
    let rowsDefinitionErp = createGroupRows('erpLable', t);
    const newRows: any[] = [];

    if (data.ratios?.length && data.banks?.length) {
        data.banks.forEach((bankData) => {
            bankData.data.forEach((bank) => {
                rowsDefinitionBank = {...rowsDefinitionBank, ...createNewBankRow(bank.iban, '_BANK', bank.bankName)};
                rowsDefinitionErp = {...rowsDefinitionErp, ...createNewBankRow(bank.iban, '_ERP', bank.bankName)};
            });
        });

        let rowFinal = {...rowsDefinition, ...rowsDefinitionBank, ...createBalanceRow('bank', t), ...rowsDefinitionErp, ...createBalanceRow('erp', t), ...createDeltaRow(t)};
        for (let rowsDefinitionKey in rowFinal) {
            const row = { ...rowFinal[rowsDefinitionKey as keyof MonitoringRowDefinition] };
            data.ratios.forEach((ratio) => {
                const key = rowsDefinitionKey as keyof CompanyRatios;
                addProp(row, `${dayjs(ratio.registrationDate).format('YYYYMMDD')}`, row.formatter ? row.formatter(ratio[key]): ratio[key])
            });
            data.banks.forEach((bankData, indexBanks) => {
                bankData.data.forEach((bank) => {
                    if (rowsDefinitionKey.includes(bank.iban)) {
                        addProp(row, `${dayjs(data.ratios[indexBanks].registrationDate).format('YYYYMMDD')}`,
                            row.formatter ?
                                row.formatter(rowsDefinitionKey.includes('BANK') ? bank.bankAmount : bank.erpAmount) :
                                rowsDefinitionKey.includes('BANK') ? bank.bankAmount : bank.erpAmount);
                    }
                })
                if (Object.keys(bankData).includes(rowsDefinitionKey)) {
                    const key = rowsDefinitionKey as keyof BaseBanksRatios;
                    addProp(row, `${dayjs(data.ratios[indexBanks].registrationDate).format('YYYYMMDD')}`,row.formatter ? row.formatter(bankData[key]): bankData[key]);
                }
            })
            newRows.push(row);
        }
    }
    return newRows;
}

export function extractColumnsDetail(data: CustomerMonitoringAnagDetails, t: TFunction) {
    const columnsTemp: GridColDef[] = [];

    columnsTemp.push({field: 'label', headerName: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.LABEL'), width: 200});
    data.ratios.forEach((ratio) => {
        columnsTemp.push({
            field: dayjs(ratio.executionDate).format('YYYYMMDD'),
            headerName: dayjs(ratio.executionDate).format('DD/MM')
        });
    });
    return columnsTemp
}

export function setRowsDetail(data: CustomerMonitoringAnagDetails, t: TFunction) {
    const rowsDefinition = createRowDefinitionsDetail(t);
    const newRows: any[] = [];

    for (let rowsDefinitionKey in rowsDefinition) {
        const key = rowsDefinitionKey as keyof MonitoringRowDefinitionDetail;
        const ratiosKey = rowsDefinitionKey as keyof CompanyRatios;
        const row = { ...rowsDefinition[key] };
        data.ratios.forEach((ratio) => {
            if (rowsDefinitionKey in ratio) {
                addProp(row, `${dayjs(ratio.executionDate).format('YYYYMMDD')}`, row.formatter ? row.formatter(ratio[ratiosKey]): ratio[ratiosKey]);
            }
        });
        newRows.push(row);
    }
    return newRows;
}

function addProp<T extends object, K extends PropertyKey, V>(
    obj: T,
    key: K,
    value: V
): asserts obj is T & { [P in K]: V } {
    Object.assign(obj, { [key]: value });
}

const createRowDefinitions  = (t: TFunction): MonitoringRowDefinition => ({
    titleMatchingRates: {id: 'titleMatchingRates', label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.TITLEMATCHINGRATES'), classes: 'title-analysis-table-color row-bold clickable', formatter: detailIcon},
    inputAisCount: {id: 'inputAisCount', label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.INPUTAISCOUNT'), classes: '', formatter: numberFormatter},
    // inputStandbyCount : {id: 'inputStandbyCount', label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.INPUTSTANDBYCOUNT'), classes: '', formatter: numberFormatter},
    inputTotalCount: {id: 'inputTotalCount', label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.INPUTTOTALCOUNT'), classes: 'row-bold', formatter: numberFormatter},
    matchedCount: {id: 'matchedCount', label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.MATCHEDCOUNT'), classes: '', formatter: numberFormatter},
    standbyCount: {id: 'standbyCount', label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.STANDBYCOUNT'), classes: '', formatter: numberFormatter},
    errorCount: {id: 'errorCount', label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.ERRORCOUNT'), classes: '', formatter: numberFormatter},
    totalCount: {id: 'totalCount', label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.TOTALCOUNT'), classes: 'row-bold', formatter: numberFormatter},
    cancelledCount: {id: 'cancelledCount', label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.CANCELLEDCOUNT'), classes: '', formatter: numberFormatter},
    approvedCount: {id: 'approvedCount', label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.APPROVEDCOUNT'), classes: '', formatter: numberFormatter},
    approvedModifiedCount: {id: 'approvedModifiedCount', label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.APPROVEDMODIFIEDCOUNT'), classes: '', formatter: numberFormatter},
    fp1: {id: 'fp1', label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.FP1'), classes: '', formatter: numberFormatter},
    mr1: {id: 'mr1',label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.MR1'), classes: '', formatter: percentageFormatter},
    sb1: {id: 'sb1',label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.SB1'), classes: '', formatter: percentageFormatter},
    fr1: {id: 'fr1',label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.FR1'), classes: '', formatter: percentageFormatter},
    cr1: {id: 'cr1',label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.CR1'), classes: '', formatter: percentageFormatter},
    cmr: {id: 'cmr',label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.CMR'), classes: 'row-colored-bold clickable underline', formatter: percentageFormatter},
    rejectCount: {id: 'rejectCount', label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.REJECTCOUNT'), classes: '', formatter: numberFormatter},
});

const createAnalysTitle = (t: TFunction) => ({
    blank: {id: 'blank', label: '', classes: ''},
    title: {id: 'title', label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.ANALYSTITLE'), classes: 'title-analysis-table-color row-bold'},
});

const createGroupRows = (labelKey: string, t: TFunction) => ({
    [labelKey]: {id: labelKey, label: t(`DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.${labelKey.toUpperCase()}`), classes: 'row-bold' }
});

const createNewBankRow = (iban: string, type: string, bankName: string) => ({
    [`${iban}${type}`]: {id: iban + type, label: `${bankName}`, classes: '', formatter: currencyFormatter }
});

const createBalanceRow = (type: string,  t: TFunction) => ({
    [`${type}Balance`]: {id: type + 'Balance', label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.BALANCE'), classes: 'row-colored-bold', formatter: currencyFormatter }
});

const createDeltaRow = (t: TFunction) => ({
    delta: {id: 'delta', label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.DELTA'), classes: '', formatter: currencyFormatter }
});

const createRowDefinitionsDetail  = (t: TFunction): MonitoringRowDefinitionDetail => ({
    titleMatchingRates: {id: 'titleMatchingRates', label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.TITLEMATCHINGRATES'), classes: 'title-analysis-table-color row-bold'},
    matchedCount: {id: 'matchedCount', label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.MATCHEDCOUNT'), classes: '', formatter: numberFormatter},
    standbyCount: {id: 'standbyCount', label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.STANDBYCOUNT'), classes: '', formatter: numberFormatter},
    errorCount: {id: 'errorCount', label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.ERRORCOUNT'), classes: '', formatter: numberFormatter},
    totalCount: {id: 'totalCount', label: t('DASHBOARD_MONITORING.DASHBOARD_RATES.FIELDS.TOTALCOUNT'), classes: 'row-bold', formatter: numberFormatter},
});

export const createSummaryColumns = (t: TFunction, navigate: NavigateFunction, date: string) => [
    {field: 'businessName', headerName: t('DASHBOARD_MONITORING.DASHBOARD_SUMMARY.FIELDS.BUSINESSNAME'), flex: 1},
    {field: 'delta', headerName: t('DASHBOARD_MONITORING.DASHBOARD_SUMMARY.FIELDS.DELTA'), flex: 1, valueFormatter: ({value}) => currencyFormatter(value)},
    {field: 'inputAisCount', headerName: t('DASHBOARD_MONITORING.DASHBOARD_SUMMARY.FIELDS.INPUTAISCOUNT'), flex: 1},
    {field: 'mr', headerName: t('DASHBOARD_MONITORING.DASHBOARD_SUMMARY.FIELDS.MR') + dayjs(date).format("D MMMM"), flex: 1, valueFormatter: ({value}) => percentageFormatter(value)},
    {field: 'cmr', headerName: t('DASHBOARD_MONITORING.DASHBOARD_SUMMARY.FIELDS.CMR'), flex: 1, valueFormatter: ({value}) => percentageFormatter(value)},
    { field: 'details', headerName: '', type: 'actions',
    getActions: (params: GridRowParams<Summary>) => [
        <GridActionsCellItem label="Dettagli" icon={<Tooltip title={t('COMMON.DETAILS').toString()}><InfoIcon /></Tooltip>}
                             onClick={() => navigate(ROUTES.DASHBOARD_RATES + "/" + params.row.companyId) }
        />
    ]}
];
