import React from 'react';
import {useCustomersList} from "../hook/useCustomer";
import {AxiosResponse} from "axios";
import {CustomerAnagDetails} from "../../../model/Customer";
import SuspenseComponent from "../../Layout/SuspenseComponent";
import CustomerGrid from "../components/CustomersGrid";
import {Trans} from "react-i18next";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {CustomerStatusesMap} from "../core/DataSources";
import {ROUTES} from "../../../routes/ROUTES";

interface CustomerHocProps {
    mode: string;
    customerType?: string;
}

function CustomersHoc(props: CustomerHocProps) {
    const navigate = useNavigate();

    const mapCustomer = (res: AxiosResponse<CustomerAnagDetails[]>): CustomerAnagDetails[] => {
        return res.data.map(c => c);
    }

    const customersHook = useCustomersList(mapCustomer);

    React.useEffect(() => {
        customersHook.get(`?status=${CustomerStatusesMap[props.mode].join(',')}&type=${props.customerType || ''}`);
    }, [props.mode, props.customerType])

    return <SuspenseComponent isLoading={customersHook.isLoading}>
        <div className="section-header">
            <Typography variant="h6">
                {props.customerType
                    ? <Trans>CUSTOMER_LIST.TITLES.VENDORS</Trans>
                    : <Trans>CUSTOMER_LIST.TITLES.BASE</Trans>
                }&nbsp;<Trans>CUSTOMER_LIST.TITLES.{props.mode?.toUpperCase()}</Trans>
            </Typography>
            <Button
                aria-label="add"
                onClick={_ => navigate(ROUTES.ADD_CUSTOMER)}
            >
                <AddIcon />
                <Typography>
                    <Trans>COMMON.ADD</Trans>
                </Typography>
            </Button>
        </div>
        {!customersHook.isLoading && <CustomerGrid customers={customersHook.customers} /> }
    </SuspenseComponent>
}

export default CustomersHoc;