import React from "react";
import {CustomerComponentProps} from "./CustomerComponentProps";
import {GridActionsCellItem, GridColDef, GridRowParams} from "@mui/x-data-grid";
import InfoIcon from "@mui/icons-material/Info";
import {AxiosResponse} from "axios";
import {Group, GroupType} from "../../../model/Customer";
import {useCustomer} from "../hook/useCustomer";
import SuspenseComponent from "../../Layout/SuspenseComponent";
import {Button, Card, CardContent, CardHeader, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {Trans, useTranslation} from "react-i18next";
import CustomerGrid from "./CustomersGrid";
import AddIcon from "@mui/icons-material/Add";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupAddModal from "./GroupAddModal";
import GradeIcon from "@mui/icons-material/Grade";
import LinkIcon from "@mui/icons-material/Link";
import {useNavigate} from "react-router-dom";
import EuroIcon from "@mui/icons-material/Euro";
import {ROUTES} from "../../../routes/ROUTES";
import Placeholder from "../../SkinnableComponents/Placeholder/Placehoder";

function CustomerGroup(props: CustomerComponentProps) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    //const [assignOpen, setAssignOpen] = React.useState<boolean>(false);
    const [createOpen, setCreateOpen] = React.useState<boolean>(false);
    const [selectedGroup, setSelectedGroup] = React.useState<Group>(new Group());

    const columns: GridColDef<Group>[] = [
        {
            field: 'details', headerName: '', type: 'actions', width: 50,
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem label={t("COMMON.DETAILS")} title={t("COMMON.DETAILS")}
                                     icon={<Tooltip title={t('COMMON.DETAILS').toString()}><InfoIcon /></Tooltip>}
                                     onClick={() => {
                                         setSelectedGroup(params.row);
                                         setCreateOpen(true);
                                     } }
                />
            ]
        },
        {
            field: 'goto', headerName: '', type: 'actions', width: 50,
            getActions: (params: GridRowParams<Group>) => [
                <GridActionsCellItem disabled={params.row.ownerId === props.id}
                                     label={t("COMMON.GO_TO")}
                                     title={t("COMMON.GO_TO")}
                                     icon={<Tooltip title={t('COMMON.GO_TO').toString()}><LinkIcon /></Tooltip>}
                                     onClick={() => navigate(ROUTES.EDIT_CUSTOMER + params.row.ownerId)}
                />
            ]
        },
        { field: 'description', headerName: t("CUSTOMER_DETAILS.FIELDS.GROUPS.NAME"), flex: 1},
        { field: 'groupType', headerName: t("CUSTOMER_DETAILS.FIELDS.GROUPS.GROUP_TYPE"), renderCell: params => params.row.groupType === GroupType.FATTURAZIONE && <EuroIcon color="primary" />},
        { field: 'isOwner', headerName: t("CUSTOMER_DETAILS.FIELDS.GROUPS.OWNER"), renderCell: params => params.row.ownerId === props.id && <GradeIcon color="primary" />},
    ]

    const mapGroup = (res: AxiosResponse<Group[]>) => {
        return {
            groups: res.data
        }
    }

    const groupsHook = useCustomer(`/groups?memberId=`,props.id, mapGroup);

    const onCloseModal = (callback) => {
        groupsHook.setReload(true);
        callback();
    }

    return (
        <SuspenseComponent isLoading={groupsHook.isLoading}>
            <Card>
                <CardHeader title={<div className="section-header">
                    <Typography variant="h6" component="div">
                        <Trans>CUSTOMER_DETAILS.FIELDS.GROUPS.TITLE</Trans>
                    </Typography>
                    <div>
                        <Button
                            aria-label="assign"
                            onClick={_ => navigate(ROUTES.GROUPS)}
                        >
                            <GroupAddIcon />
                            <Typography>
                                <Trans>COMMON.ASSIGN</Trans>
                            </Typography>
                        </Button>
                        <Button
                            aria-label="add"
                            onClick={_ => {
                                setCreateOpen(true);
                                setSelectedGroup(new Group(props.id))}
                            }>
                            <AddIcon />
                            <Typography>
                                <Trans>COMMON.ADD</Trans>
                            </Typography>
                        </Button>
                    </div>
                </div>}>
                </CardHeader>
                <GroupAddModal open={createOpen}
                               data={selectedGroup}
                               onClose={() => onCloseModal(() => {setSelectedGroup(new Group()); setCreateOpen(false)})}
                               customerId={props.id}></GroupAddModal>
                {/*<GroupAssignModal open={assignOpen}
                                  onClose={() => onCloseModal(() => setAssignOpen(false))} customerId={props.id}></GroupAssignModal>*/}

                <CardContent>
                    {groupsHook.customer.groups?.length
                        ? <DataGridPro
                            loading={groupsHook.isLoading}
                            getRowId={(row: Group) => row.groupId} rows={groupsHook.customer.groups} columns={columns}
                            getDetailPanelContent={({row}) => <div style={{padding: ".5rem"}}>
                                <Typography
                                    variant="h6"><Trans>CUSTOMER_DETAILS.FIELDS.GROUPS.MEMBERS</Trans></Typography>
                                <CustomerGrid customers={row.members} disableToolbar={true}/>
                            </div>}
                            hideFooter
                            getDetailPanelHeight={() => 'auto'} // Height based on the content.
                        />
                        : <Placeholder></Placeholder>
                    }

                </CardContent>
            </Card>
        </SuspenseComponent>
    )
}

export default CustomerGroup;