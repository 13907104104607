import React from "react";
import {useAxios} from "../../../core/axiosHook";
import {AxiosResponse} from "axios";
import {CompanyRatios, CustomerAnagDetails, CustomerMonitoringAnagDetails} from "../../../model/Customer";
import {TFunction} from "i18next";
import {extractColumns, extractColumnsDetail, setRows, setRowsDetail} from "../core/RowColumnDefinitions";
import type {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {RowDefinitionDetail} from "../core/model/MonitoringRowDefinition";
import {AccountingRowT} from "../core/model/AccountingRowT";


export function useCustomers(callback: (res: AxiosResponse<CustomerAnagDetails[]>) => CustomerAnagDetails[]) {
    const [customers, setCustomers] = React.useState<CustomerAnagDetails[]>([]);
    const {isLoading, request} = useAxios();

    const get = (url: any, _callback: (res: AxiosResponse<CustomerAnagDetails[]>) => CustomerAnagDetails[] = callback) => {
        setCustomers([]);
        request({method: "GET", url: `${process.env.REACT_APP_BE_INGRESS}/customers${url}`}, res => {
            setCustomers(callback(res));
        }).then();
    }

    return {customers, get, isLoading}
}

export function useCustomersMonitoring(t: TFunction) {
    const [customersMonitoring, setCustomersMonitoring] = React.useState<CustomerMonitoringAnagDetails>(); // RESPONSE API
    const [monitoringColumns, setMonitoringColumns] = React.useState<GridColDef[]>(); // COLONNE ESTRATTE DA RESPONSE API
    const [monitoringRows, setMonitoringRows] = React.useState<RowDefinitionDetail[]>(); // RIGHE MAPPATE DA RESPONSE API
    const {isLoading, request} = useAxios();

    const get = (id: number, startDate: string, endDate: string) => {
        setCustomersMonitoring(new CustomerMonitoringAnagDetails(id));
        request({
            method: "GET",
            url: `${process.env.REACT_APP_BE_INGRESS_RATIOS}/ratios/${id}`,
            params: {
                startDate: startDate,
                endDate: endDate
            }
        }).then(res => {
            setCustomersMonitoring(res.data);
            setMonitoringColumns(extractColumns(res.data, t));

            res.data.ratios = res.data.ratios.map((r: CompanyRatios) => ({
                ...r,
                titleMatchingRates: r.inputTotalCount
            }));

            setMonitoringRows(setRows(res.data, t));
        });
    };

    return {customersMonitoring, monitoringColumns, monitoringRows, get, isLoading};
}

export function useRatiosDetail(t: TFunction) {
    const [customersMonitoring, setCustomersMonitoring] = React.useState<CustomerMonitoringAnagDetails>(); // RESPONSE API
    const [monitoringColumns, setMonitoringColumns] = React.useState<GridColDef[]>(); // COLONNE ESTRATTE DA RESPONSE API
    const [monitoringRows, setMonitoringRows] = React.useState<RowDefinitionDetail[]>(); // RIGHE MAPPATE DA RESPONSE API
    const {isLoading, request} = useAxios();

    const get = (id: number, registrationDate: string) => {
        setCustomersMonitoring(new CustomerMonitoringAnagDetails(id));
        request({
            method: "GET",
            url: `${process.env.REACT_APP_BE_INGRESS_RATIOS}/ratios/${id}/${registrationDate}`
        }).then(res => {
            setCustomersMonitoring(res.data);
            setMonitoringColumns(extractColumnsDetail(res.data, t));
            setMonitoringRows(setRowsDetail(res.data, t));
        });
    };

    return {customersMonitoring, monitoringColumns, monitoringRows, get, isLoading};
}


export function useMovementsDetail() {
    const [movements, setMovements] = React.useState<AccountingRowT[]>();
    const {isLoading, request} = useAxios();

    const get = (companyId: number, startDate: string, endDate: string) => {
        setMovements([]);
        request({
            method: "GET",
            url: `${process.env.REACT_APP_BE_BUS_URL}/bus/m2z/v3/movements/${companyId}?startDate=${startDate}&endDate=${endDate}`
        }).then(res => {
            setMovements(res.data.data);
        });
    };

    return {movements, get, isLoading};
}
