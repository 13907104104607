import * as yup from "yup";
import {CustomerAnagDetails} from "../../../../model/Customer";

export const CustomerValidation = (t) =>
    yup.object<CustomerAnagDetails>().shape({
        customerCode: yup.string().required(`${t("CUSTOMER_DETAILS.FIELDS.DETAILS.CODE")} ${t("COMMON.REQUIRED_M")}`),
        vatNumber: yup.string().required(`${t("CUSTOMER_DETAILS.FIELDS.DETAILS.VAT_NUMBER")} ${t("COMMON.REQUIRED_F")}`),
        businessName: yup.string().required(`${t("CUSTOMER_DETAILS.FIELDS.DETAILS.NAME")} ${t("COMMON.REQUIRED_F")}`),
        taxCode: yup.string().required(`${t("CUSTOMER_DETAILS.FIELDS.DETAILS.TAX_CODE")} ${t("COMMON.REQUIRED_M")}`),
        //iban: yup.string().required(`${t("CUSTOMER_DETAILS.FIELDS.DETAILS.IBAN")} ${t("COMMON.REQUIRED_M")}`),
        customerType: yup.string().required(`${t("CUSTOMER_DETAILS.FIELDS.DETAILS.TYPE")} ${t("COMMON.REQUIRED_F")}`),
        customerStatus: yup.string().required(`${t("CUSTOMER_DETAILS.FIELDS.DETAILS.STATUS")} ${t("COMMON.REQUIRED_M")}`),
    });