import React, {MutableRefObject, useCallback, useEffect, useRef} from "react";
import "./OktaLogin.scss";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import * as i18n  from "react-i18next";
import OktaSignIn from '@okta/okta-signin-widget';
import { useNavigate } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { signInWidgetConfig } from "./signInWidgetConfig";
import {ROUTES} from "../../routes/ROUTES";
import {useTranslation} from "react-i18next";


function OktaLogin() {
  const widgetRef: MutableRefObject<any> = useRef();

  const navigate= useNavigate();
  const [nextRoute, setNextRoute] = React.useState(ROUTES.OKTA_CALLBACK);
  const { oktaAuth, authState } = useOktaAuth();

  const {t} = useTranslation();
  const widget: OktaSignIn = React.useMemo(() => new OktaSignIn(signInWidgetConfig(i18n, t)), [t]);
  const goToNextRoute = useCallback(() => navigate(nextRoute), [navigate, nextRoute]);

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    if (params.get("redirectUrl")) {
      setNextRoute(ROUTES.OKTA_CALLBACK + "?redirectUrl=" + params.get("redirectUrl"));
    }
  }, [])

  useEffect(() => {
    if (!oktaAuth.isAuthenticated) {
      // When user isn't authenticated
      console.log("not authenticated");
    } else {
      if (authState?.accessToken) {
        oktaAuth.getUser().then(() => {
          goToNextRoute();
        }).catch(err => console.log(err));
      }
    }
  }, [oktaAuth, authState, goToNextRoute]); // update id authstate changes


  useEffect(() => {
    if (widgetRef.current) {
      // widget log in
      widget.renderEl(
          { el: widgetRef.current },
          (res: any) => {
            oktaAuth.handleLoginRedirect(res.tokens);
          },
          () => { }
      ).then();
    }
    return () => {
      widget.remove();
    };
  }, [oktaAuth, widget]);

  return (
      <React.Suspense fallback="loading">
    <div className="widget-container">
      {<div ref={widgetRef} />}
    </div>
      </React.Suspense>
  );
}

export default OktaLogin;
