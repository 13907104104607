import React from "react";

function useModalState<T>() {
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState<T>();

    const toggle = (_data?: T) => {
        setOpen(!open)
        setData(_data);
    }

    return {open, data, toggle}
}

export default useModalState;