import { ModalComponentProps } from "./CustomerComponentProps";
import { Service } from "../../../model/Customer";
import React from "react";
import { ManualExec } from "../core/CustomerConfiguration";
import { useManualExec } from "../hook/UseManualExec";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import { Trans } from "react-i18next";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

function ManualExecModal(props: ModalComponentProps<Service>) {
  const [selectedDate, setSelectedDate] = React.useState<string>(
    new Date().toISOString()
  );

  const manualExecHook = useManualExec();

  const preventCloseOnBackdrop = () => {
    return;
  };

  const submit = () => {
    const execData: ManualExec = {
      serviceId: props.data.serviceId,
      customerId: props.customerId,
      executionDate: selectedDate
    };

    manualExecHook.save(execData, false).then((_) => props.onClose());
  };

  return (
    <Dialog
      open={props.open}
      onClose={preventCloseOnBackdrop}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle className="section-header">
        <Trans>CUSTOMER_DETAILS.FIELDS.MANUAL_EXEC.TITLE</Trans>
      </DialogTitle>
      <DialogContent>
        <div style={{ padding: "inherit" }}>
          <p>Service: {props.data?.name}</p>
          <br></br>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="it-IT"
          >
            <DatePicker
              label="Data Esecuzione"
              format="DD-MM-YYYY"
              value={moment(selectedDate)}
              onChange={(value: Moment) => {
                setSelectedDate(value.toISOString());
              }}
            />
          </LocalizationProvider>
        </div>
      </DialogContent>
      <DialogActions>
        <div>
          <Button color="error" onClick={props.onClose}>
            <Trans>COMMON.CANCEL</Trans>
          </Button>
          <Button type="submit" onClick={submit}>
            <Trans>COMMON.SAVE</Trans>
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default ManualExecModal;
