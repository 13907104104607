import React from "react";
import {useAxios} from "../../../core/axiosHook";
import {Service} from "../core/Service";
import {AxiosResponse} from "axios";
import {CustomerType, Rate, RateT} from "../../../model/Customer";
import {ServiceConfiguration} from "../../ServiceConfiguration/core/ServiceConfiguration";

export function useServices(callback: (res:AxiosResponse<Service[]>) => Service[]) {
    const [services, setServices] = React.useState<Service[]>([]);
    const {isLoading, request} = useAxios();

    React.useEffect(() => {
        request({ method: "GET", url:`${process.env.REACT_APP_BE_INGRESS}/services` }).then(res => {
            setServices(callback(res));
        });
    }, [callback, request]);

    return {services, isLoading};
}

export function useService(serviceId: number, callback: (res:AxiosResponse<Service>) => Service) {
    const [service, setService] = React.useState<Service>(new Service());
    const {isLoading, request, setReload, reload} = useAxios();

    React.useEffect(() => {
        request({ method: "GET", url:`${process.env.REACT_APP_BE_INGRESS}/services/${serviceId}` }).then(res => {
            setService(callback(res));
        });
    }, [serviceId, reload, request, callback]);

    return {service, isLoading, setReload};
}


export function useRates(serviceId: number, callback: (res:AxiosResponse<Rate[]>) => RateT[]) {
    const [rates, setRates] = React.useState<RateT[]>([]);
    const {isLoading, request, reload, setReload} = useAxios();

    React.useEffect(() => {
        request({ method: "GET", url:`${process.env.REACT_APP_BE_INGRESS}/rates?serviceId=${serviceId}` }).then(res => {
            setRates(callback(res));
        });
    }, [serviceId, reload, callback, request]);

    return {rates, isLoading, setReload};
}


export function useServiceRates() {
    const [rates, setRates] = React.useState<RateT[]>([]);
    const {isLoading, request} = useAxios();

    const get = (serviceId: number, customerType: CustomerType) => {
        if (serviceId && customerType) {
            request({
                method: "GET",
                url: `${process.env.REACT_APP_BE_INGRESS}/rates?serviceId=${serviceId}&customerType=${customerType}`
            }).then(res => {
                setRates(res.data);
            });
        } else {
            setRates([]);
        }
    }

    return {rates, isLoading, get};
}

export function useRate(rateId: number, callback: (res:AxiosResponse<Rate>, r: ServiceConfiguration) => RateT) {
    const [rate, setRate] = React.useState<RateT>(new RateT());
    const {isLoading, request} = useAxios();

    React.useEffect(() => {
        if (rateId) {
            request({method: "GET", url: `${process.env.REACT_APP_BE_INGRESS}/rates/${rateId}`}).then(res => {
                request({
                    method: "GET",
                    url: `${process.env.REACT_APP_M1A009}/serviceConfig/rate/${rateId}`
                }).then(r => {
                    setRate(callback(res, r.data));
                });
            });
        } else {
            setRate(new RateT());
        }
    }, [rateId, callback, request]);

    const saveRate = (r: RateT, rateId: number, callback: (res:AxiosResponse<Rate>) => RateT) => {
        request({ method: rateId ? "PUT" : "POST",
            url:`${process.env.REACT_APP_BE_INGRESS}/rates` + (rateId ? `/${r.rateId}` : ''), data: r }).then(res => {
            setRate(callback(res));
        });
    }

    const assignConfig = (rateId: number, serviceConfigId: number) => {
        const data = {rateId, serviceConfigId }
        return request({ method: "POST",
            url:`${process.env.REACT_APP_M1A009}/serviceConfig/rate/assign`,
            data}).then();
    }


    return {rate, saveRate, assignConfig, isLoading};
}


export function useServiceConfigurations(serviceId: number) {
    const [serviceConfigurations, setServiceConfigurations] = React.useState<ServiceConfiguration[]>([]);

    const {isLoading, request} = useAxios();

    React.useEffect(()  => {
        request({ method: "GET",
            url:`${process.env.REACT_APP_M1A009}/serviceConfig?serviceId=${serviceId}` })
            .then(res => setServiceConfigurations(res.data));
    }, [serviceId]);

    return {serviceConfigurations, isLoading};
}