import {Service} from "../../Services/core/Service";
import React from "react";
import {useAxios} from "../../../core/axiosHook";
import {ServiceConfiguration} from "../core/ServiceConfiguration";

export function useServices() {
    const [services, setServices] = React.useState<Service[]>([]);
    const {isLoading, request} = useAxios();

    React.useEffect(() => {
        request({ method: "GET", url:`${process.env.REACT_APP_BE_INGRESS}/services` }).then(res => {
            setServices(res.data);
        });
    }, [request]);

    return {services, isLoading};
}

export function useServiceConfigurations() {
    const [serviceConfigurations, setServiceConfigurations] = React.useState<ServiceConfiguration[]>([]);

    const {isLoading, request} = useAxios();

    const get = (serviceId: number) => {
        return request({ method: "GET",
            url:`${process.env.REACT_APP_M1A009}/serviceConfig?serviceId=${serviceId}` })
            .then(res => setServiceConfigurations(res.data));
    }

    return {serviceConfigurations, get, isLoading};
}


export interface ServiceConfigurationHookT {
    serviceConfiguration, get, save, isLoading
}

export function useServiceConfiguration() {
    const [serviceConfiguration, setServiceConfiguration] = React.useState<ServiceConfiguration>(new ServiceConfiguration());

    const {isLoading, request} = useAxios();

    const get = (serviceConfigurationId: number) => {
         return request({ method: "GET",
             url:`${process.env.REACT_APP_M1A009}/serviceConfig/${serviceConfigurationId}` })
             .then(res => setServiceConfiguration(res.data));
    }


    const save = (serviceConfiguration: ServiceConfiguration) => {
        return request({ method:  serviceConfiguration.serviceConfigId ? "PUT" : "POST",
            url:`${process.env.REACT_APP_M1A009}/serviceConfig${serviceConfiguration.serviceConfigId ? '/' + serviceConfiguration.serviceConfigId : ''}` ,
            data:serviceConfiguration });
    }


    return {serviceConfiguration, get, save, isLoading};
}
