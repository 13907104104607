import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Grid,
  ListItem,
  TextField,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { CustomerAnagDetails } from '../../../model/Customer';
import { COMPOSITION_LIST } from '../hook/useListComposition';

interface GroupCompositionProps {
  compositionHook: any;
}

const GroupCompositionList1 = ({ compositionHook }: GroupCompositionProps) => {
  const customList = (
    title: React.ReactNode,
    list: COMPOSITION_LIST,
    filteredItems: readonly CustomerAnagDetails[],
    selectedItems: readonly CustomerAnagDetails[],
    allItems: readonly CustomerAnagDetails[]
  ) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        title={
          <TextField
            type="search"
            placeholder={'Filtra ' + title}
            fullWidth
            onChange={(e) =>
              compositionHook.handleFilter(
                e.target.value,
                ['businessName', 'customerCode'],
                list
              )
            }
          />
        }
      ></CardHeader>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={compositionHook.handleToggleAll(filteredItems)}
            checked={
              compositionHook.numberOfChecked(selectedItems) ===
                allItems.length && allItems.length !== 0
            }
            indeterminate={
              compositionHook.numberOfChecked(selectedItems) !==
                allItems.length &&
              compositionHook.numberOfChecked(selectedItems) !== 0
            }
            disabled={allItems.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${compositionHook.numberOfChecked(selectedItems)}/${allItems.length} selected`}
      />
      <Divider />
      <List
        sx={{
          //width: 200,
          height: window.innerHeight - 400 + 'px',
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {filteredItems.map((value: CustomerAnagDetails) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value.customerId}
              role="listitem"
              onClick={compositionHook.handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={compositionHook.checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={value.businessName}
                secondary={value.customerCode}
              />
            </ListItem>
          );
        })}
      </List>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        subheader={`Aziende trovate ${filteredItems.length}`}
      />
    </Card>
  );

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Grid item xs={5}>
        {customList(
          'Selezionati',
          COMPOSITION_LIST.DATA_SOURCE,
          compositionHook.dataSource,
          compositionHook.leftChecked,
          compositionHook.originalDataSource
        )}
      </Grid>
      <Grid item xs={1}>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={compositionHook.handleCheckedRight}
            disabled={compositionHook.leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={compositionHook.handleCheckedLeft}
            disabled={compositionHook.rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        {customList(
          'Selezionati',
          COMPOSITION_LIST.SELECTED,
          compositionHook.selected,
          compositionHook.rightChecked,
          compositionHook.originalSelected
        )}
      </Grid>
    </Grid>
  );
};
export default GroupCompositionList1;
